/* Profile Container */
.profile-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.profile-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Nickname and Country Fields */
.profile-section {
    margin-bottom: 20px;
}

.profile-label {
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

.nickname-edit {
    display: flex;
    align-items: center;
    position: relative;
}

.profile-input {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    font-size: 16px;
}

.profile-input:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255,255);
    outline: none;
}

.edit-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.profile-select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-top: 5px;
}

.profile-select:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none;
}

/* Statistics Section */
.profile-statistics {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 80px;

}
.profile-circle-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    background-color: #282828;
    border: 1px solid #383434;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 20px 40px 50px 40px;
    border-radius: 12px;
    gap: 15px;
}

.profile-circle {
    position: relative;
    width: 90px;
    height: 90px;
    text-align: center;
}

.profile-circle svg {
    width: 90px;
    height: 90px;
}

.stat-number {
    position: absolute;
    top: 38%; /* Adjusted to center correctly */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px; /* Slightly smaller to fit */
    font-weight: bold;
    color: white;
}

.stat-label {
    position: absolute;
    top: 52%; /* Adjusted to center correctly */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: white;
}


.stat-percentage {
    margin-top: 10px;
    font-size: 16px !important;
    color: white;
}

.profile-circle p {
    margin-top: 10px;
    font-size: 14px;
    color: #FFFFFF;
}

.profile-circle-container p {
    font-size: 12px;
    color: #9E9E9E;
}


/* Animated Progress Circles */
@keyframes drawCircle {
    from {
        stroke-dashoffset: 251; /* Start fully hidden (full circle) */
    }
    to {
        stroke-dashoffset: var(--stroke-dashoffset); /* End at the calculated percentage */
    }
}


.progress-circleX {
    animation: drawCircle 1s ease-out forwards;
    stroke-dasharray: 251 251;
    stroke-dashoffset: var(--stroke-dashoffset); /* Dynamically set based on percentage */
    transition: stroke-dashoffset 1s ease-out;
}


/* Save Button */
.profile-save-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
  
}

.profile-save-button:hover {
    background-color: #dec000;
}

.profile-button-loading {
    background-color: #dec000;
    cursor: not-allowed;
    opacity: 0.7;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Confirmation Popup Overlay */
.profile-update-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Popup Container */
.profile-update-popup {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
}

/* Popup Title */
.popup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Popup Message */
.popup-message {
    font-size: 18px;
    margin-bottom: 20px;
}

/* Close Button */
.profile-popup-close-button {
    background-color: #FFD700;
    color: black;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.profile-popup-close-button:hover {
    background-color: #dec000;
}

.profile-label-country-select {
font-size: 14px;
margin-bottom: 10px;
display: block;
}

/* Profile Popup Overlay */
.profile-update-popup-overlay-country-select {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Popup Container */
.profile-update-popup-country-select {
    background-color: #1E1E1E; /* Same background as input fields */
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
    border: 2px solid #555555; /* Consistent with input borders */
}

/* Popup Title */
.popup-title-country-select {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Popup Message */
.popup-message-country-select {
    font-size: 16px;
    margin-bottom: 20px;
}

/* Close Button */
.profile-popup-close-button-country-select {
    background-color: #FFD700;
    color: black;
    padding: 10px 30px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

.profile-popup-close-button-country-select:hover {
    background-color: #dec000;
}

/* Dropdown Styles - Specific for Country Selection */
.custom-dropdown-country-select {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
}

.selected-country-country-select {
    display: flex;
    align-items: center;
}

.flag-icon-country-select {
    width: 20px;
    height: 15px;
    margin-right: 10px;
}

.dropdown-list-country-select {
    position: absolute;
    width: calc(100% - 65px);
    max-height: 60vh;
    background-color: #1E1E1E;
    border: 2px solid #555555;
    z-index: 10;
    overflow-y: auto;
    margin-top: 10px;
}

.dropdown-item-country-select {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #1E1E1E;
    border-bottom: 1px solid #555555;
}

.dropdown-item-country-select:hover {
    background-color: #2E2E2E;
    border-color: #eaeaea;
}

.search-input-country-select {
    width: calc(100% - 20px);
    padding: 8px 10px;
    font-size: 16px;

    background-color: #1E1E1E;
    color: white;
}

.search-input-country-select::placeholder {
    color: #999999;
}

.no-results-country-select {
    padding: 10px;
    text-align: center;
    color: #ff4d4d;
    font-size: 14px;
}

.search-input-country-select:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255,255);
    outline: none;
}