@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
/* Registration Page Styles */
.registration-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.registration-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.registration-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.input-field {
    width: calc(100% - 25px);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
}

.email-description {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}

.password-guideline {
    color: #888;
    font-size: 12px;
    margin-top: 5px;
}

.register-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    transition: background-color 0.3s ease;
    margin-top: 30px;
    margin-bottom: 50px;
}

.register-button:hover {
    background-color: #dec000;
}

.error-message, .error-text {
    color: #ff4d4d;
    font-size: 12px;
}




/* Unique Popup overlay and styling */
.registration-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.registration-popup {
    background-color: #282828; /* Dark theme */
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 300px;
    width: 100%;
}

.registration-popup h3 {
    margin-bottom: 10px;
}

.popup-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.popup-button:hover {
    background-color: #dec000;
}
/* Password field with toggle icon */
.password-field-container {
    position: relative;
}

.password-toggle-icon {
    color:#FFFFFF;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px; /* Adjust icon size */
    height: 20px;
}

.password-toggle-icon.show {
    opacity: 0.3;
}

.password-toggle-icon {
    opacity: 1;
    }

.password-toggle-icon:hover {
    opacity: 0.3;
}
/* Login Page Styles */
.login-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.login-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 50px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.label {
    font-size: 14px;
}

.input-field {
    width: calc(100% - 25px); 
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-bottom: 10px;
}

.input-field:focus{
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none;
}

/* Button styles */
.login-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 50px;
}

.login-button:hover {
    background-color: #dec000;
}

.login-disabled {
    background-color: #dec000;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Loading Spinner */
.spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    color: #ff4d4d;
    text-align: center;
    margin-top: 10px;
}

.login-options {
    margin-top: 20px;
    text-align: center;
}

.link-button {
    background: none;
    border: none;
    color: #00aaff;
    cursor: pointer;
    margin-top: 10px;
}

.link-button:hover {
    background: none;
    text-decoration: underline;
}


/* Container to hold the Telegram button with a scaling effect */
.telegram-login-container {

    width: 100%; /* Let the width adjust dynamically */
    max-width: 250px; /* Set max width to prevent cutoff */
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    animation: scaleEffect 1.3s infinite ease-in-out;
    overflow: hidden; /* Ensures the animation stays within bounds */
}

@keyframes scaleEffect {
    0%, 100% {
        transform: scale(1); /* Normal size */
    }
    50% {
        transform: scale(1.1); /* Scale up slightly */
    }
}


/* Loading screen specifically for login page */
#login-loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #121212;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 20px;
    color: white;
    transition: opacity 0.3s ease;
}
#login-loading-screen.hidden {
    opacity: 0;
    visibility: hidden;
}

/* Forgot Password Page Styles */
.forgot-password-page-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.forgot-password-page-title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: bold;
}

.forgot-password-description-text {
    text-align: center;
    color: #ccc;
    margin-bottom: 20px;
}

.forgot-password-page-form {
    display: flex;
    flex-direction: column;
}

.forgot-password-form-group {
    margin-bottom: 15px;
}

.forgot-password-input-field {
    width: calc(100% - 25px); 
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555; /* Adds a dark border to the text box */
    background-color: #1E1E1E; /* Dark background for the text box */
    color: white; /* White text to ensure readability */
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.forgot-password-input-field:focus{
    border-color: #eaeaea; /* Same as the regular border color */
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none; /* Remove the blue outline */
}

.forgot-password-submit-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    transition: background-color 0.3s ease;
    margin-top: 30px;
}

.forgot-password-submit-button:hover {
    background-color: #dec000;
}

.forgot-password-submit-button.disabled {
    opacity: 0.5; /* Dim the button when disabled */
    cursor: not-allowed;
}

.forgot-password-error-message {
    color: #ff4d4d;
    text-align: center;
    margin-top: 10px;
}

.forgot-password-success-message {
    color: #4caf50;
    text-align: center;
    margin-top: 10px;
}

/* Loading Spinner */
.forgot-password-spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 2s linear infinite;
    margin-right: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Popup Overlay and Content */
.forgot-password-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.forgot-password-popup {
    background-color: #282828;
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 300px;
}

.forgot-password-popup h3 {
    margin-bottom: 10px;
}

.forgot-password-popup-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
}

.forgot-password-popup-button:hover {
    background-color: #dec000;
}

/* Reset Password Page Styles */
.reset-password-page-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.reset-password-page-title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: bold;
}

.reset-password-guideline-text {
    text-align: center;
    color: #ccc;
    margin-bottom: 20px;
}

.reset-password-form {
    display: flex;
    flex-direction: column;
}

.reset-password-form-group {
    margin-bottom: 15px;
}

.reset-password-input-field {
    width: calc(100% - 25px); 
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555; /* Adds a dark border to the text box */
    background-color: #1E1E1E; /* Dark background for the text box */
    color: white; /* White text to ensure readability */
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.reset-password-input-field:focus {
    border-color: #eaeaea; /* Same as the regular border color */
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none; /* Remove the blue outline */
    font-size: 16px;
}

.reset-password-submit-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    transition: background-color 0.3s ease;
    margin-top: 30px;
}

.reset-password-submit-button:hover {
    background-color: #dec000;
}

.reset-password-submit-button.disabled {
    background-color: #999;
    cursor: not-allowed;
    opacity: 0.7;
}

.reset-password-error-message {
    color: #ff4d4d;
    text-align: center;
    margin-top: 10px;
}

.reset-password-success-message {
    color: #4caf50;
    text-align: center;
    margin-top: 10px;
}

/* Loading Spinner */
.reset-password-spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 2s linear infinite;
    margin-right: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Popup Overlay and Content */
.reset-password-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.reset-password-popup {
    background-color: #282828;
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 300px;
}

.reset-password-popup h3 {
    margin-bottom: 10px;
}

.reset-password-popup-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
}

.reset-password-popup-button:hover {
    background-color: #dec000;
}

.game-component {
  background-color: #121212;
  min-height: 100vh;
  overflow-y: auto;
}

body{
  font-family: 'Roboto', sans-serif;
}



.user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px; /* Keeps the settings icon on the right */
  color: white;

  
}

.user-info::after {
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust as needed */
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #585454; /* Line color */
}

.left-section {
  display: flex;
  align-items: center;
}

.avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.username-with-flag {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px; /* Adds space between the flag and nickname */
}

.flag-icon-user-details {
  width: 20px;
  height: 15px;
}

.username {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long names with dots */
    max-width: 100px; /* Set a limit on the name's width */
}

.score {
  display: flex;
  align-items: center;
}

.score-container {
  display: flex;
  align-items: center;
  padding: 4px 10px; /* Adjust padding for spacing */

  border-radius: 8px; /* Rounded corners */
  
  background-color: #282828; /* Slightly different black */
  
  border: 1px solid #383434;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  
}

.score-icon-img {
  width: 15px;
  height: 15px;
  padding-top: 3px;
  margin-right: 5px; /* Space between icon and score */
}

.score-value {
  font-size: 16px;

  color: white; /* Adjust text color */
}


.avatar-button, .settings-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.avatar-button:hover, .settings-button:hover {
  background: none; /* Prevents background change on hover */
}



.balance-container {
  border: 1px solid #383434;
  background-color: #282828; /* Slightly different black */
  padding: 20px;
  border-radius: 30px;
  width: 264px;
  margin: 0 auto;
  text-align: center;
  margin-top: 75px;
}

.balance-label {
  font-size: 16px;
  color: #9E9E9E;
  margin-bottom: 5px; /* Add spacing between label and balance */
}

.currency-sign {
  
  font-size: 52px; /* Make $ sign bigger */
  color: #9E9E9E;
  margin-right: 8px;
}

.main-balance {

  font-size: 56px; /* Larger font for the main balance */
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.decimal-point {
  font-size: 44px; /* Same height as the smaller part */
  margin-left: 2px;
  color: white;
  font-weight: bold;
}

.decimal-balance {
  font-size: 44px; /* Smaller font for decimal part */
  margin-left: 2px;
  color: white;
  font-weight: normal;
}


.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.action-button {
  flex: 1 1;
  background-color: #333333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  color: white;
  border: 0px solid grey;
  padding: 10px 5px;
  margin: 0 5px;
  border-radius: 14px;
  cursor: pointer;
  font-size: 12px;
  
  
}

.action-button:hover {
  background-color: #282828;
}

.action-button:active {
  background-color: #333333; /* Even darker green when clicked */
}





.games-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2px;
  margin-top: 20px;
  margin-bottom: 100px;
}

/* For all game items */
.game-item {
  position: relative;
  background-color: #282828;
  width: 40%;
  height: 165px;
  border: 1px solid #383434;
  border-radius: 25px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 4px;
  min-width: 120px;
  max-width: 250px;
}

/* Styling for the unavailable game item (Belote V2V) */
.game-item.disabled {
  position: relative;
  background-color: #444444; /* Muted background color */
  color: #999999; /* Muted text color */
  
  
}

/* Full background overlay for the disabled item */
.game-item.disabled::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  border-radius: 25px; /* Keep the same border-radius as the item */
  z-index: 1; /* Ensure it covers the whole item */
}

/* "Coming Soon" message */
.game-item.disabled::after {
  content: '(Coming Soon)';
  position: absolute;
  bottom: 20px; /* Set the distance from the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center the text horizontally */
  font-size: 14px;
  color: #9E9E9E; /* Bright red for "Coming Soon" */
  font-weight: bold;
  z-index: 2; /* Display the message above the overlay */
  text-align: center;
  white-space: nowrap; /* Ensure the text doesn't wrap */
}


.game-logo {
  width: 77.5px;
  height: 70px;
  margin-bottom: -4px;
  object-fit: contain;
  
}


.game-title {
  font-size: 20px;
  color: #ffffff;
  margin-top: 10px;
 
}

.game-description {
  font-size: 12px;
  color: rgb(172, 172, 172);
}

.bet-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: flex-end; 

}

.bet-popup-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001;
  animation: slideUp 0.3s ease;
}

.bet-popup-container.closing {
  animation: slideDown 0.2s ease;
}

.bet-popup {
  background-color: #282828;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  padding-bottom: 120px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  max-height: 70vh; /* Limit popup height */
  overflow-y: auto; /* Allow scrolling inside the popup */

  
}


@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
      transform: translateY(0);
  }
  to {
      transform: translateY(100%);
  }
}

.bet-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
  
}

.bet-option {
  font-size: 14px;
  width: 40%;  /* Two items per row */
  background-color: #333333;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: white;
  border: 1px solid #383838;
  cursor: pointer;
  margin-top: 10px;
    /* Add min and max width constraints */
    max-width: 200px;  /* Prevents the option from growing too large */
    min-width: 120px;  /* Prevents the option from becoming too small */
}

.option-icon {
  width: 28px;
  height: 28px;
  margin-top: 10px;
  
}

.bet-type {
  font-size: 12px;
  font-weight: bold;
  color: #9E9E9E;
  
}

.bet-condition {
  font-size: 12px;
  color: #FFD700;
  margin-top: -8px;
}

.pro-game {
  background-color: #333333;
}

.bet-option:hover {
  background-color: #555555;
}





.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 0px; /* Move the title and button slightly up */
}

.popup-header h3 {
  color: #FFFFFF;
  margin: 0;
  font-size: 16px;
}

.close-popup {
  background: none;
  font-size: 24px;
  color: #9E9E9E;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: absolute;
  right: 5px; /* Moves it to the right */
}



.close-popup:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */

}




/* Overlay styling to cover the whole screen */
.search-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Main popup styling */
.search-popup {
  background-color: #282828; /* Match the app's dark theme */
  border-radius: 20px;
  padding: 20px;
  width: 300px;
  text-align: center;
  position: relative;
}

/* Popup header with title and close button */
.search-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-popup-header h3 {
  color: #9E9E9E;
  margin: 0px;
  font-size: 18px;
}



/* Body of the popup */
.popup-body {
  margin-top: 20px;
}

.popup-body p {
  color: white;
  margin-bottom: 20px;
  font-size: 14px;
}


/* Loader for the search animation */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e74c3c;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



/* Cancel button styling */
.cancel-search-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.cancel-search-button:hover {
  background-color: #c0392b;
}




.error-message {
  
  color: #ff4d4d;
  padding: 10px;
  margin-top: -40px;
  margin-bottom: -20px;
  text-align: center;
  font-weight: bold;
}























/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 6px;
  background-color: #bebebe; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #383434; /* Scrollbar color */
  border-radius: 10px; /* Rounded corners */
  
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5c5b5b; /* Change color on hover */
}





html, body {
    
    margin: 0;
    padding: 0;
    height: 100%;

  }

  .animating {
    will-change: transform;
}


  .blot-page {
    background-image: url('/Tbackground.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    max-height: 100svh;
    overflow: hidden; /* Hide any content that extends beyond this */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dealer sign for the player */
.player-section .dealer-sign {
    margin-bottom: 125px; /* Adjust position for player */
    margin-left: 300px;
    display: inline-block;
    opacity: 0.9; 
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
}

.player-section .dealer-sign img {
    width: 20px;  
    height: 20px; 
}

/* Dealer sign for the opponent */
.opponent-section .dealer-sign {
    margin-top: 120px; /* Adjust position for opponent */
    margin-left: 170px;
    display: inline-block;
    opacity: 0.9; 
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
}

.opponent-section .dealer-sign img {
    width: 20px;  
    height: 20px; 
}





.popup {
    position: fixed;
    top: 0; /* Set this to 0 to cover the full screen */
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other elements */
}

.popup-inner p {
    margin: 0;
    padding: 0;
    color: #000; /* Ensure the text color is not the same as the background */
}

.popup p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Ensure all card images have the same size */
.card img, .card-back img {
    width: 80px;
    height: 120px;
    transition: transform 0.3s;
    will-change: transform;
    display: block; /* Avoid inline space issues */
}

.Fcard img{
    width: 70px; /* Reduced width */
    height: 110px; /* Reduced height */
    transition: transform 0.3s;
    will-change: transform;
}




/* Ensure the blot page takes the full height of the viewport */
.blot-page {
    position: relative;
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between opponent, play, and player sections */
    
}




.playarea {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the play area */
    width: 100%;
    max-height: 20vh; /* Prevent it from growing too large */
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Prevent interactions with the play area */
    
}

.card.in-play {
    width: 80px;
    height: 120px;
    position: absolute; /* Ensure it positions correctly during animation */
    transform-origin: center center; /* Set the transform origin to center */
    z-index: 1; /* Ensure it layers correctly during animation */
}


.playarea .card.in-play {
    position: absolute;
    top: 50%;
    left: 50%;
    will-change: transform;
}

/* First card positioning */
.playarea .card.in-play.first-card-position {
    z-index: 0;
    transform: translate(-50%, -50%);
    will-change: transform;
}

/* Second card positioning */
.playarea .card.in-play.second-card-position {
    z-index: 1;
    transform: translate(-25%, -42%) rotate(15deg);
    will-change: transform;
}

/* Disable hover and interactions during animation */
.disable-hover {
    pointer-events: none;
}







.opponent-section {
    position: absolute; /* Keep it anchored to the top */
    top: 0;
    left: 0;
    width: 100%;
    height: 20%; /* Full opponent section height */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.player-section {
    position: absolute;
    bottom: 20px; /* Attach it to the bottom of the screen */
    left: 0;
    width: 100%; /* Full width */
    height: 22%; /* Fixed height for player section */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6000;
}

.player-hand .card.disabled {
    pointer-events: none !important;
    will-change: transform;
}

/* Highlight allowed cards */
.allowed-card {
    opacity: 1; /* Ensure full visibility */
    cursor: pointer; /* Show pointer to indicate interactivity */
    /* Add more styles as needed */
}

/* Cover not-allowed cards with a dark shadow */
.not-allowed-card {
    position: relative; /* Required for the pseudo-element */
    cursor: not-allowed; /* Indicate that this card is not clickable */
}

/* Add the dark shadow using a pseudo-element */
.not-allowed-card::before {
    content: ''; /* Required to display the pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.348); /* Adjust the color and opacity for shadow */
    z-index: 1; /* Ensure the shadow covers the card */

}





.deck-container {
    position: fixed;       /* Fix it to the viewport so it stays in place on any screen size */
    top: 50vh;             /* Center it vertically based on the viewport height */
    left: 65vw;            /* Center it horizontally based on the viewport width */
    transform: translate(-50%, -50%);  /* Shift the container back to exact center */
    display: flex;
    flex-direction: column;  /* Stack the deck and face-up card vertically */
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Style for the deck */
.initial-deck {
    position: absolute;    /* Absolute within the container */
    width: 80px;           /* Set the width for the deck */
    height: 120px;         /* Set the height for the deck */
    top: -40px;             /* Adjust this to control how far it is from the center */
    left: 60px;               /* Align with the container */
    z-index: 101;
    will-change: transform;
}

/* Style for the face-up card */
.face-up-card {
    position: absolute;    /* Absolute within the container */
    width: 80px;           /* Set the width for the face-up card */
    height: 120px;         /* Set the height for the face-up card */
    rotate: 90deg;         /* Rotate the face-up card as needed */
    top: -40px;            /* Adjust this to control spacing between deck and face-up card */
    left: 10px;           /* Adjust as needed to position correctly relative to the deck */
    z-index: 100;
    will-change: transform;
}






/* Animation for Moving Cards */
@keyframes moveToPlayArea {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(50%, 50%); /* Adjust as necessary */
    }
}

@keyframes moveToWinner {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, -100px); /* Adjust as necessary */
    }
}





.dialog-popup-inner h3 {
    margin: 5px; /* Remove any default margin */
    
}

/* Add the keyframes for the animation */
@keyframes popupSlideIn {
    0% {
        transform: scale(0.5); /* Start smaller */
        opacity: 0;            /* Fully transparent */
    }
    100% {
        transform: scale(1);   /* Scale to normal size */
        opacity: 1;            /* Fully opaque */
    }
}

/* Apply the animation to the dialog-popup-inner */
.dialog-popup-inner {
    background-color: #fff;
    border-radius: 8px;
    padding: 0px 9px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    position: relative;
    
    /* Animation properties */
    animation: popupSlideIn 0.3s ease-out forwards; /* 0.3s duration, ease-out effect */
}

/* Arrow pointing up for player popup */
.dialog-popup-inner.player::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 25%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

/* Arrow pointing down for opponent popup */
.dialog-popup-inner.opponent::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 25%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}


/* Positioning the player popup with percentage values */
.player-popup {
    position: absolute;
    margin-top:-170px;
    z-index: 1000;
}

/* Positioning the opponent popup similarly */
.opponent-popup {
    position: absolute;
    margin-top: 80px;
    z-index: 1000;
}

/* Positioning the opponent popup similarly */
.player-popup-inOtherScreen {
    position: absolute;
    margin-top: -180px;
    z-index: 1000;
}

.PlayerTrumpSelection-popUp {
    position: absolute;
    margin-top: -280px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.85); /* Darker transparent background */
    padding: 0px 15px 20px 15px;
    border-radius: 15px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* More prominent shadow */
    text-align: center;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.95;
    z-index: 5001;
}

/* Container for suit buttons */
.PlayerTrumpSelection-popUp .button-container {
    display: flex;
    justify-content: space-between; /* Space buttons evenly */
    width: 100%;

}

/* Common styles for all buttons */
.PlayerTrumpSelection-popUp button {
    background-color: #ffffff; /* Add white background */
    border: 2px solid #ffde59;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 20px;
}

/* Icon size and hover effects */
.PlayerTrumpSelection-popUp button img {
    width: 50%;
    height: 50%;
    transition: transform 0.2s ease-in-out;
}

.PlayerTrumpSelection-popUp button:hover {
    background-color: #e2e2e2;
}

/* Hover effect for all buttons */
.PlayerTrumpSelection-popUp button:hover img {
    transform: scale(1.1); /* Slight scale-up on hover */
  
}



/* Pass button styling */
.PlayerTrumpSelection-popUp button.btn {
    background-color: #ffde59; /* Yellow background for Pass */
    color: #000;
    border: none;
    width: auto;
    padding: 0px 20px; /* Adjust padding for horizontal space */
    margin-top: 15px;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1; /* This ensures the button height is just enough for the text */
    margin: 0; /* Remove default margin */
    transition: background-color 0.3s ease-in-out;
    height: 30px; /* Control height directly to reduce space */
    display: flex;
    align-items: center; /* Align the text vertically */
    justify-content: center; /* Center the text horizontally */
}

/* Hover effect for Pass button */
.PlayerTrumpSelection-popUp button.btn:hover {
    background-color: #ffb800; /* Slightly darker yellow */
    transform: scale(1.05);
}




.trump-final-decision-popup {
    position: absolute;
    margin-top: -280px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.85); /* Darker transparent background */
    padding: 0px 15px 20px 15px;
    border-radius: 15px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* More prominent shadow */
    text-align: center;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.95;
}

/* Display for the selected trump suit icon */
.selected-suit-icon {
    background-color: #ffffff; /* Add white background */
    border: 2px solid #ffde59;
    padding: 5px;
    margin: 20px 0 -20px 0px; /* Use margin to move it down */
    border-radius: 8px;
    width: 35px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


.selected-suit-icon img {
    width: 30px;
    height: 30px;
}


/* Pass button styling */
/* Pass button styling */
.trump-final-decision-popup button.btn {
    background-color: #ffde59; /* Yellow background for Pass */
    color: #000;
    border: none;
    width: auto;
    padding: 0px 10px;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 20px 10px 0 10px; /* Increase the top margin to move it down */
    transition: background-color 0.3s ease-in-out;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.trump-final-decision-popup button.btn:hover {
    background-color: #ffb800; /* Slightly darker yellow */
    transform: scale(1.05);
}


.PlayerTrumpDecision-popUp {
    position: absolute;
    z-index: 1000;
    text-align: center;
    display: flex;  
    justify-content: center;
    align-items: center;
}

/* Button Styles */
.PlayerTrumpDecision-popUp button {
    background-color: #ffde59; /* Yellow background */
    color: #000;
    border: none;
    width: auto;
    padding: 8px 20px;
    margin-top: 10px;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 180px;
}

/* Take Button Specific Styles */
.PlayerTrumpDecision-popUp .take-btn {
    background-color: #32CD32; /* Green background for Take button */
    color: white;
}

.PlayerTrumpDecision-popUp .take-btn:hover {
    background-color: #28a428; /* Darker green on hover */
    transform: scale(1.05);
}

/* Pass Button Specific Styles */
.PlayerTrumpDecision-popUp .pass-btn {
    background-color: #ffde59; /* Yellow background for Pass button */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.PlayerTrumpDecision-popUp .pass-btn:hover {
    background-color: #ffb800; /* Slightly darker yellow */
    transform: scale(1.05);
}

/* Disabled Pass Button Styles */
.PlayerTrumpDecision-popUp .pass-btn:disabled {
    background-color: #ccc; /* Grey out the button */
    color: #666; /* Darker text */
    cursor: not-allowed; /* Show 'not-allowed' cursor */
    transform: none; /* No scaling on hover */
    opacity: 0.7; /* Make it look inactive */
}




/* Announce and Cancel Popup */
.PlayerAnnounceCancel-popup {
    position: absolute; /* Position relative to player-hand-container */
    bottom: 140px; /* Position it above the player's hand */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Disable pointer events for the container */
}

/* Container for buttons */
.PlayerAnnounceCancel-popup .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: auto; /* Enable pointer events for buttons */
}

/* Common Button Styles */
.PlayerAnnounceCancel-popup button {
    background-color: white; /* Add background to buttons */
    padding: 8px 15px;
    border-radius: 12px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    font-size: 1rem;
    font-weight: bold;
    color: #000; /* Black text for contrast */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: 10px;
    pointer-events: auto; /* Enable pointer events on buttons */
}

/* Specific styles for Announce Button */
.PlayerAnnounceCancel-popup .announce-btn {
    background-color: #32CD32;
    color: white;
}

.PlayerAnnounceCancel-popup .announce-btn:hover {
    background-color: #28a428;
    transform: scale(1.05);
}

/* Specific styles for Cancel Button */
.PlayerAnnounceCancel-popup .cancel-btn {
    background-color: #ffde59;
    color: black;
}

.PlayerAnnounceCancel-popup .cancel-btn:hover {
    background-color: #ffb800;
    transform: scale(1.05);
}

/* Player Dialog Popup */
.player-popupComb {
    position: absolute; /* Position relative to player-hand-container */
    top: -30px; /* Adjust top value to control how high it appears above the player's hand */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Disable pointer events for the container */
}

.player-popupComb .dialog-popup-inner {
    pointer-events: auto; /* Enable pointer events for the dialog content */
}





/* CSS for remaining card dealing animation */
@keyframes ppbet-dealCardToPlayer {
    0% {
        transform: translate(0, 0); /* Start from the deck */
    }
    100% {
        transform: translate(-50vw, 100vh); /* Move to the player's area */
    }
}

@keyframes ppbet-dealCardToOpponent {
    0% {
        transform: translate(0, 0); /* Start from the deck */
    }
    100% {
        transform: translate(-50vw, -100vh); /* Move to the opponent's area */
    }
}

/* CSS for removing the deck cards */
@keyframes ppbet-removeDeckCard {
    0% {
        opacity: 1;
        transform: translate(0, 0); /* Start at current position */
    }
    100% {
        opacity: 0;
        transform: translate(50vw, 0); /* Move off-screen to the right */
    }
}

.ppbet-card {
    position: absolute;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}


/* CSS animation to rotate and move face-up card off-screen to the right */
@keyframes ppbet-rotateAndMoveOffScreen {
    0% {
        transform: rotate(90deg) translate(0, 0); /* Initial rotation and position */
        opacity: 1;
    }
    10% {
        transform: rotate(0deg); /* Rotate back to 0 degrees */
    }
    100% {
        transform: translateX(100vw); /* Move off-screen to the right */
        opacity: 0; /* Fade out */
    }
}

.ppbet-face-up-card-animation {
    position: absolute;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

















.belote-game-buttonsIcons {
    position: absolute;
    bottom: 100%;
    right: 12px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.belote-textIcon-buttonIcon,
.belote-settingsIcon-buttonIcon {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 8px; /* Space between buttons */
    padding: 1px;
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
    -webkit-tap-highlight-color: transparent; /* Remove iOS tap highlight */
}

.belote-textIcon-buttonIcon img,
.belote-settingsIcon-buttonIcon img {
  width: 25px;
  height: 25px;
}

.belote-textIcon-buttonIcon:focus,
.belote-textIcon-buttonIcon:hover,
.belote-textIcon-buttonIcon:active,
.belote-settingsIcon-buttonIcon:focus,
.belote-settingsIcon-buttonIcon:hover,
.belote-settingsIcon-buttonIcon:active,
.belote-soundToggle-buttonExt:focus,
.belote-soundToggle-buttonExt:hover,
.belote-soundToggle-buttonExt:active,
.belote-leaveGame-buttonExt:focus,
.belote-leaveGame-buttonExt:hover,
.belote-leaveGame-buttonExt:active  {
  outline: none;
  box-shadow: none;
  background: none; /* Remove any background styles */
}

.belote-settings-expanded {
    position: absolute;
    bottom: 0%;
    right: 40px; /* Adjust for desired spacing */
    z-index: 9;
    display: flex;
    opacity: 0;
    transform: translateX(10px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none; /* Prevent interaction when hidden */
}

.belote-leaveGame-buttonExt,
.belote-soundToggle-buttonExt{
background: none;
margin: 8px;
padding: 1px;
border: none;
}

/* When settings is active, show expanded buttons */
.belote-settingsIcon-buttonIcon.active + .belote-settings-expanded {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto; /* Enable interaction */
}



.belote-leaveGame-buttonExt img,
.belote-soundToggle-buttonExt img {
    width: 25px;
    height: 25px;
}



.belote-dropdown-menu {
    position: absolute;
    bottom: 105%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    width: 150px;
    padding: 8px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 6000;
}

.belote-dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.belote-dropdown-menu li {
    color: #fff;
    padding: 8px 16px;
    cursor: pointer;
    text-align: center;
}

.belote-dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}





/* Message Popups */
.belote-message-popup {
    position: absolute;
    z-index: 1000;
  }
  
  .belote-message-popup-inner {
    background-color: white;
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
    max-width: 150px;
   
  }
  
  /* Player Message Position */
  .belote-player-message {
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    animation: belote-massagePopupAnimation 0.4s ease-out;
    z-index: 5000;
  }
  
  .belote-player-message-inner::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  /* Opponent Message Position */
  .belote-opponent-message {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    animation: belote-massagePopupAnimation 0.4s ease-out;
  }
  
  .belote-opponent-message-inner::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  

/* Animation for smooth popup appearance */
@keyframes belote-massagePopupAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}


.belote-leave-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: carrom-popupAnimation 0.4s ease-out;
  }
  

  
  .belote-leave-game-header {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .belote-leave-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
  }
  
  .belote-leave-game-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .belote-leave-game-btn {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    width: 45%;
    text-align: center;
  }
  
  .belote-leave-game-btn:hover {
    background-color: #666;
  }
  





   /* Container for the end reason message */
.belote-end-reason-container {
    background-color: rgba(255, 255, 255, 0.1);  /* Lighter dark background to stand out */
    padding: 10px;
    margin-top: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.3);  /* Subtle border to distinguish it */
}

/* Icon for the end reason */
.belote-end-reason-icon {
    margin-right: 8px;
    font-size: 18px;
}

/* Reason text */
.belote-end-reason-text {
    display: flex;
    align-items: center;
}



@keyframes cardEntryAnimation {
    0% {
        transform: rotate(var(--card-rotation)) translateY(100px); /* Start 100px lower */
        opacity: 0;
    }
    100% {
        transform: rotate(var(--card-rotation)) translateY(var(--card-translateY)); /* Move to final position */
        opacity: 1;
    }
}

.cardEntryAnimation {
    animation: cardEntryAnimation 0.6s ease-in-out forwards;
}


@keyframes opponentHandAppear {
    0% {
        transform: translateY(-100px); /* Start above the screen */
        opacity: 0; /* Fully transparent */
    }
    100% {
        transform: translateY(0); /* Move to the original position */
        opacity: 1; /* Fully visible */
    }
}






/* Adjusted keyframes to retain the rotation */
/* Adjusted keyframes to retain both the rotation and translateY */
@keyframes moveUpDown {
    0% {
        transform: rotate(var(--card-rotation)) translateY(var(--card-translateY)); /* Start with original rotation and translateY */
    }
    30% {
        transform: rotate(var(--card-rotation)) translateY(calc(var(--card-translateY) - 32px)); /* Move up faster */
    }
    60% {
        transform: rotate(var(--card-rotation)) translateY(calc(var(--card-translateY) - 32px)); /* Hold at the top */
    }
    100% {
        transform: rotate(var(--card-rotation)) translateY(var(--card-translateY)); /* Return to original position */
    }
}


/* Add animation to specific cards that need it */
.card-animate-updown {
    animation: moveUpDown 2.5s ease-in-out forwards;
}


.player-hand {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute; /* Change position to absolute */
    bottom: -14px; /* Anchor to the bottom of the page */
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ensure it spans the full width */
    height: 150px; /* Adjust height as needed */
    z-index: 1001; /* Ensure it overlays the hand */
   
}


/* Style for individual cards within the hand */
.player-hand .card {
    position: relative;
    transform-origin: bottom center;
    transition: transform 0.3s ease;
    will-change: transform;
}

.player-hand.eight-cards .card {
    margin-right: -25px;
    margin-left: -25px;
}
.player-hand.seven-cards .card {
    margin-right: -22px; /* Slightly reduce overlap */
    margin-left: -22px;
}

.player-hand.six-cards .card {
    margin-right: -20px; /* Further reduce overlap */
    margin-left: -20px;
}

.player-hand.five-cards .card {
    margin-right: -18px; /* Increase spacing as cards decrease */
    margin-left: -18px;
}

.player-hand.four-cards .card {
    margin-right: -15px;
    margin-left: -15px;
}

.player-hand.three-cards .card {
    margin-right: -12px;
    margin-left: -12px;
}

.player-hand.two-cards .card {
    margin-right: -10px;
    margin-left: -10px;
}

/* Styles specifically for an 8-card hand */
.player-hand.eight-cards .card:nth-child(1) {
    --card-rotation: -9.6deg;
    --card-translateY: 12.8px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(2) {
    --card-rotation: -6.4deg;
    --card-translateY: 6.4px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

  
}

.player-hand.eight-cards .card:nth-child(3) {
    --card-rotation: -3.2deg;
    --card-translateY: 3.2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

}

.player-hand.eight-cards .card:nth-child(4) {
    --card-rotation: -1.4deg;
    --card-translateY: 0.5px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(5) {
    --card-rotation: 1.4deg;
    --card-translateY: 0px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(6) {
    --card-rotation: 3.2deg;
    --card-translateY: 2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

  
}

.player-hand.eight-cards .card:nth-child(7) {
    --card-rotation: 6.4deg;
    --card-translateY: 6.4px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(8) {
    --card-rotation: 9.6deg;
    --card-translateY: 12.8px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

   
}



/* Styles specifically for a 7-card hand */
.player-hand.seven-cards .card:nth-child(1) {
    transform: rotate(-9.6deg) translateY(12.8px); /* Rotate leftmost card and move down */
}

.player-hand.seven-cards .card:nth-child(2) {
    transform: rotate(-6.4deg) translateY(6.4px); /* Rotate next card slightly and move down */
}

.player-hand.seven-cards .card:nth-child(3) {
    transform: rotate(-3.2deg) translateY(3.2px); /* Rotate next card slightly and move down */
}

/* Middle card - keep vertical and move slightly up */
.player-hand.seven-cards .card:nth-child(4) {
    transform: rotate(0deg) translateY(2px); /* Keep middle card vertical  */
}

.player-hand.seven-cards .card:nth-child(5) {
    transform: rotate(3.2deg) translateY(3.2px); /* Rotate next card slightly and move down */
}

.player-hand.seven-cards .card:nth-child(6) {
    transform: rotate(6.4deg) translateY(6.4px); /* Rotate next card slightly and move down */
}

.player-hand.seven-cards .card:nth-child(7) {
    transform: rotate(9.6deg) translateY(14px); /* Rotate rightmost card and move down */
}

/* Styles specifically for a 6-card hand */
.player-hand.six-cards .card:nth-child(1) {
    transform: rotate(-8deg) translateY(10.2px); /* Rotate leftmost card and move down */
}

.player-hand.six-cards .card:nth-child(2) {
    transform: rotate(-4deg) translateY(5.1px); /* Rotate next card slightly and move down */
}

/* Middle left card - slight inward rotation */
.player-hand.six-cards .card:nth-child(3) {
    transform: rotate(-1.28deg) translateY(2px); /* Slight inward rotation */
}

/* Middle right card - slight inward rotation */
.player-hand.six-cards .card:nth-child(4) {
    transform: rotate(1.28deg) translateY(2px); /* Slight inward rotation */
}

.player-hand.six-cards .card:nth-child(5) {
    transform: rotate(4deg) translateY(5.1px); /* Rotate next card slightly and move down */
}

.player-hand.six-cards .card:nth-child(6) {
    transform: rotate(8deg) translateY(10.2px); /* Rotate rightmost card and move down */
}

/* Styles specifically for a 5-card hand */
.player-hand.five-cards .card:nth-child(1) {
    --card-rotation: -8deg;
    --card-translateY: 10.2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(2) {
    --card-rotation: -4deg;
    --card-translateY: 5.1px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(3) {
    --card-rotation: 0deg;
    --card-translateY: 2.5px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(4) {
    --card-rotation: 4deg;
    --card-translateY: 5.1px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(5) {
    --card-rotation: 8deg;
    --card-translateY: 10.2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}



/* Styles specifically for a 4-card hand */

.player-hand.four-cards .card:nth-child(1) {
    transform: rotate(-6.4deg) translateY(8.5px); /* Rotate leftmost card and move down */
}

.player-hand.four-cards .card:nth-child(2) {
    transform: rotate(-2.5deg) translateY(4.25px); /* Rotate next card slightly and move down */
}

/* Middle right card - slight inward rotation */
.player-hand.four-cards .card:nth-child(3) {
    transform: rotate(2.5deg) translateY(4.25px); /* Slight inward rotation */
}

.player-hand.four-cards .card:nth-child(4) {
    transform: rotate(6.4deg) translateY(8.5px); /* Rotate rightmost card and move down */
}




/* Styles specifically for a 3-card hand */
.player-hand.three-cards .card:nth-child(1) {
    transform: rotate(-6.4deg) translateY(8.5px); /* Rotate leftmost card and move down */
}

/* Middle card - keep vertical */
.player-hand.three-cards .card:nth-child(2) {
    transform: rotate(0deg) translateY(4px); /* Middle card vertical */
}

.player-hand.three-cards .card:nth-child(3) {
    transform: rotate(6.4deg) translateY(8.5px); /* Rotate rightmost card and move down */
}


/* Styles specifically for a 2-card hand */
.player-hand.two-cards .card:nth-child(1) {
    transform: rotate(-2.5deg) translateY(6px); /* Rotate leftmost card and move down */
}

.player-hand.two-cards .card:nth-child(2) {
    transform: rotate(2.5deg) translateY(6px); /* Rotate rightmost card and move down */
}

/* Styles specifically for a 1-card hand */
.player-hand.one-card .card:nth-child(1) {
    transform: rotate(0deg) translateY(0px); /* Single card stays vertical */
}




.opponent-hand {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute; /* Change position to absolute */
    top: -12%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ensure it spans the full width */
    height: 150px; /* Adjust height as needed */
    z-index: 1; /* Keep it behind popups */

}


/* Style for the card back images */
.opponent-hand .card-back {
    position: relative;
    width: 80px;
    height: 120px; /* Full height of the card */
    margin-left: -35px; /* Adjust overlap for smaller cards */
    margin-right: -35px;
}

/* Position the image to only show more than half */
.opponent-hand .card-back img {
    position: absolute;
    top: -48px; /* Adjust the position to show more of the card */
    width: 80px;
    height: 120px;
}



.opponent-combination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute; /* Change position to absolute */
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ensure it spans the full width */
    height: 150px; /* Adjust height as needed */
    z-index: 1; /* Keep it behind popups */
}

.combination-card {
    width: 50px; /* Smaller width for the cards */
    height: 80px; /* Smaller height for the cards */
    position: relative;
    z-index: 1000; /* Ensure they appear above other elements */
    transition: transform 0.3s ease; /* Smooth appearance */
    margin-left: -20px; /* Overlap the previous card */
}

.combination-card img {
    width: 100%;
    height: 100%;
    border-radius: 1px; /* Keep rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for cards */
}


/* Player Label Container */
.player-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.85); /* More transparent background */
    border-radius: 8px;
    box-shadow: 0 0 1px #e9e9e9; /* Yellow subtle shadow */
    transform: scale(0.85);
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 115px;
    z-index: 2000;
    overflow: hidden;
}

/* Opponent Section */
.opponent-section .player-label {
    position: absolute;
    top: 2px;
    bottom: auto;
    z-index: 2;
}

/* Player Icon/Avatar */
.player-label .player-icon {
    width: 35px;
    height: 35px;
    margin-right: 8px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #D9D9D9; /* Fallback background for initials */
    font-size: 16px; /* Adjust size for initials */
    font-weight: bold;
    color: #ffffff;
    overflow: hidden;
}

.player-label .player-icon img {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    object-fit: cover;
}

.player-initial {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px; /* Match font size with avatar size */
    font-weight: bold;
    color: #464646;
    background-color: #D9D9D9; /* Fallback color for initials */
    border-radius: 50%;
}


/* Name and Flag Container */
.name-and-flag {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Player Nickname */
.player-label .player-name {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis if name is too long */
}

/* Country Flag */
.country-flagL {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.player-score {
    font-size: 10px;
    color: #ffde59; /* Lighter color for score */
    font-weight: normal;
    margin-top: 0px;
    display: block; /* Place score under the name */
}

/* Round Points */
.round-points {
    font-size: 12px;
    font-weight: normal;
    color: #f0f0f0; /* Red for round points to highlight */
    display: block;
}

/* Trump Suit Icon */
.trump-icon {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(25%);
    width: 17px;
    height: 17px;
    background: #ffffff;
    border-radius: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.trump-icon img {
    width: 70%;
    height: 70%;
}

/* SVG Circles for the Timer Effect */
.player-icon svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    z-index: 1;
}

/* Base Circle always visible */
.player-icon circle.base {
    fill: none;
    stroke: #e9e9e9;
    stroke-width: 2;
    stroke-dasharray: 188;
    stroke-dashoffset: 0;
}

/* Timer Circle that animates */

.player-icon circle.timer {
    fill: none;
    stroke-width: 3;
    stroke-dasharray: 188;
    stroke-dashoffset: 188;
    transition: none;
}

/* Highlighted Player with Timer Animation */
.player-section.highlighted.waiting-for-move .player-icon circle.timer,
.opponent-section.highlighted.waiting-for-move .player-icon circle.timer {
    transition: stroke-dashoffset 25s linear;
    stroke-dashoffset: 0;
}



/* Hide the timer instantly when it's not the player's turn */
.player-section:not(.highlighted) .player-icon circle.timer,
.opponent-section:not(.highlighted) .player-icon circle.timer {
    stroke-dashoffset: 188;
    transition: none;
}

/* Highlight the player label with yellow border when it's their turn */
.player-section.highlighted .player-label {
    border-top: 2.5px solid #13C513; /* Yellow border on top */
    box-shadow: 0 -1.5px 2.5px #13C513;
}

.opponent-section.highlighted .player-label {
    border-bottom: 2.5px solid #13C513;
    box-shadow: 0 1.5px 2.5px #13C513;
}



/* Scale up the avatar and timer when it's the player's turn */
.opponent-section.highlighted.waiting-for-move .player-icon,
.player-section.highlighted.waiting-for-move .player-icon {
    transform: scale(1.22); /* Increase size */
    transition: transform 0.35s ease-in-out; /* Smooth transition */
}

/* Revert to normal size when the class is removed */
.opponent-section .player-icon,
.player-section .player-icon {
    transform: scale(1); /* Normal size */
    transition: transform 0.25s ease-in-out;
}

/* GamePoints.css */
.game-points-container {
    position: fixed;
    top: 10px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 8px 8px; /* Slightly increased padding */
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 11px; /* Slightly larger font size */
    width: 90px; /* Increased width slightly */
    z-index: 1000;
}

.game-points-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding-bottom: 6px;
    margin-bottom: 4px; /* Slightly increased spacing */
    font-size: 15px;
}

.game-points-values {
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 4px; /* Slightly increased padding */
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Slightly more visible border */
}

.game-points-values span {
    flex: 1 1;
    text-align: center;
    font-size: 11px; /* Slightly larger font for values */
    font-weight: bold;
}

.game-points-divider {
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3); /* Slightly more visible divider */
    margin: 0 3px; /* Slightly increased space around the divider */
}




/* Unique class for general popup styling */
.ppbet-round-results-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: ppbet-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance with scaling */
@keyframes ppbet-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5); /* Start from smaller size */
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1); /* Grow to full size */
    }
}

.ppbet-result-header {
    text-align: center;
}

/* Win/Lose Status Styles */
.ppbet-round-status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
}

.ppbet-round-status h3 {
    margin: 0; /* Remove default margin */
    padding: 5px 0; /* Specific padding for h3 */
}

.ppbet-round-status.ppbet-win h3 {
    color: #32cd32; /* Green for win */
}

.ppbet-round-status.ppbet-lose h3 {
    color: #e74c3c; /* Red for loss */
}

.ppbet-results-table {
    margin-top: 15px;
}

.ppbet-results-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
}

/* Header Row */
.ppbet-results-row.ppbet-header {
    font-weight: bold;
    font-size: 15px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
    white-space: nowrap;
    
}

/* Total Row */
.ppbet-results-row.ppbet-total {
    font-weight: bold;
    font-size: 18px;
    background: linear-gradient(135deg, #191919c4, #252525c4);

    border-top: 2px solid rgba(255, 255, 255, 0.3);
}

/* Equal column widths for cleaner layout */
.ppbet-results-row > div {
    flex: 1 1;
    text-align: center;
    max-width: 85px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}






/* Unique class for the end game popup */
.ppbet-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 8001;
    width: 280px;
    max-height: 80%;
    animation: ppbet-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes ppbet-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.ppbet-end-game-header {
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
}

.ppbet-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.ppbet-end-game-header h3.ppbet-win {
    color: #32cd32; /* Green for win */
}

.ppbet-end-game-header h3.ppbet-lose {
    color: #e74c3c; /* Red for loss */
}

.ppbet-end-game-results-table {
    margin-top: 15px;
    text-align: center; /* Center the content */
}

.ppbet-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.ppbet-end-game-score {
    font-size: 1.5rem; /* Increase font size for better visibility */
    font-weight: bold;
}

.ppbet-end-game-colon {
    padding: 0 10px; /* Space between the scores */
    color: #fff; /* Keep the colon visible */
}

.ppbet-end-game-winner-score {
    color: #ffffff; /* Green for the winner */
}

.ppbet-end-game-loser-score {
    color: #a9a9a9; /* Gray for the loser */
}



/* General Row Styling */
.ppbet-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column - Consistent Width */
.ppbet-results-left {
    display: flex;
    align-items: center;
    width: 150px; /* Fixed width for all left column elements */
}

/* Score Row */
.ppbet-score-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Icon Styling */
.ppbet-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px; /* Reduce the margin */
}

.ppbet-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Current Score and Score Result */
.ppbet-current-score {
    font-size: 1rem; /* Keep font size the same as other text */
    font-weight: bold;
    display: flex;
    align-items: center; /* Align icon and text vertically */
    margin-right: 10px; /* Ensure spacing between the current score and score result */
}

.ppbet-score-result {
    font-size: 1rem; /* Normalize font size */
    font-weight: bold;
    margin-left: 5px; /* Ensure spacing between the score and result */
}

.ppbet-score-positive {
    color: #ffffff;
}

.ppbet-score-negative {
    color: #ffffff;
}

/* Bet Amount Row */
.ppbet-bet-amount-container {
    display: flex;
    align-items: center;
}

.ppbet-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Dollar Sign Styling */

.ppbet-dollar-signBET {
    color: #ccc;
    margin-right: 5px;
}

.ppbet-dollar-sign {
    font-size: 1.3rem;
    color: #ccc;
}

/* Winning Amount */
.ppbet-winning-row .ppbet-winning-amount {
    font-size: 1.4rem; /* Larger size for winning amount */
    font-weight: bold;
    color: #32cd32; /* Green color */
}

/* Align Columns Properly */
.ppbet-results-right {
    text-align: right;
    flex: 1 1;
    padding-left: 10px; /* Add space between text and right column */
}

/* Additional Spacing for Left and Right Columns */
.ppbet-results-left {
    display: flex;
    align-items: center;
    width: 160px; /* Fixed width for consistent alignment */
}

.ppbet-results-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1;
}

/* LastPlayedHand.css */
.last-played-hand-container {
   position: fixed;
    top: 10px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.6); /* More transparent background */
    color: white;
    padding: 8px 6px; /* Smaller padding */
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); /* Reduced shadow */
    text-align: center;
    font-size: 10px; /* Smaller font size */
    width: 80px; /* Smaller fixed width */

   
    z-index: 1000; /* Make sure it stays on top */
}

.last-played-hand-header {
    font-weight: bold;
    padding-bottom: 2px;
    margin-bottom: 3px;
}

.last-played-hand-cards {
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}

.last-played-card {
    width: 38px;
    height: 55px;
    background-size: cover;
    border-radius: 1px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
}

.last-played-card.empty {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px dashed rgba(255, 255, 255, 0.6);
}

.last-played-card img {
    width: 100%;
    height: 100%;
    position: absolute;

}


.deposit-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}



/* Page Title */
.deposit-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
    color: #FFFFFF;
    text-align: center; /* Center-aligns the title */
}

/* Instructions Section */
.instructions-section {
    text-align: left;
    margin-bottom: 30px;
}



.instructions-title {
    font-size: 14px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 200;
    margin-bottom: 15px;
}

.instructions-border {
    border: 1px solid #383434;
    padding: 15px;
    border-radius: 10px;
    background-color: #282828;
    margin-bottom: 50px;
}

.instruction-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.instruction-number {
    background-color: #444;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0; /* Prevents the circle from shrinking */
}


.instruction-item span {
    color: #E0E0E0;
    font-size: 14px;
    font-weight: normal;
}


/* Currency Section */
.currency-section {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.currency-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.currency-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
}

.coming-soon {
    opacity: 0.5;
    cursor: not-allowed;
}

.deposit-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align popup to the bottom */
    z-index: 2000;
}

.deposit-popup-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 2001;
    animation: slideUp 0.3s ease;
}

.deposit-popup-container.closing {
    animation: slideDown 0.2s ease;
}

.deposit-popup {
    background-color: #282828;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    padding-bottom: 110px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    max-height: 70vh; /* Limit popup height */
    overflow-y: auto; /* Allow scrolling inside the popup */
}

  
  .deposit-popup-header {
    display: flex;
    justify-content: center; /* Center icon and text horizontally */
    align-items: center;     /* Center icon and text vertically */
    padding-bottom: 0px;
    margin-top: -18px; /* Move the title and button slightly up */
    position: relative;      /* Position close button on the right */
  }
  
  .deposit-popup-currency-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .deposit-popup-currency-label {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
  }
  
  .deposit-popup-close {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: -15px; /* Moves it to the right */
  }
  
  .deposit-popup-close:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
  }
  
  .deposit-popup-warning {
    font-size: 14px;
    color: grey;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  
  .qr-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;
}



.wallet-address-text {
    font-size: 12px;
    color: #FFFFFF;
    word-break: break-all;
    margin-top: 10px;
}

.copy-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
    margin: 0 auto; /* This centers the button */
}

.copy-button:hover {
    background-color: #dec000;
}

.copy-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.copied-message {
    color: green;
    font-size: 12px;
}

.normal-text {
    font-size: 14px;
    font-weight: 100; /* Light font */
    color: white;
}

.bold-text {
    font-size: 14px;
    font-weight: bold; /* Bold font for the amount */
    color: white;
}

.rate-info {
    font-size: 12px;
    color: grey;
    margin-top: 5px;
  }
  
.withdraw-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.withdraw-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center; /* Center-aligns the title */
}

.step-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 100;
    margin-top: 40px;
    margin-bottom: 15px;
    text-align: left;
}



.address-input-container {
 
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;   
    
}

.address-input {
    width: calc(100% - 25px); 
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555; /* Adds a dark border to the text box */
    background-color: #1E1E1E; /* Dark background for the text box */
    color: white; /* White text to ensure readability */
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 16px; /* Prevents zooming on mobile */
}

.address-input:focus {
    border-color: #dfdfdf; /* Same as the regular border color */
    outline: none; /* Remove the blue outline */
    box-shadow: 0 0 2px rgb(255, 255, 255);
}


.invalid {
    border-color: #ff4d4d;
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.paste-button, .qr-scan-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2px;
    margin-bottom: 10px;
}

.paste-button {
    font-size: 18px;
    color: #FFD700;
}

.paste-button:hover {
    background-color: #121212;
}

.qr-scan-button {
    margin-left: 10px;
}


.qr-scan-button:hover {
    background-color: #121212;
}

.qr-scan-icon {
    width: 18px;
    height: 18px;
}

.qr-scanner-title {
    font-size: 20px;
    color: white;
    text-align: center;
    margin-bottom: 8px;
}

.qr-scanner-subtitle {
    font-size: 14px;
    color: lightgray;
    text-align: center;
    margin-bottom: 16px;
}


.continue-button, .withdraw-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto; /* This centers the button */
    margin-bottom: 80px;
}

.continue-button:hover{
    background-color: #dec000;
}

.withdraw-button:hover{
    background-color: #dec000;
}

.error-message {
    color: #ff4d4d;
    font-size: 14px;
    margin-top: 10px; /* This adds space between the button and the message */
    margin-bottom: 15px;
    text-align: center; /* Align the message in the center */
}

.success-message {
    color: #28a745; /* Green color for success */
    font-size: 14px;
    margin-top: 10px; /* This adds space between the button and the message */
    margin-bottom: 15px;
    text-align: center; /* Align the message in the center */
}
    

.info-section {
    margin-top: 20px;
    font-size: 12px;
    color: grey;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 10px;
}

.warning-text {
    color: grey;
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: -8px;
}


.qr-scanner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Dim background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.qr-scanner-popup-unique {
    background-color: #121212;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    width: 90%;
    position: relative;
}

.qr-scanner-frame {
    border: 2px solid #FFFFFF;
    padding: 5px; /* Reduce padding slightly */
    border-radius: 10px;
    width: 80%; /* Reduce width for smaller frame */
    max-width: 250px; /* Decrease max width to make it smaller */
    margin: 0 auto 15px; /* Center the frame and add bottom margin */
}


.unique-close-button {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle grey background */
    color: #9E9E9E;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 24px;
    cursor: pointer;
    margin-top: 15px;   /* Adds space below the video */
    align-self: center;
}

.unique-close-button:hover{
    background-color: rgba(184, 184, 184, 0.2); /* Subtle hover effect */
}

.address-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.address-popup {
    background-color: #282828;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 90%;
    max-width: 400px;
    
}

.address-popup h4 {
    font-size: 16px; /* You can set this to any size you prefer */
    word-break: break-all; /* Ensures long addresses break to the next line */
    margin-top: 40px;
}

.disconnect-button {
    background-color: #FFD700;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.disconnect-button:hover{
    background-color: #dec000;
}

.disconnect-close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #9E9E9E;
}

.disconnect-close-popup:hover{
    background: none;
}

.attached-address-container {
    margin-bottom: 10px;
   }

.arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
}

.attached-address-titul{
    font-size: 16px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 100;
    margin-top: 40px;
    margin-bottom: 15px;
    text-align: left;
}

.attached-address {
    display: flex;
}

.attached-address-text {
    font-size: 12px;
    color: grey;
    flex-grow: 1;  /* Ensures the text takes all available space */
    word-wrap: break-word;  /* Prevents long addresses from overflowing */
    margin-top: -10px;
}

.arrow-icon {
    font-size: 18px;
    color: #FFD700;  /* Yellow color for better visibility */
    margin-top: -20px;
}

.amount-title{
    font-size: 16px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 100;
    margin-top: 15px;
    margin-bottom: 8px;
    text-align: left;
}


.amount-input-container {
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}

.amount-input {
    width: 100%;
    padding: 10px;
    padding-right: 20px; /* Add padding to the right for the button space */
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    box-sizing: border-box;
    font-size: 16px;
}

.amount-input:focus {
    border-color: #dfdfdf; /* Same as the regular border color */
    outline: none; /* Remove the blue outline */
    box-shadow: 0 0 2px rgb(255, 255, 255);
}

/* For Chrome, Safari, Edge, and Opera */
.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.max-button {
    position: absolute;
    right: 0px; /* Align button inside input on the right */
    top: 50%;
    transform: translateY(-50%);
    background-color: #1E1E1E;
    color: rgb(158, 158, 158);
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    margin-top: 0px;
    cursor: pointer;
}

.max-button:hover{
    background-color: #555555;
}


/* Styling for the fee and withdrawal information */
.fee-info {
    margin-top: -40px;
    margin-bottom: 20px;
    padding: 4px 0px;
    background-color: #1e1e1e; /* Dark background to match theme */
    border-radius: 5px;
    color: rgb(255, 255, 255); /* Ensure readability on the dark background */
}

.fee-details {
    font-size: 12px;
    margin: 5px 0; /* Adjusts spacing between the fee and withdrawal amount */
}

.withdrawal-warning {
    margin-bottom: 15px;
    color: #ff4d4d;
    font-size: 12px;

}

.withdraw-disabled {
    opacity: 0.5; /* Dim the button when disabled */
    cursor: not-allowed;
}


.spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 2s linear infinite;
    margin-right: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}




.transaction-history-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

body {
    background-color: #121212; /* Ensure the body also has a black background */
}

html {
    height: 100%; /* Ensure the html element stretches full height */
}

.history-page-title{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center; /* Center-aligns the title */

}

.filter-container {
    margin-bottom: 24px;
    margin-top: 24px;
}
.filter-container select {
    background-color: #121212; /* Black background */
    color: #fff; /* White text */
    border: 1px solid #555; /* Optional: add a border for better visibility */
    padding: 5px;
    border-radius: 4px;
}

.transaction-group{
    margin-bottom: 30px;
}

.transaction-fullList{
    margin-bottom: 80px;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #333;
    cursor: pointer;
}

.transaction-date{
    font-size: 14px;
    color: #ffffff;
}

.transaction-info {
    display: flex;
    flex-direction: column;
}

.transaction-amount {
    font-size: 18px;
}

.transaction-type {
    font-size: 14px;
    color: #888;
}

.transaction-status {
    font-size: 12px;
    color: #888; /* Keep the existing color for the status label "Status:" */
}

.status-name {
    margin-left: 8px; /* Add some space between the word "Status:" and the status name */
}

.status-name.completed {
    color: #13C513; /* Green for completed */
}

.status-name.pending {
    color: #FFD700; /* Yellow for pending */
}

.status-name.failed {
    color: #e74c3c; /* Red for failed */
}




.transaction-arrow {
    width: 20px;
    height: 20px;
    filter: invert(100%); /* Make the arrow white */
}
/* Overlay styling to cover the whole screen */
/* Existing CSS remains unchanged */

.transaction-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

/* Main popup styling */
.transaction-popup {
    background-color: #282828; /* Match the app's dark theme */
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    text-align: center;
    position: relative;
    max-height: 90vh; /* Ensure popup doesn't overflow */
    overflow-y: auto; /* Allow scrolling if content exceeds the height */
    word-wrap: break-word; /* Force long words to wrap */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Separator line between title and content */
.popup-separator {
    border: 0;
    height: 1px;
    background-color: #555; /* Grey line */
    margin: 10px 0;
}

/* Unique Popup header */
.transaction-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items vertically in the center */
    margin-bottom: 10px;
}

/* Unique Popup title */
.transaction-popup-title {
    color: white; /* Make the title white */
    margin: 0;
    font-size: 18px;
    text-align: left; /* Keep the title aligned to the left */
}

/* Row labels (field names) */
.row-label {
    color: #9E9E9E; /* Grey color for labels */
    font-weight: bold;
}

/* Row data (actual transaction values) */
.row-data {
    color: white; /* White color for data */
}

/* Status-specific colors */
.status-completed {
    color: #13C513; /* Green for completed */
}

.status-pending {
    color: #FFD700; /* Yellow for pending */
}

.status-failed {
    color: #e74c3c; /* Red for failed */
}

.empty-message{
    color: #9E9E9E;
}

/* Unique close button for this transaction popup */
.transaction-close-btn {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: 0px; /* Moves it to the right */
    top:10px;
}

.transaction-close-btn:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
}

/* Ensure long content wraps */
.transaction-popup p {
    word-wrap: break-word; /* Ensure long words wrap */
    white-space: normal; /* Breaks long text into new lines */
}


/* Ensure the loading message has the same black background and white text */
.loading-message {
    background-color: #121212; /* Black background */
    color: #fff; /* White text */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center; /* Center horizontally */
    padding-top: 80px; /* Add space from the top */
    font-size: 18px;
}




/* Profile Container */
.profile-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.profile-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Nickname and Country Fields */
.profile-section {
    margin-bottom: 20px;
}

.profile-label {
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

.nickname-edit {
    display: flex;
    align-items: center;
    position: relative;
}

.profile-input {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    font-size: 16px;
}

.profile-input:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255,255);
    outline: none;
}

.edit-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.profile-select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-top: 5px;
}

.profile-select:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none;
}

/* Statistics Section */
.profile-statistics {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 80px;

}
.profile-circle-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    background-color: #282828;
    border: 1px solid #383434;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 20px 40px 50px 40px;
    border-radius: 12px;
    grid-gap: 15px;
    gap: 15px;
}

.profile-circle {
    position: relative;
    width: 90px;
    height: 90px;
    text-align: center;
}

.profile-circle svg {
    width: 90px;
    height: 90px;
}

.stat-number {
    position: absolute;
    top: 38%; /* Adjusted to center correctly */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px; /* Slightly smaller to fit */
    font-weight: bold;
    color: white;
}

.stat-label {
    position: absolute;
    top: 52%; /* Adjusted to center correctly */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: white;
}


.stat-percentage {
    margin-top: 10px;
    font-size: 16px !important;
    color: white;
}

.profile-circle p {
    margin-top: 10px;
    font-size: 14px;
    color: #FFFFFF;
}

.profile-circle-container p {
    font-size: 12px;
    color: #9E9E9E;
}


/* Animated Progress Circles */
@keyframes drawCircle {
    from {
        stroke-dashoffset: 251; /* Start fully hidden (full circle) */
    }
    to {
        stroke-dashoffset: var(--stroke-dashoffset); /* End at the calculated percentage */
    }
}


.progress-circleX {
    animation: drawCircle 1s ease-out forwards;
    stroke-dasharray: 251 251;
    stroke-dashoffset: var(--stroke-dashoffset); /* Dynamically set based on percentage */
    transition: stroke-dashoffset 1s ease-out;
}


/* Save Button */
.profile-save-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
  
}

.profile-save-button:hover {
    background-color: #dec000;
}

.profile-button-loading {
    background-color: #dec000;
    cursor: not-allowed;
    opacity: 0.7;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Confirmation Popup Overlay */
.profile-update-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Popup Container */
.profile-update-popup {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
}

/* Popup Title */
.popup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Popup Message */
.popup-message {
    font-size: 18px;
    margin-bottom: 20px;
}

/* Close Button */
.profile-popup-close-button {
    background-color: #FFD700;
    color: black;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.profile-popup-close-button:hover {
    background-color: #dec000;
}

.profile-label-country-select {
font-size: 14px;
margin-bottom: 10px;
display: block;
}

/* Profile Popup Overlay */
.profile-update-popup-overlay-country-select {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Popup Container */
.profile-update-popup-country-select {
    background-color: #1E1E1E; /* Same background as input fields */
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
    border: 2px solid #555555; /* Consistent with input borders */
}

/* Popup Title */
.popup-title-country-select {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Popup Message */
.popup-message-country-select {
    font-size: 16px;
    margin-bottom: 20px;
}

/* Close Button */
.profile-popup-close-button-country-select {
    background-color: #FFD700;
    color: black;
    padding: 10px 30px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

.profile-popup-close-button-country-select:hover {
    background-color: #dec000;
}

/* Dropdown Styles - Specific for Country Selection */
.custom-dropdown-country-select {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
}

.selected-country-country-select {
    display: flex;
    align-items: center;
}

.flag-icon-country-select {
    width: 20px;
    height: 15px;
    margin-right: 10px;
}

.dropdown-list-country-select {
    position: absolute;
    width: calc(100% - 65px);
    max-height: 60vh;
    background-color: #1E1E1E;
    border: 2px solid #555555;
    z-index: 10;
    overflow-y: auto;
    margin-top: 10px;
}

.dropdown-item-country-select {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #1E1E1E;
    border-bottom: 1px solid #555555;
}

.dropdown-item-country-select:hover {
    background-color: #2E2E2E;
    border-color: #eaeaea;
}

.search-input-country-select {
    width: calc(100% - 20px);
    padding: 8px 10px;
    font-size: 16px;

    background-color: #1E1E1E;
    color: white;
}

.search-input-country-select::placeholder {
    color: #999999;
}

.no-results-country-select {
    padding: 10px;
    text-align: center;
    color: #ff4d4d;
    font-size: 14px;
}

.search-input-country-select:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255,255);
    outline: none;
}
.friends-page-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    position: relative ;  /* For fixed buttons */

}

.claimFriends-reward-popup-overlay {
    position: absolute;
    top: 0; /* Center vertically */
    left: 0; /* Center horizontally */
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
}


.claimFriends-reward-popup {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
    max-width: 90%; 
    max-height: 90%; 
    overflow: auto; 
}

.claimFriendspopup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.claimFriendspopup-message {
    font-size: 18px;
    margin-bottom: 20px;
}

/* Unique class for the close button */
.claimFriends-popup-close-button {
    background-color: #FFD700;
    color: black;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.claimFriends-popup-close-button:hover {
    background-color: #dec000;
}



.friends-page-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Claim Rewards Section */
.claim-rewards-section {
    text-align: center;
    margin-bottom: 20px;
}

.claim-amount {
    font-size: 36px;
    font-weight: bold;
    color: #FFFFFF;
}
.claim-amount-decimal {
    font-size: 28px; /* Smaller font size for the decimal part */
    font-weight: normal;
    color: #FFFFFF;
    
}

.currency-symbol {
    color: #A9A9A9;
    margin-right: 5px;
    font-size: 34px;
}

.currency-symbol-on-row {
    color: #A9A9A9;
    margin-right: 5px;
 
}


.claim-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 40px;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
    margin-bottom: 0px;
}

.claim-button:hover {
    background-color: #dec000;
}

.claim-button.disabled {
    background-color: #999;
    opacity: 0.7;
}



/* Explanation Text */
.explanation-text {
    text-align: center;
    font-size: 13px;
    color: #ccc;
    margin-bottom: 40px;
}

/* Friends Section */
.friends-count-title {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
}

.friends-list {
    margin-bottom: 150px; /* Space before fixed buttons */
}

.friend-item {
    display: flex;
    align-items: center; /* Vertically align avatar and name */
    justify-content: space-between; /* Ensure the name and amount are spaced correctly */
    padding: 6px 15px;
    margin-bottom: 10px;
    background-color: #282828;
    border-radius: 12px;
    
    
}

.friend-info {
    display: flex; /* Ensure avatar and name are side-by-side */
    align-items: center; /* Vertically align avatar and name */
}

.friend-icon img, .friend-initial {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.friends-page-friend-name {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 15px; /* Space between avatar and name */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long names with dots */
    max-width: 120px; /* Set a limit on the name's width */
}


.friend-initial {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #555;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.friend-earnings {
    font-size: 18px;
    font-weight: bold;
    color: #FFD700;
}

/* No friends message */
.no-friends-message {
    text-align: center;
    color: grey;
}


.copy-popup {
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 90%;
    width: 400px;
}

.manual-copy-link {
    color: #FFD700;
    margin-top: 10px;
    font-size: 14px;
    word-wrap: break-word; /* In case the link is long, it breaks into the next line */
}

.copy-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copy-popup-title {
    color: #A9A9A9; /* Grey color for the title */
    font-size: 16px;
    margin: 0;
    margin-top: -15px;
}

.copy-popup-close-btn {
    background-color: transparent;
    border: none;
    color: #A9A9A9; /* Grey color for the close button */
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    line-height: 1;
    margin-top: -15px;

}

.copy-popup-close-btn:hover {
     background-color: rgba(184, 184, 184, 0.2); /* Subtle hover effect */
}




.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
}

.confetti-piece {
    width: 10px;
    height: 20px;
    position: absolute;
    background-color: hsl(0, 100%, 50%);
    animation: explode 1.1s ease-in-out forwards; /* Reduced animation duration for faster effect */
    z-index: 9999;
}

@keyframes explode {
    to {
        transform: translate(var(--randomX), var(--randomY)) rotate(1080deg); /* Full-screen movement */
        opacity: 0; /* Fade out */
    }
}


.tasks-page-container {
    padding: 0 20px 20px;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    text-align: center;
}

.tasks-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
.tasks-available-title{
    text-align: left;
    font-size: 18px;
    margin-top: 40px;
}

.tasks-page-description {
    font-size: 14px;
    color: #A9A9A9;
    margin-bottom: 20px;
}

.tasks-list {
    margin-bottom: 100px;
}

.task-item {
    display: flex;
    align-items: center; /* Vertically align avatar and name */
    justify-content: space-between; /* Ensure the name and amount are spaced correctly */
    padding: 6px 15px;
    margin-bottom: 10px;
    background-color: #282828;
    border-radius: 12px;
}

.task-info {
    text-align: left;
}

.task-name {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.task-status {
    font-size: 12px;
    color: #FFD700;
}

.task-claim-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 8px 20px;
    border-radius: 12px;
    cursor: pointer;
}

.task-claim-button:hover {
    background-color: #dec000;
    
}

.task-claim-button.disabled {
    background-color: #A9A9A9;
    border: none; /* Remove border if needed */
    opacity: 0.7;
}

.task-claim-button.completed {
    background-color: transparent; /* Remove button background for completed tasks */
    border: none; /* Remove border for completed tasks */
    cursor: default; /* Default cursor for completed tasks */
    
}

.task-claim-button img.checkmark-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    filter: invert(50%); /* Invert the color to white */

}


.no-tasks-message {
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    margin-top: 80px;
}

.claim-reward-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.claim-reward-popup {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
}

.popup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.popup-message {
    font-size: 18px;
    margin-bottom: 20px;
}

.claim-popup-close-button {
    background-color: #FFD700;
    color: black;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.claim-popup-close-button:hover {
    background-color: #dec000;
}

.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
}

.confetti-piece {
    width: 10px;
    height: 20px;
    position: absolute;
    background-color: hsl(0, 100%, 50%);
    animation: explode 1.1s ease-in-out forwards;
    z-index: 9999;
}

@keyframes explode {
    to {
        transform: translate(var(--randomX), var(--randomY)) rotate(1080deg);
        opacity: 0;
    }
}

.more-page-container {
    padding: 0 20px 20px;
    background-color: #121212;
    color: #ffffff;
    min-height: 100vh;
    text-align: center;
}

.more-page-title {
    font-size: 24px;
    margin-bottom: 45px;
}

.more-options-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 80px;
}

.more-option-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    margin: 10px 0;
    background-color: #282828;
    border-radius: 12px;
    cursor: pointer;
}

.more-option-text {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
}

.more-option-arrow {
    width: 20px;
    height: 20px;
}

.more-option-item:hover {
    background-color: #343434;
}


/* Unique CSS for User Agreement Popup */
.user-agreement-popup-overlay-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: flex-start; /* Open from the left */
    align-items: flex-end; 
    z-index: 1000;
}

.user-agreement-popup-custom {
    background-color: #282828;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    text-align: left; /* Text aligned to the left */
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: 80vh; /* Set height to 80% of the page */
    overflow-y: auto; /* Allow scrolling inside the popup */
    animation: slideUp 0.3s ease;
}

@keyframes slideInFromBottomCustom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.user-agreement-header-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.user-agreement-title-custom {
    color: #FFFFFF;
    margin: 0;
    font-size: 16px;
}

.user-agreement-close-btn-custom {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-agreement-close-btn-custom:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.user-agreement-separator-custom {
    border: 0;
    height: 1px;
    background-color: #555;
    margin: 10px 0;
}

.user-agreement-body-custom {
    color: white;
    font-size: 14px;
    text-align: left; /* Ensure text aligns left */
}

.user-agreement-body-custom p {
    margin-bottom: 15px;
    text-align: left; /* Ensure text aligns left */
}

.played-games-history-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.played-games-history-page-titleO {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 45px;
    color: #FFFFFF;
    text-align: center;
}

.played-games-history-group {
    margin-bottom: 30px;
}

.played-games-history-fullList {
    margin-bottom: 80px;
}

.played-games-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #333;
    cursor: pointer;
}

.played-games-history-date {
    font-size: 14px;
    color: #ffffff;
}

.played-games-history-info {
    display: flex;
    flex-direction: column;
}

.played-games-history-nameO {
    font-size: 16px;
    font-weight: normal;
}

.played-games-history-bet-result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.played-games-history-bet {
    font-size: 14px;
    color: #888;
}

.played-games-history-result {
    font-size: 14px;
    margin-left: 10px;
}

.played-games-history-result.win {
    color: #13C513; /* Green for win */
}

.played-games-history-result.lose {
    color: #E74C3C; /* Red for lose */
}

.played-games-history-arrow {
    width: 20px;
    height: 20px;
    filter: invert(100%); /* Make the arrow white */
}

.played-games-history-empty-message {
    text-align: center;
    color: grey;
}

.played-games-history-container-loading-message {
    background-color: #121212;
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    font-size: 18px;
}

.played-games-history-container-error-message {
    text-align: center;
    color: #E74C3C;
}


/* Container styling */
.played-games-history-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

/* Page title styling */
.played-games-history-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center;
}

/* Grouping items by date */
.played-games-history-group {
    margin-bottom: 30px;
}

/* Game item styling */
.played-games-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #333;
    cursor: pointer;
}

/* Game date styling */
.played-games-history-date {
    font-size: 14px;
    color: #ffffff;
}

/* Game information styling */
.played-games-history-info {
    display: flex;
    flex-direction: column;
}

/* Game name */
.played-games-history-name {
    font-size: 18px;
}

/* Bet and result information */
.played-games-history-bet-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.played-games-history-bet {
    font-size: 14px;
    color: #888;
}

/* Result styling (win/lose) */
.played-games-history-result.win {
    color: #13C513; /* Green for win */
}

.played-games-history-result.lose {
    color: #E74C3C; /* Red for lose */
}

/* Arrow icon */
.played-games-history-arrow {
    width: 20px;
    height: 20px;
    filter: invert(100%);
}

/* Popup overlay */
.played-games-history-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
}

/* Popup styling */
.played-games-history-popup {
    background-color: #282828;
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    text-align: center;
    position: relative;
    max-height: 90vh;
    overflow-y: auto; /* Allow scrolling if content exceeds the height */
    
}

/* Popup title */
.played-games-history-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.played-games-history-popup-title {
    color: white;
    margin: 0;
    font-size: 18px;
    text-align: left;
}

/* Close button */
.played-games-history-close-btn {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: 0px;
    top: 10px;
}

.played-games-history-close-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Popup separator */
.popup-separator {
    border: 0;
    height: 1px;
    background-color: #555;
    margin: 10px 0;
}

.opponent-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long names with dots */
    max-width: 100px; /* Set a limit on the name's width */

   
}

.game-pointsResult {
    font-size: 16px;
}

/* Row label and data */
.row-label {
    color: #9E9E9E;
    font-weight: bold;
}

.row-data {
    color: white;
}

/* Styling for win/lose status inside the popup */
.status-win {
    color: #13C513; /* Green for win */
}

.status-lose {
    color: #E74C3C; /* Red for lose */
}

/* Loading message styling */
.played-games-history-container-loading-message {
    background-color: #121212;
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    font-size: 18px;
}



.affiliate-program-container-unique {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.affiliate-program-title-unique {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.affiliate-program-description-unique {
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    color: #A9A9A9;
}

.affiliate-program-form-unique {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiliate-form-group-unique {
    margin-bottom: 20px;
    width: 100%;
}

.affiliate-input-field-unique {
    width: calc(100% - 25px); 
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-bottom: 10px;
    font-size: 16px;
}

.affiliate-textarea-field-unique {
    height: 80px;
    resize: none;
}

.affiliate-input-field-unique:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none;
}

.affiliate-submit-button-unique {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 320px;
}




.affiliate-submit-button-unique:hover {
    background-color: #dec000;
}

.affiliate-button-disabled-unique {
    background-color: #dec000;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Loading Spinner */
.affiliate-spinner-unique {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.affiliate-success-message-unique {
    color: #13C513;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}



input, textarea {
    outline: none;
}
input:focus, textarea:focus {
    scroll-margin-top: 300px; 
}

#miniAppContainer, #scrollableContent {
    touch-action: manipulation; /* Prevent unintended touch scrolling behavior */
}

textarea {
    resize: none; /* Prevent resizing, which can mess with the layout */
}

#scrollableContent {
    padding-bottom: 300px; /* Extra space to account for the keyboard */
}

.appPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden; /* Prevents page from shifting due to scrollbars */
}

.telegram-web-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

html, body, #root {
  height: 100%;
  margin: 0;
  background-color: #121212; /* Ensures a dark background until fully loaded */
}




.page-container {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.page-container.page-exit {
  opacity: 0;
  transform: scale(0.97); /* Slight zoom out effect */
}


/* Popup Styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.popup-inner p {
    margin: 0;
    font-size: 18px;
    color: #333;
}


/* Button Styles */
button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

/* GameComponent Styles */
.game-component {
    text-align: center;
    padding: 20px;
}




/* Withdraw Popup Styles */
.withdraw-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



.withdraw-popup-inner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}




/* Fixed buttons at the bottom */
.fixed-buttons-container {
  position: fixed;
  bottom: 95px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 100;
  
}

/* Invite a Friend Button */
.invite-button {
  background-color: #FFD700;
  color: black;
  border: none;
  padding: 10px 40px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-right: 10px;
}

.invite-button.animated-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { box-shadow: 0 0 5px #FFD700; }
  50% { box-shadow: 0 0 20px #FFD700; }
  100% { box-shadow: 0 0 5px #FFD700; }
}

.invite-button:hover {
  background-color: #dec000;
}

/* Copy Link Button */
.copy-link-button {
  background-color: #FFD700;
  border: none;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-link-button img {
  width: 24px;
  height: 24px;
}

.copy-link-button:hover {
  background-color: #dec000;
}


.copy-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
}


.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #121212; /* Matches the app background */
  padding: 10px 0 25px; /* Extra 15px padding at the bottom */
  border-top: 1px solid #383838; /* Line separating footer from content */
  box-sizing: border-box; /* Ensures padding doesn’t affect positioning */
}

  .hidden-footer {
    display: none; /* Hides the footer completely */
    /* Alternatively, you can use visibility: hidden; */
}
  
  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: white;
  }
  
 

  .footer-item.active {
    color: #ffffff; /* Highlight color for the active section */
  }
  
  .footer-icon {
    width: 24px;  /* Adjust icon size */
    height: 24px;
    margin-bottom: 5px; /* Space between icon and text */
  }

  
  .footer-item.active .footer-icon {
    filter: brightness(2.5); /* Brightens the active icon */
  }
  
  .footer-item span {
    font-size: 12px;
    color: #a8a8a8; /* Grey color for section names */
  }
  
  .footer-item.active span {
    color: #ffffff; /* Highlight color for active section name */
  }
  

  /* Prevent long-press behavior on images and other elements globally */
img, .no-long-press {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}

/* LoadingScreen.css */

.loading-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.986); /* Dimmed overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it covers all other elements */
    color: #fff;
    font-size: 1.5em;
}

.loading-screen-content {
    text-align: center;
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-top: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Admin Login Page Styles */
.admin-login-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212; /* Dark background */
    color: #FFFFFF; /* Light text for contrast */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-login-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #FFD700; /* Gold for admin-specific highlight */
}

.admin-login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.admin-form-group {
    margin-bottom: 20px;
}

.admin-label {
    font-size: 16px;
    margin-bottom: 8px;
    color: #FFFFFF; /* Ensure text is visible */
}

.admin-input-field {
    width: 100%;
    font-size: 16px;
    padding: 12px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-bottom: 10px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.admin-input-field:focus {
    border-color: #FFD700; /* Gold highlight on focus */
    box-shadow: 0 0 5px #FFD700;
    outline: none;
}

/* Button styles */
.admin-login-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 12px 60px;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.admin-login-button:hover {
    background-color: #E6C300;
}

.admin-login-disabled {
    background-color: #555555;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Error message styling */
.admin-error-message {
    color: #FF4D4D;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

/* Centering the login container */
.admin-login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Adjust loading spinner */
.admin-spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #FFD700; /* Gold spinner */
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Responsive design */
@media (max-width: 768px) {
    .admin-login-container {
        padding: 10px;
    }

    .admin-login-title {
        font-size: 24px;
    }

    .admin-input-field {
        font-size: 14px;
    }

    .admin-login-button {
        font-size: 14px;
        padding: 10px 40px;
    }
}

/* Admin Dashboard Styles */
.admin-dashboard-container {
    padding: 20px;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

.dashboard-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #FFD700; /* Gold highlight */
}

.date-range-picker {
    text-align: center;
    margin-bottom: 40px;
}

.date-picker-group {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.date-picker {
    background-color: #1E1E1E;
    color: white;
    border: 1px solid #FFD700;
    padding: 8px;
    border-radius: 5px;
    font-size: 16px;
    max-width: 100%;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 85px;
}

.view-all-button {
    position: absolute; /* Allow precise positioning */
    bottom: 10px; /* Adjust bottom positioning */
    right: 10px; /* Place at the bottom-right corner */
    padding: 8px 16px;
    background-color: #FFD700; /* Gold color for the button */
    color: #121212; /* Dark text for contrast */
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.view-all-button:hover {
    background-color: #e5c100; /* Slightly darker gold on hover */
    transform: scale(1.05); /* Slight enlargement on hover */
}

.view-all-button:active {
    transform: scale(0.95); /* Slight shrink on click */
}

.dashboard-card {
    position: relative; /* Ensures the button can be positioned within the card */
    padding: 20px;
    background-color: #1E1E1E;
    border: 1px solid #555555;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.dashboard-card h2 {
    color: #FFD700; /* Gold for card titles */
    margin-bottom: 15px;
}

.dashboard-card p,
.dashboard-card ul {
    color: #FFFFFF;
    font-size: 16px;
    margin: 0;
}

.dashboard-card ul {
    list-style-type: none;
    padding: 0;
}

.dashboard-card ul li {
    background-color: #252525;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
}

.highlightQ {
    font-weight: bold;
    color: red;
}




.completed-games-container {
    padding: 20px;
    background-color: #121212;
    color: #FFFFFF;
}

.completed-games-container h1 {
    text-align: center;
    color: #FFD700;
    margin-bottom: 20px;
}

/* Responsive Table Container */
.table-container {
    overflow-x: auto;
    margin-bottom: 20px;
}

.games-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    min-width: 600px; /* Ensure table doesn't shrink too small */
}

.games-table th,
.games-table td {
    border: 1px solid #555555;
    padding: 10px;
    text-align: center;
    white-space: nowrap; /* Prevent text wrapping for better readability */
}

.games-table th {
    background-color: #1E1E1E;
    color: #FFD700;
    font-size: 14px;
}

.games-table tr:nth-child(even) {
    background-color: #252525;
}

.pagination {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 20px 0;
}

.pagination button {
    padding: 10px 20px;
    background-color: #FFD700;
    border: none;
    color: #121212;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.pagination button:disabled {
    background-color: #555555;
    cursor: not-allowed;
}

/* Date Range Picker Styling */
.date-range-picker {
    display: flex;
    flex-wrap: wrap; /* Wrap items on small screens */
    justify-content: center;
    grid-gap: 20px;
    gap: 20px; /* Space between items */
    margin-bottom: 20px;
}

.date-picker-group {
    display: flex;
    flex-direction: column; /* Stack items vertically for smaller screens */
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.date-picker {
    background-color: #1E1E1E;
    color: white;
    border: 1px solid #FFD700;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%; /* Take full width in column layout */
    max-width: 200px;
}

.fetch-games-button {
    padding: 8px 20px;
    background-color: #FFD700;
    border: none;
    color: #121212;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.fetch-games-button:hover {
    background-color: #FFC107;
}

/* Search Bar Styling */
.search-bar {
    text-align: center;
    margin-bottom: 20px;
    
}

.search-input {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    border: 1px solid #FFD700;
    background-color: #1E1E1E;
    color: white;
    font-size: 14px;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .date-range-picker {
        flex-direction: column; /* Stack filters vertically */
        align-items: center;
    }

    .table-container {
        overflow-x: auto;
    }

    .pagination button {
        font-size: 12px;
        padding: 8px 16px;
        margin-bottom: 75px;
    }

    .search-bar input {
        max-width: 100%; /* Take up full width */
        font-size: 16px; /* Slightly smaller font for compact view */
    }

    .search-bar {
        flex-direction: column; /* Stack elements vertically */
        grid-gap: 10px;
        gap: 10px; /* Add space between elements */
        max-width: 80vw;
    }


}

.downloadLog-button {
    padding: 5px 10px;
    background-color: #ccd7ff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #121212;
    font-weight: bold;
}

.downloadLog-button:hover {
    background-color: #8fa6fa;
}
.users-page-container {
    padding: 20px;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    overflow-x: auto;
}

.users-page-container h1 {
    text-align: center;
    color: #FFD700;
}


.users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.users-table th,
.users-table td {
    border: 1px solid #555555;
    padding: 10px;
    text-align: center;
}

.users-table th {
    background-color: #1E1E1E;
    color: #FFD700;
}

.users-table tr:nth-child(even) {
    background-color: #252525;
}

.transactions-button {
    padding: 5px 10px;
    background-color: #ccd7ff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #121212;
    font-weight: bold;
}

.transactions-button:hover {
    background-color: #8fa6fa;
}



/* Min Balance Filter Styling */
.min-balance-filter {
    text-align: center;
    margin-bottom: 20px;
}

.min-balance-input {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    border: 1px solid #FFD700;
    background-color: #1E1E1E;
    color: white;
    font-size: 14px;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .min-balance-filter input {
        max-width: 100%; /* Take up full width */
        font-size: 16px; /* Slightly smaller font for compact view */
    }

    .min-balance-filter {
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px; /* Space between filter and search */
        max-width: 80vw;
    }
}

.export-buttonUsers {
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #FFD700;
    color: #1E1E1E;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.export-buttonUsers:hover {
    background-color: #E6BE00;
}




.rps-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; /* Horizontal centering */
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    max-width: 100vw; /* Prevent accidental resizing */
    background: linear-gradient(180deg, #121212, #1e3c72);
    position: relative; /* For absolute child positioning */
    overflow: hidden;
}



.rps-round-number {
    position: fixed;
    top: 50%;
    left: 50%;
    transform-origin: center; /* Ensure scaling is consistent */
    font-size: 48px; /* Start size */
    font-weight: bold;
    color: #ffffff;
    z-index: 100;
    animation: moveToTop 1s ease-in-out forwards; /* Keyframes for movement and scaling */
}



.rps-animate {
    animation: scaleAndMove 2s ease-in-out forwards;
}

/* Scale and center animation */
@keyframes scaleAndMove {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0.5;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}


/* Move to top with dynamic resizing */
@keyframes moveToTop {
    0% {
        top: 50%;
        left: 50%; /* Centered horizontally */
        transform: translate(-50%, -50%) scale(1); /* Centered with initial size */
        font-size: 48px; /* Initial size */
    }
    100% {
        top: calc(env(safe-area-inset-top) + 5%); /* Top position */
        left: 50%; /* Horizontally centered */
        transform: translate(-50%, -50%) scale(0.5); /* Reduced size dynamically */
        
    }
}











/* Opponent Wrapper */
.rps-opponent-wrapper {
    margin-top: 70px; /* Adds space below the round number */
    width: 100%;
}

.rps-opponent-area {
    display: flex;
    align-items: center;
    padding: 15px 25px; 
}

/* Opponent Avatar */
.rps-opponent-avatar {
    width: 60px; 
    height: 60px; 
    background-color: #D9D9D9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px; /* For initials */
    font-weight: bold;
    color: #464646; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure the image doesn't overflow */
}

.rps-opponent-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; /* Ensure the image scales properly */
}

/* Opponent Info */
.rps-opponent-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.rps-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px; 
}

.rps-opponent-name {
    font-size: 15px; 
    font-weight: bold;
    color: #ffffff; 
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rps-country-flag {
    width: 18px; 
    height: 18px; 
    margin-left: 5px;
    vertical-align: middle;
}

/* Opponent Score */
.rps-opponent-score {
    font-size: 14px; 
    color: #ffde59; 
}

/* Round Result Placeholders */
.rps-opponentRound-result {
    display: flex;
    grid-gap: 15px;
    gap: 15px; 
}

.rps-placeholder {
    width: 35px; 
    height: 35px; 
    background-color: #D9D9D9; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.rps-timer-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.rps-base {
    fill: transparent;
    stroke: #D9D9D9; /* Made lighter for better visibility */
    stroke-width: 3px; /* Increased thickness */
}

.rps-timer {
    fill: none;
    stroke-width: 3px; /* Increased thickness */
    stroke-dasharray: 160; /* Adjusted for larger size */
    stroke-dashoffset: 0;
    animation: rps-timer-animation 10s linear infinite;
}

@keyframes rps-timer-animation {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 160;
    }
}

/* Timer Active */
.rps-timer-active .rps-placeholder {
    border: 3px solid #ffffff; /* Enhanced border for active placeholder */
}














/* Player Wrapper */
.rps-player-wrapper {
    position: absolute; /* Position it relative to the parent container */
    bottom: 50px; /* 50px from the bottom of the screen */
    width: 100%; /* Full width */
}

/* Player Area */
.rps-player-area {
    display: flex;
    align-items: center;
    padding: 15px 25px; /* Padding for consistent spacing */
}


/* Player Avatar */
.rps-player-avatar {
    width: 60px;
    height: 60px; 
    background-color: #D9D9D9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 24px; /* For initials */
    font-weight: bold;
    color: #555; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure the image doesn't overflow */
}

.rps-player-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; /* Ensure the image scales properly */
}

/* Player Info */
.rps-player-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.rps-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px; 
}

.rps-player-name {
    font-size: 15px; 
    font-weight: bold;
    color: #ffffff; 
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rps-country-flag {
    width: 18px; 
    height: 18px; 
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.rps-player-score {
    font-size: 14px; 
    color: #ffde59; 
}


.rps-playerRound-result {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

.rps-placeholder {
    width: 35px; 
    height: 35px; 
    background-color: #D9D9D9; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.rps-timer-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.rps-base {
    fill: transparent;
    stroke: #D9D9D9; 
    stroke-width: 3px; 
}

.rps-timer {
    fill: none;
    stroke-width: 3px; 
    stroke-dasharray: 160; 
    stroke-dashoffset: 0;
    animation: rps-timer-animation 10s linear infinite;
}












/* Wrapper for Play Buttons and Waiting Message */
.rps-play-buttons-wrapper {
    position: relative;
    margin-bottom: 100px; /* Fixed distance from the player section */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px; /* Space between the waiting message and buttons */
}





/* Play Buttons Container */
.rps-play-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px; /* Even spacing between buttons */
}

/* Individual Play Button */
.rps-play-button {
    background: none;
    border: none;
    width: 90px; 
    height: 90px;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform-origin: center; /* Ensures rotation around the center */
}


.rps-play-button:nth-child(1) {
    width: 75px; 
    height: 75px;
    transform: rotate(-45deg); /* Rock rotated slightly left */
}

.rps-play-button:nth-child(2) {
    transform: rotate(-15deg); /* Paper remains upright */
}

.rps-play-button:nth-child(3) {
    transform: rotate(-45deg); /* Scissors rotated slightly right */
}


.rps-play-button:hover {
    transform: scale(1.1); /* Enlarges on hover */
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.6);
}

.rps-play-button:active {
    transform: scale(0.95); /* Slight shrink on click */
    box-shadow: 0px 2px 6px rgba(255, 255, 255, 0.4);
}

/* Play Button Icon */
.rps-play-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Initial Centered State - No Animation */
.rps-play-choice {
    position: fixed;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 10;
}

/* Smooth Animation Only for Moving to Play Area */
.rps-animate-to-play-area {
    transition: transform 1s ease-in-out; /* Apply transition only here */
    transform: translate(-50%, -150px); /* Move icon to play area */
}

/* Chosen Icon Wrapper */
.rps-chosen-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Optional: to match the round look */
}

/* Specific Sizes for Different Choices */
.rps-chosen-icon-wrapper.rock {
    width: 75px; /* Specific size for rock */
    height: 75px;
}

.rps-chosen-icon-wrapper.paper,
.rps-chosen-icon-wrapper.scissors {
    width: 90px; /* Larger size for paper and scissors */
    height: 90px;
}

/* Chosen Icon Image */
.rps-chosen-icon {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Prevents distortion */
    animation: bounce 0.5s ease-in-out infinite alternate;
}

/* Remove bounce animation when move is finalized */
.rps-chosen-icon.no-bounce {
    animation: none; /* Stops the bounce animation */
}

/* Waiting Message Styling */
.rps-waiting-message {
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -300%);
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
}



/* Countdown Timer */
.rps-countdown {
    font-size: 14px;
    color: #ffde59; /* Light yellow for visibility */
    font-weight: bold;
    text-align: center;
    animation: RPSpulse 1s ease-in-out infinite;
}

/* Animations */
@keyframes bounce {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
}

@keyframes RPSpulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

.rps-trophy-icon {
    font-size: 22px; /* Adjust size as needed */
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}












/* Play Area */
.rps-play-area {
    position: relative;
    width: 100%;
    height: 150px; /* Adjust height for design */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px; /* Space between play area and other sections */
    overflow: hidden;
}

/* Opponent's Move Wrapper (initial position and animation) */
.rps-opponent-move-wrapper {
    position: fixed; /* Allow global positioning */
    top: 10%; /* Start near the top-center of the screen */
    left: 50%; /* Horizontally centered */
    transform: translateX(-50%) translateY(-150px); /* Initial position */
    z-index: 10; /* Ensure it appears above other elements */
    animation: moveOpponentToPlayArea 1s ease-in-out forwards;
}

/* Opponent's Icon Styling */
.rps-opponent-move-icon {
    object-fit: contain; /* Prevent distortion */
    rotate: 180deg; /* Initial rotation */
}

/* Specific sizes for each move */
.rps-opponent-move-icon.rock {
    width: 75px;
    height: 75px;
}

.rps-opponent-move-icon.paper,
.rps-opponent-move-icon.scissors {
    width: 90px;
    height: 90px;
}

/* Opponent Move Animation Keyframes */
@keyframes moveOpponentToPlayArea {
    0% {
        transform: translateX(-50%) translateY(-150px); /* Start flipped */
    }
    100% {
        transform: translateX(-50%) translateY(150px); /* End upright */
    }
}










/* Winner Animation (delayed after move-to-play-area finishes) */
.rps-winner-icon-delay {
    animation: scalePulse 1.5s ease-in-out 1s infinite; /* Pulse effect starts after a slight delay */
}

/* Tie Animation (delayed) */
.rps-tie-icon-delay {
    animation: scalePulse 1.5s ease-in-out 1s infinite; /* Same pulse effect as winner */
}

/* Pulse Animation for Scaling */
@keyframes scalePulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Slight scale up */
    }
    100% {
        transform: scale(1); /* Scale back to normal */
    }
}


.rps-no-animation {
    animation: none !important; /* Explicitly prevent any animation */
    transform: none; /* Reset any scaling or transformations */
    opacity: 0.6;
}














/* Unique class for the RPS end game popup */
.rps-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: rps-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes rps-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.rps-end-game-header {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.rps-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.rps-end-game-header h3.rps-win {
    color: #32cd32; /* Green for win */
}

.rps-end-game-header h3.rps-lose {
    color: #e74c3c; /* Red for loss */
}

.rps-end-game-results-table {
    margin-top: 15px;
    text-align: center;
}

.rps-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.rps-end-game-score {
    font-size: 1.5rem;
    font-weight: bold;
}

.rps-end-game-colon {
    padding: 0 10px;
    color: #fff;
}

.rps-end-game-winner-score {
    color: #ffffff; 
}

.rps-end-game-loser-score {
    color: #a9a9a9; 
}

/* General row styling */
.rps-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column */
.rps-results-left {
    display: flex;
    align-items: center;
    width: 150px;
}

/* Icon styling */
.rps-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.rps-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.rps-current-score {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
}

.rps-score-result {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

.rps-score-positive {
    color: #ffffff;
}

.rps-score-negative {
    color: #ffffff;
}

/* Bet Amount */
.rps-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Winning Amount */
.rps-winning-row .rps-winning-amount {
    font-size: 1.4rem;
    font-weight: bold;
    color: #32cd32; /* Green */
}

/* Button Styling */
.rps-close-btn {
    margin-top: 15px;
    background: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}




.carrom-container {
    background: linear-gradient(180deg, #121212, #1e3c72);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    max-width: 100vw; /* Prevent overflow horizontally */
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow: hidden; /* Disable scrolling */
    touch-action: none; /* Prevent gestures like scroll or zoom */
}




/* Opponent Wrapper */
.carrom-opponent-wrapper {
    margin-top: 20px;
    width: 100%; /* Full width */
    display: flex;
    justify-content: center; /* Center the opponent area */
    padding: 0 15px; /* Add padding from the screen borders */
    box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}

/* Opponent Area */
.carrom-opponent-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align content to the left */
    padding: 12px 14px; /* Inner padding for content */
    width: 100%; /* Take up the full wrapper width */
    max-width: 500px; /* Limit the max width */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%);
    border: 2px solid #9A6001; /* Yellow border */
    box-shadow: inset 0 0 12px 5px rgb(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box; /* Include padding and border in dimensions */
}



/* Opponent Avatar */
.carrom-opponent-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px; /* Initials font size */
    font-weight: bold;
    color: #464646; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure image doesn't overflow */
}

/* Opponent Info */
.carrom-opponent-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px; /* Add a bit of spacing inside */
}

/* Opponent Name and Flag */
.carrom-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.carrom-opponent-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carrom-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Opponent Score */
.carrom-opponent-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Opponent Points */
.carrom-opponent-betAmmount {
    font-size: 14px;
    color: #4caf50; /* Green for points */
}


.carrom-opponent-coins {
    position: absolute; /* Position it within the parent container */
    right: 20px; /* Add spacing from the right side */
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
    width: 125px; /* Width of the rectangle */
    height: 45px; /* Height of the rectangle */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%); /* Same gradient */
    box-shadow: inset 0 0 8px 2px rgba(0, 0, 0, 0.6); /* Aggressive outer shadow */
    border-radius: 12px; /* Smaller rounded corners */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content inside */
    box-sizing: border-box;
}

/* Coin Icon for Opponent */
.carrom-opponent-coin-icon {
    width: 22px; /* Coin icon size */
    height: 22px;
    background-size: contain;
    margin-right: 7px; /* Spacing between icon and number */
}

/* Coin Count for Opponent */
.carrom-opponent-coin-count {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* White text */
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7); /* Subtle text shadow */
}












/* Player Wrapper */
.carrom-player-wrapper {
    position: absolute; /* Position it relative to the parent container */
    bottom: 50px; /* 50px from the bottom of the screen */
    width: 100%; /* Full width */
    display: flex;
    justify-content: center; /* Center the player area horizontally */
    padding: 0 15px; /* Add padding from screen borders */
    box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}

/* Player Area */
.carrom-player-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align content to the left */
    padding: 12px 14px; /* Inner padding for content */
    width: 100%; /* Take up the full wrapper width */
    max-width: 500px; /* Limit the max width */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%);
    border: 2px solid #9A6001; 
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6); /* Inner shadow */
    border-radius: 25px;
    box-sizing: border-box; /* Include padding and border in dimensions */
}

/* Player Avatar */
.carrom-player-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px; /* Initials font size */
    font-weight: bold;
    color: #464646; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure image doesn't overflow */
}

.carrom-avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; /* Ensure proper scaling */
}

/* Player Info */
.carrom-player-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Player Name and Flag */
.carrom-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.carrom-player-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carrom-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.carrom-player-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Player Points */
.carrom-player-betAmmount {
    font-size: 14px;
    color: #4caf50; /* Green for points */
}


/* Small Rectangle for Coins */
.carrom-player-coins {
    position: absolute; /* Position it within the parent container */
    right: 20px; /* Add spacing from the right side */
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
    width: 125px; /* Width of the rectangle */
    height: 45px; /* Height of the rectangle */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%); /* Same gradient */
    box-shadow: inset 0 0 8px 2px rgba(0, 0, 0, 0.6); /* Aggressive outer shadow */
    border-radius: 12px; /* Smaller rounded corners */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content inside */
    box-sizing: border-box;
}

/* Coin Icon */
.carrom-coin-icon {
    
    width: 22px; /* Coin icon size */
    height: 22px;
    background-size: contain;
    margin-right: 7px; /* Spacing between icon and number */
}

/* Coin Count */
.carrom-coin-count {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* White text */
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7); /* Subtle text shadow */
}
















/* Carrom Board Container */
.carrom-board-container {
    position: absolute; /* Allows independent positioning */
    top: 42%; /* Moves the board up relative to the wrapper */
    left: 50%; /* Centers the board horizontally */
    transform: translate(-50%, -50%); /* Ensures proper alignment */
    width: 320px; /* Includes the border size */
    height: 320px; /* Includes the border size */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

/* Board Border */
.carrom-board-border {
    position: relative;
    width: 318px;
    height: 318px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('/carromAssets/board-border.png') no-repeat center center; /* Preserve the original image */
    box-shadow: 0 0 14px 10px rgba(0,0,0,0.4);
    border-radius: 13px;
}



/* Main Carrom Board */
.carrom-board {
    position: relative; /* Ensures coins are positioned relative to the board */
    width: 300px;
    height: 300px;
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5) 0%, /* Bright center */
        rgba(255, 255, 255, 0.2) 50%, /* Gradual fade */
        rgba(0, 0, 0, 0) 100% /* Transparent edges */
    ),
    url('/carromAssets/board-background.png') no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    box-shadow: inset 0 0 14px 16px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}

/* Board Lines */
.board-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/carromAssets/board-lines.png') no-repeat center center;
    background-size: cover;
    pointer-events: none;
}

/* Coins */
.carrom-coin {
    position: absolute;
    width: 20px; /* Smaller size for coins */
    height: 20px;
    pointer-events: none; /* Prevent interaction */
    transform: translate(-50%, -50%); /* Center coins at their positions */
    will-change: transform, top, left;
    z-index: 10;
}


.carrom-coin.highlight-queen {
    border: 1px solid #ffde59; /* Yellow border */
    box-shadow: 0px 0px 5px 0px #ffde59; /* Focused glow effect */
    border-radius: 50%; /* Make border circular */
    animation: pulsateQueen 1.5s infinite ease-in-out; /* Apply pulsation */
}

/* Define the pulsation animation */
@keyframes pulsateQueen {
    0% {
        box-shadow: 0px 0px 5px 0px #ffde59; /* Minimal glow */
        
    }
    50% {
        box-shadow: 0px 0px 8px 0px #ffde59; /* Slightly brighter glow */
        
    }
    100% {
        box-shadow: 0px 0px 5px 0px #ffde59; /* Back to minimal glow */
        
    }
}



.carrom-coin.pocketing {
    will-change: transform, top, left;
    transition: transform 0.6s ease, top 0.6s ease, left 0.6s ease;
    z-index: 100;
}

.carrom-striker {
    position: absolute;
    width: 30px; /* Match the size of the PNG */
    height: 30px;
    transform: translate(-50%, -50%); /* Center it properly */
    z-index: 2; /* Keep it above other elements */
    pointer-events: all; /* Ensure it receives clicks/touches */
    background-color: rgba(0, 0, 0, 0); /* No background, just the image */
    z-index: 110;
}

/* Striker Controller */
.striker-controller {
    position: absolute;
    top: calc(42% + 190px);
    left: 50%;
    transform: translate(-50%, 0);
    width: 200px;
    height: 30px;
    border-radius: 15px;
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%);
    border: 2px solid #9A6001; 
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6),
                      0px 8px 15px rgba(0, 0, 0, 0.4);  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1;
}

.striker-controller.active {
    pointer-events: all; /* Allow interaction when active */
    opacity: 1;
}

.striker-controller.inactive {
    pointer-events: none;
    opacity: 0.5;
}

.striker-handle {
    position: absolute;
    width: 30px;
    height: 30px;
    background: url('/carromAssets/striker.png') no-repeat center center;
    background-size: contain;
    cursor: grab;
}

.striker-handle:active {
    cursor: grabbing;
    transform: scale(0.95); /* Slight scale effect for interaction */
}



















/* Rotating Circle Animation */
.carrom-striker-animation {
    position: absolute;
    width: 36px; /* Initial radius for the animation */
    height: 36px;
    border: 1px solid gold;
    border-radius: 50%;
    background-color: rgba(236, 134, 17, 0.676); /* Gold fill with reduced opacity */
    box-shadow: 0 0 8px rgba(255, 255, 0, 0.8);
    animation: carrom-rotate-circle 3s linear infinite; /* Infinite rotation */
    pointer-events: none; /* Prevent interaction */
    z-index: 1; /* Place above the striker */
    transition: width 0.3s, height 0.3s; /* Smoothly adjust size on drag */
}

/* Smaller Circle (when dragging) */
.carrom-striker-animation.carrom-small {
    animation: carrom-rotate-circle-small 3s linear infinite; /* Shrinking + rotation */
}

.carrom-striker-animation.carrom-overlap {
    background-color: rgba(255, 0, 0, 0.6); /* Red with some transparency */
    border: 1px solid red;
    box-shadow: 0 0 12px red;
}


/* Arrow Styles */
.carrom-striker-animation .carrom-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2px; /* Size of the arrow */
    border-color: transparent transparent gold transparent; /* Arrow pointing inward */
}

/* Arrow Placement */
.carrom-striker-animation .carrom-arrow-top {
    top: 0px; /* Move arrow slightly above the border */
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
}

.carrom-striker-animation .carrom-arrow-bottom {
    bottom: 0px; /* Move arrow slightly below the border */
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
}

.carrom-striker-animation .carrom-arrow-left {
    left: 0px; /* Move arrow slightly left of the border */
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}

.carrom-striker-animation .carrom-arrow-right {
    right: 0px; /* Move arrow slightly right of the border */
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
}

/* Infinite Rotation Keyframes */
@keyframes carrom-rotate-circle {
    0% {
        transform: translate(-50%, -50%) rotate(0deg); /* Start position */
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg); /* Full rotation */
    }
}


/* Infinite Rotation Keyframes with Smaller Scale */
@keyframes carrom-rotate-circle-small {
    0% {
        transform: translate(-50%, -50%) rotate(0deg) scale(0.93); /* Smaller size */
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg) scale(0.93); /* Smaller size */
    }
}




















.carrom-hit-circle {
    position: absolute;
    background-color: rgba(158, 157, 154, 0.164); /* Light semi-transparent white */
    border: 1px dashed rgba(227, 227, 227, 0.395); /* Dashed border with semi-transparent white */
    border-radius: 50%; /* Keep it circular */
    pointer-events: none; /* Prevent interaction */
    z-index: 5; /* Above the striker */
    transition: width 0.1s ease, height 0.1s ease; /* Smooth scaling */
}


.carrom-striker-dot {
    position: absolute;
    border-radius: 50%; /* Make it a perfect circle */
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,       /* Bright highlight at the top (fully opaque) */
        rgba(255, 255, 255, 0.9) 40%,   /* Reduced transparency */
        rgba(220, 220, 220, 0.95) 70%,  /* Almost opaque gray for depth */
        rgba(180, 180, 180, 1) 100%     /* Fully opaque shadow at the bottom */
    );
    box-shadow: 0 3px 6px rgba(138, 137, 137, 0.4), /* Stronger shadow below the ball */
                inset 0 -1px 2px rgba(121, 121, 121, 0.3); /* Slightly stronger inner shadow */
    transform: translate(-50%, -50%); /* Center it precisely */
    z-index: 105; /* Ensure balls are visible */
    transition: transform 0.1s ease, width 0.1s ease, height 0.1s ease; /* Smooth scaling animation */
}


.carrom-white-dot {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Wrapper for the entire arrow */
.carrom-hit-arrow-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    pointer-events: none; /* Prevent interaction */
    z-index: 106; /* Ensure it stays above other elements */
}

/* Arrow Line (with arrowhead drawn as ::before) */
.carrom-hit-arrow-line {
    height: 5.5px; /* Thickness of the arrow line */
    border-radius: 8px; /* Smooth rounded edges */
    transition: background 0.2s linear; /* Smooth color transition */
    position: relative; /* Needed for the ::before arrowhead */
    z-index: 106;
}


.carrom-hit-arrow-line::after {
    content: ""; /* Pseudo-element for the arrowhead */
    position: absolute;
    top: 50%; /* Center the arrowhead vertically */
    right: -5px; /* Position at the end of the arrow line */
    transform: translateY(-50%) rotate(-90deg); /* Align with the line direction */
    width: 0;
    height: 0;
    border-left: 8.5px solid transparent; /* Create triangular arrowhead */
    border-right: 8.5px solid transparent;
    border-top: 10.5px solid var(--arrow-color, gold); /* Use CSS variable or fallback to gold */
    z-index: 107;
}






.carrom-striker.falling {
    animation: fall-into-hole 1s ease-out forwards;
    transform-origin: center;
}

@keyframes fall-into-hole {
    0% {
        transform: translate(-50%, -50%) scale(1); /* Include translate to maintain centering */
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(0.4); /* Combine translate with scale */
        opacity: 0.4;
    }
}




.carrom-CoinFallingToHoll {
    position: absolute;
    transition: transform 0.5s ease, left 0.5s ease, top 0.5s ease;
    animation: coinFall-into-hole 1s ease-out forwards;
    

}

@keyframes coinFall-into-hole {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.8); /* Slightly smaller */
        opacity: 1; /* Reduce opacity */
    }
}


.carrom-CoinMovingFromHoll {
    animation: coinMoveing-from-hole 0.5s ease-out forwards;
    transform-origin: center;
}

@keyframes coinMoveing-from-hole {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }
    100% {
        transform: scale(1.2); /* Slightly smaller */
        opacity: 0.8; /* Reduce opacity */
    }
}


















.striker-foul-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85); /* Slightly darker background */
    color: #fff;
    padding: 20px 40px;
    border-radius: 12px; /* Softer corners */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 320px; /* Wider for better visual proportions */
    max-height: 80%;
    animation: striker-popup-animation 0.4s ease-out; /* Smooth scaling */
}

@keyframes striker-popup-animation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.striker-popup-header {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: #ffde59; /* Yellow for emphasis */
    margin-bottom: 10px;
}

.striker-popup-message {
    text-align: center;
    font-size: 1rem;
    color: #fff;
}

.striker-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


















/* Unique class for the RPS end game popup */
.carrom-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: carrom-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes carrom-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.carrom-end-game-header {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.carrom-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.carrom-end-game-header h3.carrom-win {
    color: #32cd32; /* Green for win */
}

.carrom-end-game-header h3.carrom-lose {
    color: #e74c3c; /* Red for loss */
}

.carrom-end-game-results-table {
    margin-top: 15px;
    text-align: center;
}

.carrom-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.carrom-end-game-score {
    font-size: 1.5rem;
    font-weight: bold;
}

.carrom-end-game-colon {
    padding: 0 10px;
    color: #fff;
}

.carrom-end-game-winner-score {
    color: #ffffff; 
}

.carrom-end-game-loser-score {
    color: #a9a9a9; 
}

/* General row styling */
.carrom-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column */
.carrom-results-left {
    display: flex;
    align-items: center;
    width: 150px;
}

/* Icon styling */
.carrom-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.carrom-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carrom-current-score {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
}

.carrom-score-result {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

.carrom-score-positive {
    color: #ffffff;
}

.carrom-score-negative {
    color: #ffffff;
}

/* Bet Amount */
.carrom-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Winning Amount */
.carrom-winning-row .carrom-winning-amount {
    font-size: 1.4rem;
    font-weight: bold;
    color: #32cd32; /* Green */
}

/* Button Styling */
.carrom-close-btn {
    margin-top: 15px;
    background: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}















/* Position and size for the SVG container */
.carrom-player-avatar svg,
.carrom-opponent-avatar svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg) scale(1.07); /* Ensure the timer starts at the top */
    z-index: 1;
}

/* Styling for the base circle */
.carrom-player-avatar circle.Carrom-base,
.carrom-opponent-avatar circle.Carrom-base {
    fill: none;
    stroke: #e9e9e9; /* Gray color for the base circle */
    stroke-width: 3; /* Adjust thickness if needed */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 0; /* Always fully visible */
}

/* Styling for the timer circle */
.carrom-player-avatar circle.Carrom-timerP,
.carrom-opponent-avatar circle.Carrom-timerO {
    fill: none;
    stroke-width: 4; /* Thickness of the animated stroke */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 188; /* Initially hidden (full offset) */
    
}

/* Transform and scaling when highlighted */
.carrom-player-area.highlighted .carrom-player-avatar,
.carrom-opponent-wrapper.highlighted .carrom-opponent-avatar {
    transform: scale(1.22); /* Scale up when active */
    transition: transform 0.35s ease-in-out; /* Smooth scaling */
}

.carrom-player-area .carrom-player-avatar,
.carrom-opponent-wrapper .carrom-opponent-avatar {
    transform: scale(1); /* Default size */
    transition: transform 0.25s ease-in-out; /* Smooth scaling */
}











.carrom-game-buttonsIcons {
    position: absolute;
    bottom: 105%;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .carrom-textIcon-buttonIcon,
  .carrom-settingsIcon-buttonIcon {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 8px; /* Space between buttons */
    padding: 1px;
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
    -webkit-tap-highlight-color: transparent; /* Remove iOS tap highlight */
  }
  
.carrom-textIcon-buttonIcon:focus,
.carrom-textIcon-buttonIcon:hover,
.carrom-textIcon-buttonIcon:active,
.carrom-settingsIcon-buttonIcon:focus,
.carrom-settingsIcon-buttonIcon:hover,
.carrom-settingsIcon-buttonIcon:active,
.carrom-soundToggle-buttonExt:focus,
.carrom-soundToggle-buttonExt:hover,
.carrom-soundToggle-buttonExt:active,
.carrom-leaveGame-buttonExt:focus,
.carrom-leaveGame-buttonExt:hover,
.carrom-leaveGame-buttonExt:active {
  outline: none;
  box-shadow: none;
  background: none; /* Remove any background styles */
}
  
  .carrom-textIcon-buttonIcon img,
  .carrom-settingsIcon-buttonIcon img {
    width: 25px;
    height: 25px;
  }


 /* Container for expanded buttons */
.carrom-settings-expanded {
    position: absolute;
    bottom: 0%;
    right: 40px; /* Adjust for desired spacing */
    z-index: 9;
    display: flex;
    opacity: 0;
    transform: translateX(10px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none; /* Prevent interaction when hidden */
}


.carrom-leaveGame-buttonExt,
.carrom-soundToggle-buttonExt{
background: none;
margin: 8px;
padding: 1px;
border: none;
}

/* When settings is active, show expanded buttons */
.carrom-settingsIcon-buttonIcon.active + .carrom-settings-expanded {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto; /* Enable interaction */
}




/* Icons inside the expanded buttons */
.carrom-soundToggle-buttonExt img,
.carrom-leaveGame-buttonExt img {
    width: 25px;
    height: 25px;
}


  
  .carrom-dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85); /* Black background */
    color: #fff; /* White text */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    width: 160px;
    padding: 10px;
    z-index: 20;
    backface-visibility: hidden;
    animation: fadeIn 0.2s ease-in-out;
    will-change: transform;
    transform: translateZ(0);
    
    /* Scrollable List */
    max-height: 150px; /* Adjust height based on how many items you want visible */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

/* Ensure smooth scrolling */
.carrom-dropdown-menu::-webkit-scrollbar {
    width: 6px;
}

.carrom-dropdown-menu::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.carrom-dropdown-menu::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
}

.carrom-dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.carrom-dropdown-menu li {
    padding: 10px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.2s ease-in-out;
    white-space: nowrap; /* Prevent text from wrapping */
}

.carrom-dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light highlight */
}











/* Message Popups */
.carrom-message-popup {
    position: absolute;
    z-index: 1000;
  }
  
  .carrom-message-popup-inner {
    background-color: white;
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    text-align: center;
    max-width: 100px;
   
  }
  
  /* Player Message Position */
  .carrom-player-message {
    bottom: 115%;
    left: 12%;
    transform: translateX(-50%);
    animation: carrom-massagePopupAnimation 0.4s ease-out;
  }
  
  .carrom-player-message-inner::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  /* Opponent Message Position */
  .carrom-opponent-message {
    top: 100%;
    left: 12%;
    transform: translateX(-50%);
    animation: carrom-massagePopupAnimation 0.4s ease-out;
  }
  
  .carrom-opponent-message-inner::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  

/* Animation for smooth popup appearance */
@keyframes carrom-massagePopupAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}



















  .carrom-leave-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: carrom-popupAnimation 0.4s ease-out;
  }
  

  
  .carrom-leave-game-header {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .carrom-leave-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
  }
  
  .carrom-leave-game-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .carrom-leave-game-btn {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    width: 45%;
    text-align: center;
  }
  
  .carrom-leave-game-btn:hover {
    background-color: #666;
  }
  









  /* Container for the end reason message */
.carrom-end-reason-container {
    background-color: rgba(255, 255, 255, 0.1);  /* Lighter dark background to stand out */
    padding: 10px;
    margin-top: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.3);  /* Subtle border to distinguish it */
}

/* Icon for the end reason */
.carrom-end-reason-icon {
    margin-right: 8px;
    font-size: 18px;
}

/* Reason text */
.carrom-end-reason-text {
    display: flex;
    align-items: center;
}



.backgammon-container {
    background: linear-gradient(180deg, #1a1a1a, #283c86);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow: hidden;
    touch-action: none;
}

/* Opponent Wrapper */
.backgammon-opponent-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Opponent Area */
.backgammon-opponent-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 14px;
    width: 100%;
    max-width: 500px;
    background: linear-gradient(180deg, #3b4c6b 0%, #1d2b4f 77.5%);
    border: 2px solid #8c5e01;
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box;
}

/* Opponent Avatar */
.backgammon-opponent-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px;
    font-weight: bold;
    color: #464646;
    text-transform: uppercase;
    overflow: hidden;
}

/* Opponent Info */
.backgammon-opponent-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
}

/* Opponent Name and Flag */
.backgammon-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.backgammon-opponent-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.backgammon-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Opponent Score */
.backgammon-opponent-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Opponent Checkers Borne Off */
.backgammon-opponent-checkers {
    font-size: 14px;
    color: #4caf50;
}


























.backgammon-board-wrapper {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.backgammon-board-container {
    position: relative; /* This allows us to position points inside the container */
    width: 340px; /* Board container width */
    height: 400px; /* Board container height */
    background: url('/backgammonAssets/board.png') no-repeat center center;
    background-size: cover;
    box-shadow: 0 0 14px 10px rgba(0,0,0,0.4);
    border-radius: 5px;
    z-index: 1;
}

.backgammon-point {
    position: absolute; /* Points are positioned inside the board container */
    width: 26px;  /* Width for each point */
    height: 122.5px;  /* Height for each point */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
}



/* Updated Checker Styling with PNG images */
.backgammon-checker {
    width: 24.5px;  /* Resize to fit inside the point */
    height: 24.5px;
    position: absolute;
    bottom: 0;  /* Start at the bottom of the point */
    z-index: 1;  /* Stack the checkers */
    transition: top 0.3s ease, bottom 0.3s ease;
}

/* White Checker */
.backgammon-checker.white {
    background: url('/backgammonAssets/checker-white.png') no-repeat center center;
    background-size: contain;
}

/* Black Checker */
.backgammon-checker.black {
    background: url('/backgammonAssets/checker-black.png') no-repeat center center;
    background-size: contain;
}

/* Adjust for stacked checkers: */
.backgammon-checker.black.stacked {
    z-index: 1005; 
}

/* Adjust for stacked checkers: */
.backgammon-checker.white.stacked {
    z-index: 1005; 
}


.backgammon-dice-container {
    position: absolute;
    bottom: 184px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.player-dice {
    left: 64.2%; /* Right half of the board for the current player */
}

.opponent-dice {
    left: 13.7%; /* Left half of the board for the opponent */
}

.backgammon-dice1,
.backgammon-dice2 {
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    z-index: 1;
}

.backgammon-OpponentDice1,
.backgammon-OpponentDice2 {
    width: 32px;
    height: 32px;
    left: 20%;
    transform: translateX(-20%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.backgammon-roll-button {
    position: absolute;
    bottom: 178px;
    cursor: pointer;
}

.backgammon-bear-off {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.game-end-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 18px;
}



















/* Player Wrapper */
.backgammon-player-wrapper {
    position: absolute;
    bottom: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Player Area */
.backgammon-player-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 14px;
    width: 100%;
    max-width: 500px;
    background: linear-gradient(180deg, #3b4c6b 0%, #1d2b4f 77.5%);
    border: 2px solid #8c5e01;
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box;
}

/* Player Avatar */
.backgammon-player-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px;
    font-weight: bold;
    color: #464646;
    text-transform: uppercase;
    overflow: hidden;
}

.backgammon-avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

/* Player Info */
.backgammon-player-info {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Player Name and Flag */
.backgammon-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.backgammon-player-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.backgammon-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.backgammon-player-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Player Checkers Off */
.backgammon-player-checkers {
    font-size: 14px;
    color: #4caf50;
}
















/* Apply transform animation for the dice movement */
.shift-die {
    animation: moveDice 0.4s ease forwards;
}

/* Define the movement animation */
@keyframes moveDice {
    to {
        transform: translate(var(--shift-x), var(--shift-y)); /* Use the custom properties set in JS */
    }
}












/* Position and size for the SVG container */
.backgammon-player-avatar svg,
.backgammon-opponent-avatar svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg) scale(1.07); /* Ensure the timer starts at the top */
    z-index: 1;
}

/* Styling for the base circle */
.backgammon-player-avatar circle.backgammon-base,
.backgammon-opponent-avatar circle.backgammon-base {
    fill: none;
    stroke: #e9e9e9; /* Gray color for the base circle */
    stroke-width: 3; /* Adjust thickness if needed */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 0; /* Always fully visible */
}

/* Styling for the timer circle */
.backgammon-player-avatar circle.backgammon-timerP,
.backgammon-opponent-avatar circle.backgammon-timerO {
    fill: none;
    stroke-width: 4; /* Thickness of the animated stroke */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 188; /* Initially hidden (full offset) */
    stroke: url(#gradientStroke); /* Apply the gradient */
}

/* Transform and scaling when highlighted */
.backgammon-player-area.highlighted-turn .backgammon-player-avatar,
.backgammon-opponent-wrapper.highlighted-turn .backgammon-opponent-avatar {
    transform: scale(1.22); /* Scale up when active */
    transition: transform 0.35s ease-in-out; /* Smooth scaling */
}

.backgammon-player-area .backgammon-player-avatar,
.backgammon-opponent-wrapper .backgammon-opponent-avatar {
    transform: scale(1); /* Default size */
    transition: transform 0.25s ease-in-out; /* Smooth scaling */
}










/* Control Buttons */
.backgammon-game-buttonsIcons {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 10;
    flex-direction: column;
    align-items: flex-end;
    display: contents;
}

.backgammon-textIcon-buttonIcon,
.backgammon-settingsIcon-buttonIcon {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 8px;
    padding: 1px;
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
}

.backgammon-textIcon-buttonIcon img,
.backgammon-settingsIcon-buttonIcon img {
    width: 25px;
    height: 25px;
}

/* Icons inside the expanded buttons */
.backgammon-soundToggle-buttonExt img,
.backgammon-leaveGame-buttonExt img {
    width: 25px;
    height: 25px;
}

/* Expanded Settings Buttons */
.backgammon-settings-expanded {
    position: absolute;
    display: grid;
    bottom: 70%;
    right: 17px;
    z-index: 9;
    opacity: 0;
    transform: translateX(10px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none;
}

.backgammon-settingsIcon-buttonIcon.active + .backgammon-settings-expanded {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
}

.backgammon-leaveGame-buttonExt,
.backgammon-soundToggle-buttonExt{
background: none;
margin: 3px;
padding: 1px;
border: none;
}




/* Dropdown Menu */
.backgammon-dropdown-menu {
    position: absolute;
    margin-bottom: 385px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    width: 160px;
    padding: 10px;
    z-index: 20;
}

.backgammon-dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.backgammon-dropdown-menu li {
    padding: 10px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.2s ease-in-out;
}

.backgammon-dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Message Popups */
.backgammon-message-popup {
    position: absolute;
    z-index: 1000;
}

.backgammon-message-popup-inner {
    background-color: white;
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    color: #333;
    text-align: center;
    max-width: 100px;
}

  /* Player Message Position */
  .backgammon-player-message {
    bottom: 115%;
    left: 12%;
    transform: translateX(-50%);
    animation: backgammon-massagePopupAnimation 0.4s ease-out;
  }

  .backgammon-player-message-inner::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  /* Opponent Message Position */
  .backgammon-opponent-message {
    top: 100%;
    left: 12%;
    transform: translateX(-50%);
    animation: backgammon-massagePopupAnimation 0.4s ease-out;
  }
  
  .backgammon-opponent-message-inner::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  

/* Animation for smooth popup appearance */
@keyframes backgammon-massagePopupAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}


/* Leave Game Popup */
.backgammon-leave-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
}

.backgammon-leave-game-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.backgammon-leave-game-btn {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    width: 45%;
}
















/* Unique class for the RPS end game popup */
.backgammon-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: backgammon-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes backgammon-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.backgammon-end-game-header {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.backgammon-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.backgammon-end-game-header h3.backgammon-win {
    color: #32cd32; /* Green for win */
}

.backgammon-end-game-header h3.backgammon-lose {
    color: #e74c3c; /* Red for loss */
}

.backgammon-end-game-results-table {
    margin-top: 15px;
    text-align: center;
}

.backgammon-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.backgammon-end-game-score {
    font-size: 1.5rem;
    font-weight: bold;
}

.backgammon-end-game-colon {
    padding: 0 10px;
    color: #fff;
}

.backgammon-end-game-winner-score {
    color: #ffffff; 
}

.backgammon-end-game-loser-score {
    color: #a9a9a9; 
}

/* General row styling */
.backgammon-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column */
.backgammon-results-left {
    display: flex;
    align-items: center;
    width: 150px;
}

/* Icon styling */
.backgammon-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.backgammon-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.backgammon-current-score {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
}

.backgammon-score-result {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

.backgammon-score-positive {
    color: #ffffff;
}

.backgammon-score-negative {
    color: #ffffff;
}

/* Bet Amount */
.backgammon-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Winning Amount */
.backgammon-winning-row .backgammon-winning-amount {
    font-size: 1.4rem;
    font-weight: bold;
    color: #32cd32; /* Green */
}













.backgammon-textIcon-buttonIcon:focus,
.backgammon-textIcon-buttonIcon:hover,
.backgammon-textIcon-buttonIcon:active,
.backgammon-settingsIcon-buttonIcon:focus,
.backgammon-settingsIcon-buttonIcon:hover,
.backgammon-settingsIcon-buttonIcon:active,
.backgammon-soundToggle-buttonExt:focus,
.backgammon-soundToggle-buttonExt:hover,
.backgammon-soundToggle-buttonExt:active,
.backgammon-leaveGame-buttonExt:focus,
.backgammon-leaveGame-buttonExt:hover,
.backgammon-leaveGame-buttonExt:active {
  outline: none;
  box-shadow: none;
  background: none; /* Remove any background styles */
}





