/* Registration Page Styles */
.registration-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.registration-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.registration-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.input-field {
    width: calc(100% - 25px);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
}

.email-description {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
}

.password-guideline {
    color: #888;
    font-size: 12px;
    margin-top: 5px;
}

.register-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    transition: background-color 0.3s ease;
    margin-top: 30px;
    margin-bottom: 50px;
}

.register-button:hover {
    background-color: #dec000;
}

.error-message, .error-text {
    color: #ff4d4d;
    font-size: 12px;
}




/* Unique Popup overlay and styling */
.registration-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.registration-popup {
    background-color: #282828; /* Dark theme */
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 300px;
    width: 100%;
}

.registration-popup h3 {
    margin-bottom: 10px;
}

.popup-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.popup-button:hover {
    background-color: #dec000;
}
/* Password field with toggle icon */
.password-field-container {
    position: relative;
}

.password-toggle-icon {
    color:#FFFFFF;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px; /* Adjust icon size */
    height: 20px;
}

.password-toggle-icon.show {
    opacity: 0.3;
}

.password-toggle-icon {
    opacity: 1;
    }

.password-toggle-icon:hover {
    opacity: 0.3;
}