/* LastPlayedHand.css */
.last-played-hand-container {
   position: fixed;
    top: 10px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.6); /* More transparent background */
    color: white;
    padding: 8px 6px; /* Smaller padding */
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); /* Reduced shadow */
    text-align: center;
    font-size: 10px; /* Smaller font size */
    width: 80px; /* Smaller fixed width */

   
    z-index: 1000; /* Make sure it stays on top */
}

.last-played-hand-header {
    font-weight: bold;
    padding-bottom: 2px;
    margin-bottom: 3px;
}

.last-played-hand-cards {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.last-played-card {
    width: 38px;
    height: 55px;
    background-size: cover;
    border-radius: 1px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
}

.last-played-card.empty {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px dashed rgba(255, 255, 255, 0.6);
}

.last-played-card img {
    width: 100%;
    height: 100%;
    position: absolute;

}

