.game-component {
  background-color: #121212;
  min-height: 100vh;
  overflow-y: auto;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body{
  font-family: 'Roboto', sans-serif;
}



.user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px; /* Keeps the settings icon on the right */
  color: white;

  
}

.user-info::after {
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust as needed */
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #585454; /* Line color */
}

.left-section {
  display: flex;
  align-items: center;
}

.avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.username-with-flag {
  display: flex;
  align-items: center;
  gap: 8px; /* Adds space between the flag and nickname */
}

.flag-icon-user-details {
  width: 20px;
  height: 15px;
}

.username {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long names with dots */
    max-width: 100px; /* Set a limit on the name's width */
}

.score {
  display: flex;
  align-items: center;
}

.score-container {
  display: flex;
  align-items: center;
  padding: 4px 10px; /* Adjust padding for spacing */

  border-radius: 8px; /* Rounded corners */
  
  background-color: #282828; /* Slightly different black */
  
  border: 1px solid #383434;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  
}

.score-icon-img {
  width: 15px;
  height: 15px;
  padding-top: 3px;
  margin-right: 5px; /* Space between icon and score */
}

.score-value {
  font-size: 16px;

  color: white; /* Adjust text color */
}


.avatar-button, .settings-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.avatar-button:hover, .settings-button:hover {
  background: none; /* Prevents background change on hover */
}



.balance-container {
  border: 1px solid #383434;
  background-color: #282828; /* Slightly different black */
  padding: 20px;
  border-radius: 30px;
  width: 264px;
  margin: 0 auto;
  text-align: center;
  margin-top: 75px;
}

.balance-label {
  font-size: 16px;
  color: #9E9E9E;
  margin-bottom: 5px; /* Add spacing between label and balance */
}

.currency-sign {
  
  font-size: 52px; /* Make $ sign bigger */
  color: #9E9E9E;
  margin-right: 8px;
}

.main-balance {

  font-size: 56px; /* Larger font for the main balance */
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.decimal-point {
  font-size: 44px; /* Same height as the smaller part */
  margin-left: 2px;
  color: white;
  font-weight: bold;
}

.decimal-balance {
  font-size: 44px; /* Smaller font for decimal part */
  margin-left: 2px;
  color: white;
  font-weight: normal;
}


.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.action-button {
  flex: 1;
  background-color: #333333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  color: white;
  border: 0px solid grey;
  padding: 10px 5px;
  margin: 0 5px;
  border-radius: 14px;
  cursor: pointer;
  font-size: 12px;
  
  
}

.action-button:hover {
  background-color: #282828;
}

.action-button:active {
  background-color: #333333; /* Even darker green when clicked */
}





.games-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2px;
  margin-top: 20px;
  margin-bottom: 100px;
}

/* For all game items */
.game-item {
  position: relative;
  background-color: #282828;
  width: 40%;
  height: 165px;
  border: 1px solid #383434;
  border-radius: 25px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 4px;
  min-width: 120px;
  max-width: 250px;
}

/* Styling for the unavailable game item (Belote V2V) */
.game-item.disabled {
  position: relative;
  background-color: #444444; /* Muted background color */
  color: #999999; /* Muted text color */
  
  
}

/* Full background overlay for the disabled item */
.game-item.disabled::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  border-radius: 25px; /* Keep the same border-radius as the item */
  z-index: 1; /* Ensure it covers the whole item */
}

/* "Coming Soon" message */
.game-item.disabled::after {
  content: '(Coming Soon)';
  position: absolute;
  bottom: 20px; /* Set the distance from the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center the text horizontally */
  font-size: 14px;
  color: #9E9E9E; /* Bright red for "Coming Soon" */
  font-weight: bold;
  z-index: 2; /* Display the message above the overlay */
  text-align: center;
  white-space: nowrap; /* Ensure the text doesn't wrap */
}


.game-logo {
  width: 77.5px;
  height: 70px;
  margin-bottom: -4px;
  object-fit: contain;
  
}


.game-title {
  font-size: 20px;
  color: #ffffff;
  margin-top: 10px;
 
}

.game-description {
  font-size: 12px;
  color: rgb(172, 172, 172);
}

.bet-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: flex-end; 

}

.bet-popup-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1001;
  animation: slideUp 0.3s ease;
}

.bet-popup-container.closing {
  animation: slideDown 0.2s ease;
}

.bet-popup {
  background-color: #282828;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  padding-bottom: 120px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  max-height: 70vh; /* Limit popup height */
  overflow-y: auto; /* Allow scrolling inside the popup */

  
}


@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
      transform: translateY(0);
  }
  to {
      transform: translateY(100%);
  }
}

.bet-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  
}

.bet-option {
  font-size: 14px;
  width: 40%;  /* Two items per row */
  background-color: #333333;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: white;
  border: 1px solid #383838;
  cursor: pointer;
  margin-top: 10px;
    /* Add min and max width constraints */
    max-width: 200px;  /* Prevents the option from growing too large */
    min-width: 120px;  /* Prevents the option from becoming too small */
}

.option-icon {
  width: 28px;
  height: 28px;
  margin-top: 10px;
  
}

.bet-type {
  font-size: 12px;
  font-weight: bold;
  color: #9E9E9E;
  
}

.bet-condition {
  font-size: 12px;
  color: #FFD700;
  margin-top: -8px;
}

.pro-game {
  background-color: #333333;
}

.bet-option:hover {
  background-color: #555555;
}





.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 0px; /* Move the title and button slightly up */
}

.popup-header h3 {
  color: #FFFFFF;
  margin: 0;
  font-size: 16px;
}

.close-popup {
  background: none;
  font-size: 24px;
  color: #9E9E9E;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: absolute;
  right: 5px; /* Moves it to the right */
}



.close-popup:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */

}




/* Overlay styling to cover the whole screen */
.search-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Main popup styling */
.search-popup {
  background-color: #282828; /* Match the app's dark theme */
  border-radius: 20px;
  padding: 20px;
  width: 300px;
  text-align: center;
  position: relative;
}

/* Popup header with title and close button */
.search-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-popup-header h3 {
  color: #9E9E9E;
  margin: 0px;
  font-size: 18px;
}



/* Body of the popup */
.popup-body {
  margin-top: 20px;
}

.popup-body p {
  color: white;
  margin-bottom: 20px;
  font-size: 14px;
}


/* Loader for the search animation */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e74c3c;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



/* Cancel button styling */
.cancel-search-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.cancel-search-button:hover {
  background-color: #c0392b;
}




.error-message {
  
  color: #ff4d4d;
  padding: 10px;
  margin-top: -40px;
  margin-bottom: -20px;
  text-align: center;
  font-weight: bold;
}























/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 6px;
  background-color: #bebebe; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #383434; /* Scrollbar color */
  border-radius: 10px; /* Rounded corners */
  
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5c5b5b; /* Change color on hover */
}


