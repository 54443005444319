@keyframes cardEntryAnimation {
    0% {
        transform: rotate(var(--card-rotation)) translateY(100px); /* Start 100px lower */
        opacity: 0;
    }
    100% {
        transform: rotate(var(--card-rotation)) translateY(var(--card-translateY)); /* Move to final position */
        opacity: 1;
    }
}

.cardEntryAnimation {
    animation: cardEntryAnimation 0.6s ease-in-out forwards;
}


@keyframes opponentHandAppear {
    0% {
        transform: translateY(-100px); /* Start above the screen */
        opacity: 0; /* Fully transparent */
    }
    100% {
        transform: translateY(0); /* Move to the original position */
        opacity: 1; /* Fully visible */
    }
}






/* Adjusted keyframes to retain the rotation */
/* Adjusted keyframes to retain both the rotation and translateY */
@keyframes moveUpDown {
    0% {
        transform: rotate(var(--card-rotation)) translateY(var(--card-translateY)); /* Start with original rotation and translateY */
    }
    30% {
        transform: rotate(var(--card-rotation)) translateY(calc(var(--card-translateY) - 32px)); /* Move up faster */
    }
    60% {
        transform: rotate(var(--card-rotation)) translateY(calc(var(--card-translateY) - 32px)); /* Hold at the top */
    }
    100% {
        transform: rotate(var(--card-rotation)) translateY(var(--card-translateY)); /* Return to original position */
    }
}


/* Add animation to specific cards that need it */
.card-animate-updown {
    animation: moveUpDown 2.5s ease-in-out forwards;
}


.player-hand {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute; /* Change position to absolute */
    bottom: -14px; /* Anchor to the bottom of the page */
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ensure it spans the full width */
    height: 150px; /* Adjust height as needed */
    z-index: 1001; /* Ensure it overlays the hand */
   
}


/* Style for individual cards within the hand */
.player-hand .card {
    position: relative;
    transform-origin: bottom center;
    transition: transform 0.3s ease;
    will-change: transform;
}

.player-hand.eight-cards .card {
    margin-right: -25px;
    margin-left: -25px;
}
.player-hand.seven-cards .card {
    margin-right: -22px; /* Slightly reduce overlap */
    margin-left: -22px;
}

.player-hand.six-cards .card {
    margin-right: -20px; /* Further reduce overlap */
    margin-left: -20px;
}

.player-hand.five-cards .card {
    margin-right: -18px; /* Increase spacing as cards decrease */
    margin-left: -18px;
}

.player-hand.four-cards .card {
    margin-right: -15px;
    margin-left: -15px;
}

.player-hand.three-cards .card {
    margin-right: -12px;
    margin-left: -12px;
}

.player-hand.two-cards .card {
    margin-right: -10px;
    margin-left: -10px;
}

/* Styles specifically for an 8-card hand */
.player-hand.eight-cards .card:nth-child(1) {
    --card-rotation: -9.6deg;
    --card-translateY: 12.8px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(2) {
    --card-rotation: -6.4deg;
    --card-translateY: 6.4px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

  
}

.player-hand.eight-cards .card:nth-child(3) {
    --card-rotation: -3.2deg;
    --card-translateY: 3.2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

}

.player-hand.eight-cards .card:nth-child(4) {
    --card-rotation: -1.4deg;
    --card-translateY: 0.5px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(5) {
    --card-rotation: 1.4deg;
    --card-translateY: 0px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(6) {
    --card-rotation: 3.2deg;
    --card-translateY: 2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

  
}

.player-hand.eight-cards .card:nth-child(7) {
    --card-rotation: 6.4deg;
    --card-translateY: 6.4px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.eight-cards .card:nth-child(8) {
    --card-rotation: 9.6deg;
    --card-translateY: 12.8px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));

   
}



/* Styles specifically for a 7-card hand */
.player-hand.seven-cards .card:nth-child(1) {
    transform: rotate(-9.6deg) translateY(12.8px); /* Rotate leftmost card and move down */
}

.player-hand.seven-cards .card:nth-child(2) {
    transform: rotate(-6.4deg) translateY(6.4px); /* Rotate next card slightly and move down */
}

.player-hand.seven-cards .card:nth-child(3) {
    transform: rotate(-3.2deg) translateY(3.2px); /* Rotate next card slightly and move down */
}

/* Middle card - keep vertical and move slightly up */
.player-hand.seven-cards .card:nth-child(4) {
    transform: rotate(0deg) translateY(2px); /* Keep middle card vertical  */
}

.player-hand.seven-cards .card:nth-child(5) {
    transform: rotate(3.2deg) translateY(3.2px); /* Rotate next card slightly and move down */
}

.player-hand.seven-cards .card:nth-child(6) {
    transform: rotate(6.4deg) translateY(6.4px); /* Rotate next card slightly and move down */
}

.player-hand.seven-cards .card:nth-child(7) {
    transform: rotate(9.6deg) translateY(14px); /* Rotate rightmost card and move down */
}

/* Styles specifically for a 6-card hand */
.player-hand.six-cards .card:nth-child(1) {
    transform: rotate(-8deg) translateY(10.2px); /* Rotate leftmost card and move down */
}

.player-hand.six-cards .card:nth-child(2) {
    transform: rotate(-4deg) translateY(5.1px); /* Rotate next card slightly and move down */
}

/* Middle left card - slight inward rotation */
.player-hand.six-cards .card:nth-child(3) {
    transform: rotate(-1.28deg) translateY(2px); /* Slight inward rotation */
}

/* Middle right card - slight inward rotation */
.player-hand.six-cards .card:nth-child(4) {
    transform: rotate(1.28deg) translateY(2px); /* Slight inward rotation */
}

.player-hand.six-cards .card:nth-child(5) {
    transform: rotate(4deg) translateY(5.1px); /* Rotate next card slightly and move down */
}

.player-hand.six-cards .card:nth-child(6) {
    transform: rotate(8deg) translateY(10.2px); /* Rotate rightmost card and move down */
}

/* Styles specifically for a 5-card hand */
.player-hand.five-cards .card:nth-child(1) {
    --card-rotation: -8deg;
    --card-translateY: 10.2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(2) {
    --card-rotation: -4deg;
    --card-translateY: 5.1px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(3) {
    --card-rotation: 0deg;
    --card-translateY: 2.5px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(4) {
    --card-rotation: 4deg;
    --card-translateY: 5.1px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}

.player-hand.five-cards .card:nth-child(5) {
    --card-rotation: 8deg;
    --card-translateY: 10.2px;
    transform: rotate(var(--card-rotation)) translateY(var(--card-translateY));


}



/* Styles specifically for a 4-card hand */

.player-hand.four-cards .card:nth-child(1) {
    transform: rotate(-6.4deg) translateY(8.5px); /* Rotate leftmost card and move down */
}

.player-hand.four-cards .card:nth-child(2) {
    transform: rotate(-2.5deg) translateY(4.25px); /* Rotate next card slightly and move down */
}

/* Middle right card - slight inward rotation */
.player-hand.four-cards .card:nth-child(3) {
    transform: rotate(2.5deg) translateY(4.25px); /* Slight inward rotation */
}

.player-hand.four-cards .card:nth-child(4) {
    transform: rotate(6.4deg) translateY(8.5px); /* Rotate rightmost card and move down */
}




/* Styles specifically for a 3-card hand */
.player-hand.three-cards .card:nth-child(1) {
    transform: rotate(-6.4deg) translateY(8.5px); /* Rotate leftmost card and move down */
}

/* Middle card - keep vertical */
.player-hand.three-cards .card:nth-child(2) {
    transform: rotate(0deg) translateY(4px); /* Middle card vertical */
}

.player-hand.three-cards .card:nth-child(3) {
    transform: rotate(6.4deg) translateY(8.5px); /* Rotate rightmost card and move down */
}


/* Styles specifically for a 2-card hand */
.player-hand.two-cards .card:nth-child(1) {
    transform: rotate(-2.5deg) translateY(6px); /* Rotate leftmost card and move down */
}

.player-hand.two-cards .card:nth-child(2) {
    transform: rotate(2.5deg) translateY(6px); /* Rotate rightmost card and move down */
}

/* Styles specifically for a 1-card hand */
.player-hand.one-card .card:nth-child(1) {
    transform: rotate(0deg) translateY(0px); /* Single card stays vertical */
}




.opponent-hand {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute; /* Change position to absolute */
    top: -12%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ensure it spans the full width */
    height: 150px; /* Adjust height as needed */
    z-index: 1; /* Keep it behind popups */

}


/* Style for the card back images */
.opponent-hand .card-back {
    position: relative;
    width: 80px;
    height: 120px; /* Full height of the card */
    margin-left: -35px; /* Adjust overlap for smaller cards */
    margin-right: -35px;
}

/* Position the image to only show more than half */
.opponent-hand .card-back img {
    position: absolute;
    top: -48px; /* Adjust the position to show more of the card */
    width: 80px;
    height: 120px;
}



.opponent-combination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute; /* Change position to absolute */
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ensure it spans the full width */
    height: 150px; /* Adjust height as needed */
    z-index: 1; /* Keep it behind popups */
}

.combination-card {
    width: 50px; /* Smaller width for the cards */
    height: 80px; /* Smaller height for the cards */
    position: relative;
    z-index: 1000; /* Ensure they appear above other elements */
    transition: transform 0.3s ease; /* Smooth appearance */
    margin-left: -20px; /* Overlap the previous card */
}

.combination-card img {
    width: 100%;
    height: 100%;
    border-radius: 1px; /* Keep rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for cards */
}

