


.rps-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; /* Horizontal centering */
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    max-width: 100vw; /* Prevent accidental resizing */
    background: linear-gradient(180deg, #121212, #1e3c72);
    position: relative; /* For absolute child positioning */
    overflow: hidden;
}



.rps-round-number {
    position: fixed;
    top: 50%;
    left: 50%;
    transform-origin: center; /* Ensure scaling is consistent */
    font-size: 48px; /* Start size */
    font-weight: bold;
    color: #ffffff;
    z-index: 100;
    animation: moveToTop 1s ease-in-out forwards; /* Keyframes for movement and scaling */
}



.rps-animate {
    animation: scaleAndMove 2s ease-in-out forwards;
}

/* Scale and center animation */
@keyframes scaleAndMove {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0.5;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}


/* Move to top with dynamic resizing */
@keyframes moveToTop {
    0% {
        top: 50%;
        left: 50%; /* Centered horizontally */
        transform: translate(-50%, -50%) scale(1); /* Centered with initial size */
        font-size: 48px; /* Initial size */
    }
    100% {
        top: calc(env(safe-area-inset-top) + 5%); /* Top position */
        left: 50%; /* Horizontally centered */
        transform: translate(-50%, -50%) scale(0.5); /* Reduced size dynamically */
        
    }
}











/* Opponent Wrapper */
.rps-opponent-wrapper {
    margin-top: 70px; /* Adds space below the round number */
    width: 100%;
}

.rps-opponent-area {
    display: flex;
    align-items: center;
    padding: 15px 25px; 
}

/* Opponent Avatar */
.rps-opponent-avatar {
    width: 60px; 
    height: 60px; 
    background-color: #D9D9D9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px; /* For initials */
    font-weight: bold;
    color: #464646; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure the image doesn't overflow */
}

.rps-opponent-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; /* Ensure the image scales properly */
}

/* Opponent Info */
.rps-opponent-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.rps-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px; 
}

.rps-opponent-name {
    font-size: 15px; 
    font-weight: bold;
    color: #ffffff; 
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rps-country-flag {
    width: 18px; 
    height: 18px; 
    margin-left: 5px;
    vertical-align: middle;
}

/* Opponent Score */
.rps-opponent-score {
    font-size: 14px; 
    color: #ffde59; 
}

/* Round Result Placeholders */
.rps-opponentRound-result {
    display: flex;
    gap: 15px; 
}

.rps-placeholder {
    width: 35px; 
    height: 35px; 
    background-color: #D9D9D9; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.rps-timer-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.rps-base {
    fill: transparent;
    stroke: #D9D9D9; /* Made lighter for better visibility */
    stroke-width: 3px; /* Increased thickness */
}

.rps-timer {
    fill: none;
    stroke-width: 3px; /* Increased thickness */
    stroke-dasharray: 160; /* Adjusted for larger size */
    stroke-dashoffset: 0;
    animation: rps-timer-animation 10s linear infinite;
}

@keyframes rps-timer-animation {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 160;
    }
}

/* Timer Active */
.rps-timer-active .rps-placeholder {
    border: 3px solid #ffffff; /* Enhanced border for active placeholder */
}














/* Player Wrapper */
.rps-player-wrapper {
    position: absolute; /* Position it relative to the parent container */
    bottom: 50px; /* 50px from the bottom of the screen */
    width: 100%; /* Full width */
}

/* Player Area */
.rps-player-area {
    display: flex;
    align-items: center;
    padding: 15px 25px; /* Padding for consistent spacing */
}


/* Player Avatar */
.rps-player-avatar {
    width: 60px;
    height: 60px; 
    background-color: #D9D9D9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 24px; /* For initials */
    font-weight: bold;
    color: #555; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure the image doesn't overflow */
}

.rps-player-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; /* Ensure the image scales properly */
}

/* Player Info */
.rps-player-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.rps-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px; 
}

.rps-player-name {
    font-size: 15px; 
    font-weight: bold;
    color: #ffffff; 
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rps-country-flag {
    width: 18px; 
    height: 18px; 
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.rps-player-score {
    font-size: 14px; 
    color: #ffde59; 
}


.rps-playerRound-result {
    display: flex;
    gap: 15px;
}

.rps-placeholder {
    width: 35px; 
    height: 35px; 
    background-color: #D9D9D9; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.rps-timer-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.rps-base {
    fill: transparent;
    stroke: #D9D9D9; 
    stroke-width: 3px; 
}

.rps-timer {
    fill: none;
    stroke-width: 3px; 
    stroke-dasharray: 160; 
    stroke-dashoffset: 0;
    animation: rps-timer-animation 10s linear infinite;
}












/* Wrapper for Play Buttons and Waiting Message */
.rps-play-buttons-wrapper {
    position: relative;
    margin-bottom: 100px; /* Fixed distance from the player section */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px; /* Space between the waiting message and buttons */
}





/* Play Buttons Container */
.rps-play-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Even spacing between buttons */
}

/* Individual Play Button */
.rps-play-button {
    background: none;
    border: none;
    width: 90px; 
    height: 90px;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform-origin: center; /* Ensures rotation around the center */
}


.rps-play-button:nth-child(1) {
    width: 75px; 
    height: 75px;
    transform: rotate(-45deg); /* Rock rotated slightly left */
}

.rps-play-button:nth-child(2) {
    transform: rotate(-15deg); /* Paper remains upright */
}

.rps-play-button:nth-child(3) {
    transform: rotate(-45deg); /* Scissors rotated slightly right */
}


.rps-play-button:hover {
    transform: scale(1.1); /* Enlarges on hover */
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.6);
}

.rps-play-button:active {
    transform: scale(0.95); /* Slight shrink on click */
    box-shadow: 0px 2px 6px rgba(255, 255, 255, 0.4);
}

/* Play Button Icon */
.rps-play-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Initial Centered State - No Animation */
.rps-play-choice {
    position: fixed;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 10;
}

/* Smooth Animation Only for Moving to Play Area */
.rps-animate-to-play-area {
    transition: transform 1s ease-in-out; /* Apply transition only here */
    transform: translate(-50%, -150px); /* Move icon to play area */
}

/* Chosen Icon Wrapper */
.rps-chosen-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Optional: to match the round look */
}

/* Specific Sizes for Different Choices */
.rps-chosen-icon-wrapper.rock {
    width: 75px; /* Specific size for rock */
    height: 75px;
}

.rps-chosen-icon-wrapper.paper,
.rps-chosen-icon-wrapper.scissors {
    width: 90px; /* Larger size for paper and scissors */
    height: 90px;
}

/* Chosen Icon Image */
.rps-chosen-icon {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Prevents distortion */
    animation: bounce 0.5s ease-in-out infinite alternate;
}

/* Remove bounce animation when move is finalized */
.rps-chosen-icon.no-bounce {
    animation: none; /* Stops the bounce animation */
}

/* Waiting Message Styling */
.rps-waiting-message {
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -300%);
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
}



/* Countdown Timer */
.rps-countdown {
    font-size: 14px;
    color: #ffde59; /* Light yellow for visibility */
    font-weight: bold;
    text-align: center;
    animation: RPSpulse 1s ease-in-out infinite;
}

/* Animations */
@keyframes bounce {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
}

@keyframes RPSpulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

.rps-trophy-icon {
    font-size: 22px; /* Adjust size as needed */
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}












/* Play Area */
.rps-play-area {
    position: relative;
    width: 100%;
    height: 150px; /* Adjust height for design */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px; /* Space between play area and other sections */
    overflow: hidden;
}

/* Opponent's Move Wrapper (initial position and animation) */
.rps-opponent-move-wrapper {
    position: fixed; /* Allow global positioning */
    top: 10%; /* Start near the top-center of the screen */
    left: 50%; /* Horizontally centered */
    transform: translateX(-50%) translateY(-150px); /* Initial position */
    z-index: 10; /* Ensure it appears above other elements */
    animation: moveOpponentToPlayArea 1s ease-in-out forwards;
}

/* Opponent's Icon Styling */
.rps-opponent-move-icon {
    object-fit: contain; /* Prevent distortion */
    rotate: 180deg; /* Initial rotation */
}

/* Specific sizes for each move */
.rps-opponent-move-icon.rock {
    width: 75px;
    height: 75px;
}

.rps-opponent-move-icon.paper,
.rps-opponent-move-icon.scissors {
    width: 90px;
    height: 90px;
}

/* Opponent Move Animation Keyframes */
@keyframes moveOpponentToPlayArea {
    0% {
        transform: translateX(-50%) translateY(-150px); /* Start flipped */
    }
    100% {
        transform: translateX(-50%) translateY(150px); /* End upright */
    }
}










/* Winner Animation (delayed after move-to-play-area finishes) */
.rps-winner-icon-delay {
    animation: scalePulse 1.5s ease-in-out 1s infinite; /* Pulse effect starts after a slight delay */
}

/* Tie Animation (delayed) */
.rps-tie-icon-delay {
    animation: scalePulse 1.5s ease-in-out 1s infinite; /* Same pulse effect as winner */
}

/* Pulse Animation for Scaling */
@keyframes scalePulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Slight scale up */
    }
    100% {
        transform: scale(1); /* Scale back to normal */
    }
}


.rps-no-animation {
    animation: none !important; /* Explicitly prevent any animation */
    transform: none; /* Reset any scaling or transformations */
    opacity: 0.6;
}














/* Unique class for the RPS end game popup */
.rps-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: rps-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes rps-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.rps-end-game-header {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.rps-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.rps-end-game-header h3.rps-win {
    color: #32cd32; /* Green for win */
}

.rps-end-game-header h3.rps-lose {
    color: #e74c3c; /* Red for loss */
}

.rps-end-game-results-table {
    margin-top: 15px;
    text-align: center;
}

.rps-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.rps-end-game-score {
    font-size: 1.5rem;
    font-weight: bold;
}

.rps-end-game-colon {
    padding: 0 10px;
    color: #fff;
}

.rps-end-game-winner-score {
    color: #ffffff; 
}

.rps-end-game-loser-score {
    color: #a9a9a9; 
}

/* General row styling */
.rps-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column */
.rps-results-left {
    display: flex;
    align-items: center;
    width: 150px;
}

/* Icon styling */
.rps-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.rps-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.rps-current-score {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
}

.rps-score-result {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

.rps-score-positive {
    color: #ffffff;
}

.rps-score-negative {
    color: #ffffff;
}

/* Bet Amount */
.rps-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Winning Amount */
.rps-winning-row .rps-winning-amount {
    font-size: 1.4rem;
    font-weight: bold;
    color: #32cd32; /* Green */
}

/* Button Styling */
.rps-close-btn {
    margin-top: 15px;
    background: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}


