/* GamePoints.css */
.game-points-container {
    position: fixed;
    top: 10px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 8px 8px; /* Slightly increased padding */
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 11px; /* Slightly larger font size */
    width: 90px; /* Increased width slightly */
    z-index: 1000;
}

.game-points-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding-bottom: 6px;
    margin-bottom: 4px; /* Slightly increased spacing */
    font-size: 15px;
}

.game-points-values {
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 4px; /* Slightly increased padding */
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Slightly more visible border */
}

.game-points-values span {
    flex: 1;
    text-align: center;
    font-size: 11px; /* Slightly larger font for values */
    font-weight: bold;
}

.game-points-divider {
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3); /* Slightly more visible divider */
    margin: 0 3px; /* Slightly increased space around the divider */
}




/* Unique class for general popup styling */
.ppbet-round-results-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: ppbet-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance with scaling */
@keyframes ppbet-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5); /* Start from smaller size */
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1); /* Grow to full size */
    }
}

.ppbet-result-header {
    text-align: center;
}

/* Win/Lose Status Styles */
.ppbet-round-status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
}

.ppbet-round-status h3 {
    margin: 0; /* Remove default margin */
    padding: 5px 0; /* Specific padding for h3 */
}

.ppbet-round-status.ppbet-win h3 {
    color: #32cd32; /* Green for win */
}

.ppbet-round-status.ppbet-lose h3 {
    color: #e74c3c; /* Red for loss */
}

.ppbet-results-table {
    margin-top: 15px;
}

.ppbet-results-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
}

/* Header Row */
.ppbet-results-row.ppbet-header {
    font-weight: bold;
    font-size: 15px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
    white-space: nowrap;
    
}

/* Total Row */
.ppbet-results-row.ppbet-total {
    font-weight: bold;
    font-size: 18px;
    background: linear-gradient(135deg, #191919c4, #252525c4);

    border-top: 2px solid rgba(255, 255, 255, 0.3);
}

/* Equal column widths for cleaner layout */
.ppbet-results-row > div {
    flex: 1;
    text-align: center;
    max-width: 85px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}






/* Unique class for the end game popup */
.ppbet-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 8001;
    width: 280px;
    max-height: 80%;
    animation: ppbet-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes ppbet-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.ppbet-end-game-header {
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
}

.ppbet-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.ppbet-end-game-header h3.ppbet-win {
    color: #32cd32; /* Green for win */
}

.ppbet-end-game-header h3.ppbet-lose {
    color: #e74c3c; /* Red for loss */
}

.ppbet-end-game-results-table {
    margin-top: 15px;
    text-align: center; /* Center the content */
}

.ppbet-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.ppbet-end-game-score {
    font-size: 1.5rem; /* Increase font size for better visibility */
    font-weight: bold;
}

.ppbet-end-game-colon {
    padding: 0 10px; /* Space between the scores */
    color: #fff; /* Keep the colon visible */
}

.ppbet-end-game-winner-score {
    color: #ffffff; /* Green for the winner */
}

.ppbet-end-game-loser-score {
    color: #a9a9a9; /* Gray for the loser */
}



/* General Row Styling */
.ppbet-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column - Consistent Width */
.ppbet-results-left {
    display: flex;
    align-items: center;
    width: 150px; /* Fixed width for all left column elements */
}

/* Score Row */
.ppbet-score-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Icon Styling */
.ppbet-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px; /* Reduce the margin */
}

.ppbet-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Current Score and Score Result */
.ppbet-current-score {
    font-size: 1rem; /* Keep font size the same as other text */
    font-weight: bold;
    display: flex;
    align-items: center; /* Align icon and text vertically */
    margin-right: 10px; /* Ensure spacing between the current score and score result */
}

.ppbet-score-result {
    font-size: 1rem; /* Normalize font size */
    font-weight: bold;
    margin-left: 5px; /* Ensure spacing between the score and result */
}

.ppbet-score-positive {
    color: #ffffff;
}

.ppbet-score-negative {
    color: #ffffff;
}

/* Bet Amount Row */
.ppbet-bet-amount-container {
    display: flex;
    align-items: center;
}

.ppbet-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Dollar Sign Styling */

.ppbet-dollar-signBET {
    color: #ccc;
    margin-right: 5px;
}

.ppbet-dollar-sign {
    font-size: 1.3rem;
    color: #ccc;
}

/* Winning Amount */
.ppbet-winning-row .ppbet-winning-amount {
    font-size: 1.4rem; /* Larger size for winning amount */
    font-weight: bold;
    color: #32cd32; /* Green color */
}

/* Align Columns Properly */
.ppbet-results-right {
    text-align: right;
    flex: 1;
    padding-left: 10px; /* Add space between text and right column */
}

/* Additional Spacing for Left and Right Columns */
.ppbet-results-left {
    display: flex;
    align-items: center;
    width: 160px; /* Fixed width for consistent alignment */
}

.ppbet-results-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}
