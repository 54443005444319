/* Reset Password Page Styles */
.reset-password-page-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.reset-password-page-title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: bold;
}

.reset-password-guideline-text {
    text-align: center;
    color: #ccc;
    margin-bottom: 20px;
}

.reset-password-form {
    display: flex;
    flex-direction: column;
}

.reset-password-form-group {
    margin-bottom: 15px;
}

.reset-password-input-field {
    width: calc(100% - 25px); 
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555; /* Adds a dark border to the text box */
    background-color: #1E1E1E; /* Dark background for the text box */
    color: white; /* White text to ensure readability */
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.reset-password-input-field:focus {
    border-color: #eaeaea; /* Same as the regular border color */
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none; /* Remove the blue outline */
    font-size: 16px;
}

.reset-password-submit-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    transition: background-color 0.3s ease;
    margin-top: 30px;
}

.reset-password-submit-button:hover {
    background-color: #dec000;
}

.reset-password-submit-button.disabled {
    background-color: #999;
    cursor: not-allowed;
    opacity: 0.7;
}

.reset-password-error-message {
    color: #ff4d4d;
    text-align: center;
    margin-top: 10px;
}

.reset-password-success-message {
    color: #4caf50;
    text-align: center;
    margin-top: 10px;
}

/* Loading Spinner */
.reset-password-spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-right: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Popup Overlay and Content */
.reset-password-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.reset-password-popup {
    background-color: #282828;
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 300px;
}

.reset-password-popup h3 {
    margin-bottom: 10px;
}

.reset-password-popup-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
}

.reset-password-popup-button:hover {
    background-color: #dec000;
}
