/* LoadingScreen.css */

.loading-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.986); /* Dimmed overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it covers all other elements */
    color: #fff;
    font-size: 1.5em;
}

.loading-screen-content {
    text-align: center;
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-top: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
