/* Player Label Container */
.player-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.85); /* More transparent background */
    border-radius: 8px;
    box-shadow: 0 0 1px #e9e9e9; /* Yellow subtle shadow */
    transform: scale(0.85);
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 115px;
    z-index: 2000;
    overflow: hidden;
}

/* Opponent Section */
.opponent-section .player-label {
    position: absolute;
    top: 2px;
    bottom: auto;
    z-index: 2;
}

/* Player Icon/Avatar */
.player-label .player-icon {
    width: 35px;
    height: 35px;
    margin-right: 8px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #D9D9D9; /* Fallback background for initials */
    font-size: 16px; /* Adjust size for initials */
    font-weight: bold;
    color: #ffffff;
    overflow: hidden;
}

.player-label .player-icon img {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    object-fit: cover;
}

.player-initial {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px; /* Match font size with avatar size */
    font-weight: bold;
    color: #464646;
    background-color: #D9D9D9; /* Fallback color for initials */
    border-radius: 50%;
}


/* Name and Flag Container */
.name-and-flag {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Player Nickname */
.player-label .player-name {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis if name is too long */
}

/* Country Flag */
.country-flagL {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.player-score {
    font-size: 10px;
    color: #ffde59; /* Lighter color for score */
    font-weight: normal;
    margin-top: 0px;
    display: block; /* Place score under the name */
}

/* Round Points */
.round-points {
    font-size: 12px;
    font-weight: normal;
    color: #f0f0f0; /* Red for round points to highlight */
    display: block;
}

/* Trump Suit Icon */
.trump-icon {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(25%);
    width: 17px;
    height: 17px;
    background: #ffffff;
    border-radius: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.trump-icon img {
    width: 70%;
    height: 70%;
}

/* SVG Circles for the Timer Effect */
.player-icon svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    z-index: 1;
}

/* Base Circle always visible */
.player-icon circle.base {
    fill: none;
    stroke: #e9e9e9;
    stroke-width: 2;
    stroke-dasharray: 188;
    stroke-dashoffset: 0;
}

/* Timer Circle that animates */

.player-icon circle.timer {
    fill: none;
    stroke-width: 3;
    stroke-dasharray: 188;
    stroke-dashoffset: 188;
    transition: none;
}

/* Highlighted Player with Timer Animation */
.player-section.highlighted.waiting-for-move .player-icon circle.timer,
.opponent-section.highlighted.waiting-for-move .player-icon circle.timer {
    transition: stroke-dashoffset 25s linear;
    stroke-dashoffset: 0;
}



/* Hide the timer instantly when it's not the player's turn */
.player-section:not(.highlighted) .player-icon circle.timer,
.opponent-section:not(.highlighted) .player-icon circle.timer {
    stroke-dashoffset: 188;
    transition: none;
}

/* Highlight the player label with yellow border when it's their turn */
.player-section.highlighted .player-label {
    border-top: 2.5px solid #13C513; /* Yellow border on top */
    box-shadow: 0 -1.5px 2.5px #13C513;
}

.opponent-section.highlighted .player-label {
    border-bottom: 2.5px solid #13C513;
    box-shadow: 0 1.5px 2.5px #13C513;
}



/* Scale up the avatar and timer when it's the player's turn */
.opponent-section.highlighted.waiting-for-move .player-icon,
.player-section.highlighted.waiting-for-move .player-icon {
    transform: scale(1.22); /* Increase size */
    transition: transform 0.35s ease-in-out; /* Smooth transition */
}

/* Revert to normal size when the class is removed */
.opponent-section .player-icon,
.player-section .player-icon {
    transform: scale(1); /* Normal size */
    transition: transform 0.25s ease-in-out;
}
