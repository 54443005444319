/* Login Page Styles */
.login-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.login-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 50px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.label {
    font-size: 14px;
}

.input-field {
    width: calc(100% - 25px); 
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-bottom: 10px;
}

.input-field:focus{
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none;
}

/* Button styles */
.login-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 50px;
}

.login-button:hover {
    background-color: #dec000;
}

.login-disabled {
    background-color: #dec000;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Loading Spinner */
.spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    color: #ff4d4d;
    text-align: center;
    margin-top: 10px;
}

.login-options {
    margin-top: 20px;
    text-align: center;
}

.link-button {
    background: none;
    border: none;
    color: #00aaff;
    cursor: pointer;
    margin-top: 10px;
}

.link-button:hover {
    background: none;
    text-decoration: underline;
}


/* Container to hold the Telegram button with a scaling effect */
.telegram-login-container {

    width: 100%; /* Let the width adjust dynamically */
    max-width: 250px; /* Set max width to prevent cutoff */
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    animation: scaleEffect 1.3s infinite ease-in-out;
    overflow: hidden; /* Ensures the animation stays within bounds */
}

@keyframes scaleEffect {
    0%, 100% {
        transform: scale(1); /* Normal size */
    }
    50% {
        transform: scale(1.1); /* Scale up slightly */
    }
}


/* Loading screen specifically for login page */
#login-loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #121212;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 20px;
    color: white;
    transition: opacity 0.3s ease;
}
#login-loading-screen.hidden {
    opacity: 0;
    visibility: hidden;
}
