.appPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden; /* Prevents page from shifting due to scrollbars */
}

.telegram-web-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

html, body, #root {
  height: 100%;
  margin: 0;
  background-color: #121212; /* Ensures a dark background until fully loaded */
}




.page-container {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.page-container.page-exit {
  opacity: 0;
  transform: scale(0.97); /* Slight zoom out effect */
}


/* Popup Styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.popup-inner p {
    margin: 0;
    font-size: 18px;
    color: #333;
}


/* Button Styles */
button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

/* GameComponent Styles */
.game-component {
    text-align: center;
    padding: 20px;
}




/* Withdraw Popup Styles */
.withdraw-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



.withdraw-popup-inner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}




/* Fixed buttons at the bottom */
.fixed-buttons-container {
  position: fixed;
  bottom: 95px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 100;
  
}

/* Invite a Friend Button */
.invite-button {
  background-color: #FFD700;
  color: black;
  border: none;
  padding: 10px 40px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-right: 10px;
}

.invite-button.animated-pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { box-shadow: 0 0 5px #FFD700; }
  50% { box-shadow: 0 0 20px #FFD700; }
  100% { box-shadow: 0 0 5px #FFD700; }
}

.invite-button:hover {
  background-color: #dec000;
}

/* Copy Link Button */
.copy-link-button {
  background-color: #FFD700;
  border: none;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-link-button img {
  width: 24px;
  height: 24px;
}

.copy-link-button:hover {
  background-color: #dec000;
}


.copy-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
}


.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #121212; /* Matches the app background */
  padding: 10px 0 25px; /* Extra 15px padding at the bottom */
  border-top: 1px solid #383838; /* Line separating footer from content */
  box-sizing: border-box; /* Ensures padding doesn’t affect positioning */
}

  .hidden-footer {
    display: none; /* Hides the footer completely */
    /* Alternatively, you can use visibility: hidden; */
}
  
  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: white;
  }
  
 

  .footer-item.active {
    color: #ffffff; /* Highlight color for the active section */
  }
  
  .footer-icon {
    width: 24px;  /* Adjust icon size */
    height: 24px;
    margin-bottom: 5px; /* Space between icon and text */
  }

  
  .footer-item.active .footer-icon {
    filter: brightness(2.5); /* Brightens the active icon */
  }
  
  .footer-item span {
    font-size: 12px;
    color: #a8a8a8; /* Grey color for section names */
  }
  
  .footer-item.active span {
    color: #ffffff; /* Highlight color for active section name */
  }
  

  /* Prevent long-press behavior on images and other elements globally */
img, .no-long-press {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}
