
.carrom-container {
    background: linear-gradient(180deg, #121212, #1e3c72);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    max-width: 100vw; /* Prevent overflow horizontally */
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow: hidden; /* Disable scrolling */
    touch-action: none; /* Prevent gestures like scroll or zoom */
}




/* Opponent Wrapper */
.carrom-opponent-wrapper {
    margin-top: 20px;
    width: 100%; /* Full width */
    display: flex;
    justify-content: center; /* Center the opponent area */
    padding: 0 15px; /* Add padding from the screen borders */
    box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}

/* Opponent Area */
.carrom-opponent-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align content to the left */
    padding: 12px 14px; /* Inner padding for content */
    width: 100%; /* Take up the full wrapper width */
    max-width: 500px; /* Limit the max width */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%);
    border: 2px solid #9A6001; /* Yellow border */
    box-shadow: inset 0 0 12px 5px rgb(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box; /* Include padding and border in dimensions */
}



/* Opponent Avatar */
.carrom-opponent-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px; /* Initials font size */
    font-weight: bold;
    color: #464646; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure image doesn't overflow */
}

/* Opponent Info */
.carrom-opponent-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px; /* Add a bit of spacing inside */
}

/* Opponent Name and Flag */
.carrom-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.carrom-opponent-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carrom-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Opponent Score */
.carrom-opponent-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Opponent Points */
.carrom-opponent-betAmmount {
    font-size: 14px;
    color: #4caf50; /* Green for points */
}


.carrom-opponent-coins {
    position: absolute; /* Position it within the parent container */
    right: 20px; /* Add spacing from the right side */
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
    width: 125px; /* Width of the rectangle */
    height: 45px; /* Height of the rectangle */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%); /* Same gradient */
    box-shadow: inset 0 0 8px 2px rgba(0, 0, 0, 0.6); /* Aggressive outer shadow */
    border-radius: 12px; /* Smaller rounded corners */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content inside */
    box-sizing: border-box;
}

/* Coin Icon for Opponent */
.carrom-opponent-coin-icon {
    width: 22px; /* Coin icon size */
    height: 22px;
    background-size: contain;
    margin-right: 7px; /* Spacing between icon and number */
}

/* Coin Count for Opponent */
.carrom-opponent-coin-count {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* White text */
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7); /* Subtle text shadow */
}












/* Player Wrapper */
.carrom-player-wrapper {
    position: absolute; /* Position it relative to the parent container */
    bottom: 50px; /* 50px from the bottom of the screen */
    width: 100%; /* Full width */
    display: flex;
    justify-content: center; /* Center the player area horizontally */
    padding: 0 15px; /* Add padding from screen borders */
    box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}

/* Player Area */
.carrom-player-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align content to the left */
    padding: 12px 14px; /* Inner padding for content */
    width: 100%; /* Take up the full wrapper width */
    max-width: 500px; /* Limit the max width */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%);
    border: 2px solid #9A6001; 
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6); /* Inner shadow */
    border-radius: 25px;
    box-sizing: border-box; /* Include padding and border in dimensions */
}

/* Player Avatar */
.carrom-player-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9; /* Placeholder background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px; /* Initials font size */
    font-weight: bold;
    color: #464646; /* Placeholder text color */
    text-transform: uppercase;
    overflow: hidden; /* Ensure image doesn't overflow */
}

.carrom-avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; /* Ensure proper scaling */
}

/* Player Info */
.carrom-player-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Player Name and Flag */
.carrom-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.carrom-player-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carrom-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.carrom-player-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Player Points */
.carrom-player-betAmmount {
    font-size: 14px;
    color: #4caf50; /* Green for points */
}


/* Small Rectangle for Coins */
.carrom-player-coins {
    position: absolute; /* Position it within the parent container */
    right: 20px; /* Add spacing from the right side */
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
    width: 125px; /* Width of the rectangle */
    height: 45px; /* Height of the rectangle */
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%); /* Same gradient */
    box-shadow: inset 0 0 8px 2px rgba(0, 0, 0, 0.6); /* Aggressive outer shadow */
    border-radius: 12px; /* Smaller rounded corners */
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content inside */
    box-sizing: border-box;
}

/* Coin Icon */
.carrom-coin-icon {
    
    width: 22px; /* Coin icon size */
    height: 22px;
    background-size: contain;
    margin-right: 7px; /* Spacing between icon and number */
}

/* Coin Count */
.carrom-coin-count {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* White text */
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7); /* Subtle text shadow */
}
















/* Carrom Board Container */
.carrom-board-container {
    position: absolute; /* Allows independent positioning */
    top: 42%; /* Moves the board up relative to the wrapper */
    left: 50%; /* Centers the board horizontally */
    transform: translate(-50%, -50%); /* Ensures proper alignment */
    width: 320px; /* Includes the border size */
    height: 320px; /* Includes the border size */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

/* Board Border */
.carrom-board-border {
    position: relative;
    width: 318px;
    height: 318px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('/carromAssets/board-border.png') no-repeat center center; /* Preserve the original image */
    box-shadow: 0 0 14px 10px rgba(0,0,0,0.4);
    border-radius: 13px;
}



/* Main Carrom Board */
.carrom-board {
    position: relative; /* Ensures coins are positioned relative to the board */
    width: 300px;
    height: 300px;
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5) 0%, /* Bright center */
        rgba(255, 255, 255, 0.2) 50%, /* Gradual fade */
        rgba(0, 0, 0, 0) 100% /* Transparent edges */
    ),
    url('/carromAssets/board-background.png') no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    box-shadow: inset 0 0 14px 16px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}

/* Board Lines */
.board-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/carromAssets/board-lines.png') no-repeat center center;
    background-size: cover;
    pointer-events: none;
}

/* Coins */
.carrom-coin {
    position: absolute;
    width: 20px; /* Smaller size for coins */
    height: 20px;
    pointer-events: none; /* Prevent interaction */
    transform: translate(-50%, -50%); /* Center coins at their positions */
    will-change: transform, top, left;
    z-index: 10;
}


.carrom-coin.highlight-queen {
    border: 1px solid #ffde59; /* Yellow border */
    box-shadow: 0px 0px 5px 0px #ffde59; /* Focused glow effect */
    border-radius: 50%; /* Make border circular */
    animation: pulsateQueen 1.5s infinite ease-in-out; /* Apply pulsation */
}

/* Define the pulsation animation */
@keyframes pulsateQueen {
    0% {
        box-shadow: 0px 0px 5px 0px #ffde59; /* Minimal glow */
        
    }
    50% {
        box-shadow: 0px 0px 8px 0px #ffde59; /* Slightly brighter glow */
        
    }
    100% {
        box-shadow: 0px 0px 5px 0px #ffde59; /* Back to minimal glow */
        
    }
}



.carrom-coin.pocketing {
    will-change: transform, top, left;
    transition: transform 0.6s ease, top 0.6s ease, left 0.6s ease;
    z-index: 100;
}

.carrom-striker {
    position: absolute;
    width: 30px; /* Match the size of the PNG */
    height: 30px;
    transform: translate(-50%, -50%); /* Center it properly */
    z-index: 2; /* Keep it above other elements */
    pointer-events: all; /* Ensure it receives clicks/touches */
    background-color: rgba(0, 0, 0, 0); /* No background, just the image */
    z-index: 110;
}

/* Striker Controller */
.striker-controller {
    position: absolute;
    top: calc(42% + 190px);
    left: 50%;
    transform: translate(-50%, 0);
    width: 200px;
    height: 30px;
    border-radius: 15px;
    background: linear-gradient(180deg, #4C607B 0%, #1D314D 77.5%);
    border: 2px solid #9A6001; 
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6),
                      0px 8px 15px rgba(0, 0, 0, 0.4);  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1;
}

.striker-controller.active {
    pointer-events: all; /* Allow interaction when active */
    opacity: 1;
}

.striker-controller.inactive {
    pointer-events: none;
    opacity: 0.5;
}

.striker-handle {
    position: absolute;
    width: 30px;
    height: 30px;
    background: url('/carromAssets/striker.png') no-repeat center center;
    background-size: contain;
    cursor: grab;
}

.striker-handle:active {
    cursor: grabbing;
    transform: scale(0.95); /* Slight scale effect for interaction */
}



















/* Rotating Circle Animation */
.carrom-striker-animation {
    position: absolute;
    width: 36px; /* Initial radius for the animation */
    height: 36px;
    border: 1px solid gold;
    border-radius: 50%;
    background-color: rgba(236, 134, 17, 0.676); /* Gold fill with reduced opacity */
    box-shadow: 0 0 8px rgba(255, 255, 0, 0.8);
    animation: carrom-rotate-circle 3s linear infinite; /* Infinite rotation */
    pointer-events: none; /* Prevent interaction */
    z-index: 1; /* Place above the striker */
    transition: width 0.3s, height 0.3s; /* Smoothly adjust size on drag */
}

/* Smaller Circle (when dragging) */
.carrom-striker-animation.carrom-small {
    animation: carrom-rotate-circle-small 3s linear infinite; /* Shrinking + rotation */
}

.carrom-striker-animation.carrom-overlap {
    background-color: rgba(255, 0, 0, 0.6); /* Red with some transparency */
    border: 1px solid red;
    box-shadow: 0 0 12px red;
}


/* Arrow Styles */
.carrom-striker-animation .carrom-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2px; /* Size of the arrow */
    border-color: transparent transparent gold transparent; /* Arrow pointing inward */
}

/* Arrow Placement */
.carrom-striker-animation .carrom-arrow-top {
    top: 0px; /* Move arrow slightly above the border */
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
}

.carrom-striker-animation .carrom-arrow-bottom {
    bottom: 0px; /* Move arrow slightly below the border */
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
}

.carrom-striker-animation .carrom-arrow-left {
    left: 0px; /* Move arrow slightly left of the border */
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}

.carrom-striker-animation .carrom-arrow-right {
    right: 0px; /* Move arrow slightly right of the border */
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
}

/* Infinite Rotation Keyframes */
@keyframes carrom-rotate-circle {
    0% {
        transform: translate(-50%, -50%) rotate(0deg); /* Start position */
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg); /* Full rotation */
    }
}


/* Infinite Rotation Keyframes with Smaller Scale */
@keyframes carrom-rotate-circle-small {
    0% {
        transform: translate(-50%, -50%) rotate(0deg) scale(0.93); /* Smaller size */
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg) scale(0.93); /* Smaller size */
    }
}




















.carrom-hit-circle {
    position: absolute;
    background-color: rgba(158, 157, 154, 0.164); /* Light semi-transparent white */
    border: 1px dashed rgba(227, 227, 227, 0.395); /* Dashed border with semi-transparent white */
    border-radius: 50%; /* Keep it circular */
    pointer-events: none; /* Prevent interaction */
    z-index: 5; /* Above the striker */
    transition: width 0.1s ease, height 0.1s ease; /* Smooth scaling */
}


.carrom-striker-dot {
    position: absolute;
    border-radius: 50%; /* Make it a perfect circle */
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,       /* Bright highlight at the top (fully opaque) */
        rgba(255, 255, 255, 0.9) 40%,   /* Reduced transparency */
        rgba(220, 220, 220, 0.95) 70%,  /* Almost opaque gray for depth */
        rgba(180, 180, 180, 1) 100%     /* Fully opaque shadow at the bottom */
    );
    box-shadow: 0 3px 6px rgba(138, 137, 137, 0.4), /* Stronger shadow below the ball */
                inset 0 -1px 2px rgba(121, 121, 121, 0.3); /* Slightly stronger inner shadow */
    transform: translate(-50%, -50%); /* Center it precisely */
    z-index: 105; /* Ensure balls are visible */
    transition: transform 0.1s ease, width 0.1s ease, height 0.1s ease; /* Smooth scaling animation */
}


.carrom-white-dot {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Wrapper for the entire arrow */
.carrom-hit-arrow-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    pointer-events: none; /* Prevent interaction */
    z-index: 106; /* Ensure it stays above other elements */
}

/* Arrow Line (with arrowhead drawn as ::before) */
.carrom-hit-arrow-line {
    height: 5.5px; /* Thickness of the arrow line */
    border-radius: 8px; /* Smooth rounded edges */
    transition: background 0.2s linear; /* Smooth color transition */
    position: relative; /* Needed for the ::before arrowhead */
    z-index: 106;
}


.carrom-hit-arrow-line::after {
    content: ""; /* Pseudo-element for the arrowhead */
    position: absolute;
    top: 50%; /* Center the arrowhead vertically */
    right: -5px; /* Position at the end of the arrow line */
    transform: translateY(-50%) rotate(-90deg); /* Align with the line direction */
    width: 0;
    height: 0;
    border-left: 8.5px solid transparent; /* Create triangular arrowhead */
    border-right: 8.5px solid transparent;
    border-top: 10.5px solid var(--arrow-color, gold); /* Use CSS variable or fallback to gold */
    z-index: 107;
}






.carrom-striker.falling {
    animation: fall-into-hole 1s ease-out forwards;
    transform-origin: center;
}

@keyframes fall-into-hole {
    0% {
        transform: translate(-50%, -50%) scale(1); /* Include translate to maintain centering */
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(0.4); /* Combine translate with scale */
        opacity: 0.4;
    }
}




.carrom-CoinFallingToHoll {
    position: absolute;
    transition: transform 0.5s ease, left 0.5s ease, top 0.5s ease;
    animation: coinFall-into-hole 1s ease-out forwards;
    

}

@keyframes coinFall-into-hole {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.8); /* Slightly smaller */
        opacity: 1; /* Reduce opacity */
    }
}


.carrom-CoinMovingFromHoll {
    animation: coinMoveing-from-hole 0.5s ease-out forwards;
    transform-origin: center;
}

@keyframes coinMoveing-from-hole {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }
    100% {
        transform: scale(1.2); /* Slightly smaller */
        opacity: 0.8; /* Reduce opacity */
    }
}


















.striker-foul-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85); /* Slightly darker background */
    color: #fff;
    padding: 20px 40px;
    border-radius: 12px; /* Softer corners */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 320px; /* Wider for better visual proportions */
    max-height: 80%;
    animation: striker-popup-animation 0.4s ease-out; /* Smooth scaling */
}

@keyframes striker-popup-animation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.striker-popup-header {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: #ffde59; /* Yellow for emphasis */
    margin-bottom: 10px;
}

.striker-popup-message {
    text-align: center;
    font-size: 1rem;
    color: #fff;
}

.striker-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


















/* Unique class for the RPS end game popup */
.carrom-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: carrom-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes carrom-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.carrom-end-game-header {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.carrom-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.carrom-end-game-header h3.carrom-win {
    color: #32cd32; /* Green for win */
}

.carrom-end-game-header h3.carrom-lose {
    color: #e74c3c; /* Red for loss */
}

.carrom-end-game-results-table {
    margin-top: 15px;
    text-align: center;
}

.carrom-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.carrom-end-game-score {
    font-size: 1.5rem;
    font-weight: bold;
}

.carrom-end-game-colon {
    padding: 0 10px;
    color: #fff;
}

.carrom-end-game-winner-score {
    color: #ffffff; 
}

.carrom-end-game-loser-score {
    color: #a9a9a9; 
}

/* General row styling */
.carrom-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column */
.carrom-results-left {
    display: flex;
    align-items: center;
    width: 150px;
}

/* Icon styling */
.carrom-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.carrom-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carrom-current-score {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
}

.carrom-score-result {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

.carrom-score-positive {
    color: #ffffff;
}

.carrom-score-negative {
    color: #ffffff;
}

/* Bet Amount */
.carrom-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Winning Amount */
.carrom-winning-row .carrom-winning-amount {
    font-size: 1.4rem;
    font-weight: bold;
    color: #32cd32; /* Green */
}

/* Button Styling */
.carrom-close-btn {
    margin-top: 15px;
    background: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}















/* Position and size for the SVG container */
.carrom-player-avatar svg,
.carrom-opponent-avatar svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg) scale(1.07); /* Ensure the timer starts at the top */
    z-index: 1;
}

/* Styling for the base circle */
.carrom-player-avatar circle.Carrom-base,
.carrom-opponent-avatar circle.Carrom-base {
    fill: none;
    stroke: #e9e9e9; /* Gray color for the base circle */
    stroke-width: 3; /* Adjust thickness if needed */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 0; /* Always fully visible */
}

/* Styling for the timer circle */
.carrom-player-avatar circle.Carrom-timerP,
.carrom-opponent-avatar circle.Carrom-timerO {
    fill: none;
    stroke-width: 4; /* Thickness of the animated stroke */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 188; /* Initially hidden (full offset) */
    
}

/* Transform and scaling when highlighted */
.carrom-player-area.highlighted .carrom-player-avatar,
.carrom-opponent-wrapper.highlighted .carrom-opponent-avatar {
    transform: scale(1.22); /* Scale up when active */
    transition: transform 0.35s ease-in-out; /* Smooth scaling */
}

.carrom-player-area .carrom-player-avatar,
.carrom-opponent-wrapper .carrom-opponent-avatar {
    transform: scale(1); /* Default size */
    transition: transform 0.25s ease-in-out; /* Smooth scaling */
}











.carrom-game-buttonsIcons {
    position: absolute;
    bottom: 105%;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .carrom-textIcon-buttonIcon,
  .carrom-settingsIcon-buttonIcon {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 8px; /* Space between buttons */
    padding: 1px;
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
    -webkit-tap-highlight-color: transparent; /* Remove iOS tap highlight */
  }
  
.carrom-textIcon-buttonIcon:focus,
.carrom-textIcon-buttonIcon:hover,
.carrom-textIcon-buttonIcon:active,
.carrom-settingsIcon-buttonIcon:focus,
.carrom-settingsIcon-buttonIcon:hover,
.carrom-settingsIcon-buttonIcon:active,
.carrom-soundToggle-buttonExt:focus,
.carrom-soundToggle-buttonExt:hover,
.carrom-soundToggle-buttonExt:active,
.carrom-leaveGame-buttonExt:focus,
.carrom-leaveGame-buttonExt:hover,
.carrom-leaveGame-buttonExt:active {
  outline: none;
  box-shadow: none;
  background: none; /* Remove any background styles */
}
  
  .carrom-textIcon-buttonIcon img,
  .carrom-settingsIcon-buttonIcon img {
    width: 25px;
    height: 25px;
  }


 /* Container for expanded buttons */
.carrom-settings-expanded {
    position: absolute;
    bottom: 0%;
    right: 40px; /* Adjust for desired spacing */
    z-index: 9;
    display: flex;
    opacity: 0;
    transform: translateX(10px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none; /* Prevent interaction when hidden */
}


.carrom-leaveGame-buttonExt,
.carrom-soundToggle-buttonExt{
background: none;
margin: 8px;
padding: 1px;
border: none;
}

/* When settings is active, show expanded buttons */
.carrom-settingsIcon-buttonIcon.active + .carrom-settings-expanded {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto; /* Enable interaction */
}




/* Icons inside the expanded buttons */
.carrom-soundToggle-buttonExt img,
.carrom-leaveGame-buttonExt img {
    width: 25px;
    height: 25px;
}


  
  .carrom-dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85); /* Black background */
    color: #fff; /* White text */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    width: 160px;
    padding: 10px;
    z-index: 20;
    backface-visibility: hidden;
    animation: fadeIn 0.2s ease-in-out;
    will-change: transform;
    transform: translateZ(0);
    
    /* Scrollable List */
    max-height: 150px; /* Adjust height based on how many items you want visible */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

/* Ensure smooth scrolling */
.carrom-dropdown-menu::-webkit-scrollbar {
    width: 6px;
}

.carrom-dropdown-menu::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.carrom-dropdown-menu::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
}

.carrom-dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.carrom-dropdown-menu li {
    padding: 10px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.2s ease-in-out;
    white-space: nowrap; /* Prevent text from wrapping */
}

.carrom-dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light highlight */
}











/* Message Popups */
.carrom-message-popup {
    position: absolute;
    z-index: 1000;
  }
  
  .carrom-message-popup-inner {
    background-color: white;
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    text-align: center;
    max-width: 100px;
   
  }
  
  /* Player Message Position */
  .carrom-player-message {
    bottom: 115%;
    left: 12%;
    transform: translateX(-50%);
    animation: carrom-massagePopupAnimation 0.4s ease-out;
  }
  
  .carrom-player-message-inner::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  /* Opponent Message Position */
  .carrom-opponent-message {
    top: 100%;
    left: 12%;
    transform: translateX(-50%);
    animation: carrom-massagePopupAnimation 0.4s ease-out;
  }
  
  .carrom-opponent-message-inner::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  

/* Animation for smooth popup appearance */
@keyframes carrom-massagePopupAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}



















  .carrom-leave-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: carrom-popupAnimation 0.4s ease-out;
  }
  

  
  .carrom-leave-game-header {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .carrom-leave-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
  }
  
  .carrom-leave-game-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .carrom-leave-game-btn {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    width: 45%;
    text-align: center;
  }
  
  .carrom-leave-game-btn:hover {
    background-color: #666;
  }
  









  /* Container for the end reason message */
.carrom-end-reason-container {
    background-color: rgba(255, 255, 255, 0.1);  /* Lighter dark background to stand out */
    padding: 10px;
    margin-top: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.3);  /* Subtle border to distinguish it */
}

/* Icon for the end reason */
.carrom-end-reason-icon {
    margin-right: 8px;
    font-size: 18px;
}

/* Reason text */
.carrom-end-reason-text {
    display: flex;
    align-items: center;
}


