

html, body {
    
    margin: 0;
    padding: 0;
    height: 100%;

  }

  .animating {
    will-change: transform;
}


  .blot-page {
    background-image: url('/Tbackground.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    max-height: 100svh;
    overflow: hidden; /* Hide any content that extends beyond this */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dealer sign for the player */
.player-section .dealer-sign {
    margin-bottom: 125px; /* Adjust position for player */
    margin-left: 300px;
    display: inline-block;
    opacity: 0.9; 
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
}

.player-section .dealer-sign img {
    width: 20px;  
    height: 20px; 
}

/* Dealer sign for the opponent */
.opponent-section .dealer-sign {
    margin-top: 120px; /* Adjust position for opponent */
    margin-left: 170px;
    display: inline-block;
    opacity: 0.9; 
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
}

.opponent-section .dealer-sign img {
    width: 20px;  
    height: 20px; 
}





.popup {
    position: fixed;
    top: 0; /* Set this to 0 to cover the full screen */
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other elements */
}

.popup-inner p {
    margin: 0;
    padding: 0;
    color: #000; /* Ensure the text color is not the same as the background */
}

.popup p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Ensure all card images have the same size */
.card img, .card-back img {
    width: 80px;
    height: 120px;
    transition: transform 0.3s;
    will-change: transform;
    display: block; /* Avoid inline space issues */
}

.Fcard img{
    width: 70px; /* Reduced width */
    height: 110px; /* Reduced height */
    transition: transform 0.3s;
    will-change: transform;
}




/* Ensure the blot page takes the full height of the viewport */
.blot-page {
    position: relative;
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between opponent, play, and player sections */
    
}




.playarea {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the play area */
    width: 100%;
    max-height: 20vh; /* Prevent it from growing too large */
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Prevent interactions with the play area */
    
}

.card.in-play {
    width: 80px;
    height: 120px;
    position: absolute; /* Ensure it positions correctly during animation */
    transform-origin: center center; /* Set the transform origin to center */
    z-index: 1; /* Ensure it layers correctly during animation */
}


.playarea .card.in-play {
    position: absolute;
    top: 50%;
    left: 50%;
    will-change: transform;
}

/* First card positioning */
.playarea .card.in-play.first-card-position {
    z-index: 0;
    transform: translate(-50%, -50%);
    will-change: transform;
}

/* Second card positioning */
.playarea .card.in-play.second-card-position {
    z-index: 1;
    transform: translate(-25%, -42%) rotate(15deg);
    will-change: transform;
}

/* Disable hover and interactions during animation */
.disable-hover {
    pointer-events: none;
}







.opponent-section {
    position: absolute; /* Keep it anchored to the top */
    top: 0;
    left: 0;
    width: 100%;
    height: 20%; /* Full opponent section height */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.player-section {
    position: absolute;
    bottom: 20px; /* Attach it to the bottom of the screen */
    left: 0;
    width: 100%; /* Full width */
    height: 22%; /* Fixed height for player section */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6000;
}

.player-hand .card.disabled {
    pointer-events: none !important;
    will-change: transform;
}

/* Highlight allowed cards */
.allowed-card {
    opacity: 1; /* Ensure full visibility */
    cursor: pointer; /* Show pointer to indicate interactivity */
    /* Add more styles as needed */
}

/* Cover not-allowed cards with a dark shadow */
.not-allowed-card {
    position: relative; /* Required for the pseudo-element */
    cursor: not-allowed; /* Indicate that this card is not clickable */
}

/* Add the dark shadow using a pseudo-element */
.not-allowed-card::before {
    content: ''; /* Required to display the pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.348); /* Adjust the color and opacity for shadow */
    z-index: 1; /* Ensure the shadow covers the card */

}





.deck-container {
    position: fixed;       /* Fix it to the viewport so it stays in place on any screen size */
    top: 50vh;             /* Center it vertically based on the viewport height */
    left: 65vw;            /* Center it horizontally based on the viewport width */
    transform: translate(-50%, -50%);  /* Shift the container back to exact center */
    display: flex;
    flex-direction: column;  /* Stack the deck and face-up card vertically */
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Style for the deck */
.initial-deck {
    position: absolute;    /* Absolute within the container */
    width: 80px;           /* Set the width for the deck */
    height: 120px;         /* Set the height for the deck */
    top: -40px;             /* Adjust this to control how far it is from the center */
    left: 60px;               /* Align with the container */
    z-index: 101;
    will-change: transform;
}

/* Style for the face-up card */
.face-up-card {
    position: absolute;    /* Absolute within the container */
    width: 80px;           /* Set the width for the face-up card */
    height: 120px;         /* Set the height for the face-up card */
    rotate: 90deg;         /* Rotate the face-up card as needed */
    top: -40px;            /* Adjust this to control spacing between deck and face-up card */
    left: 10px;           /* Adjust as needed to position correctly relative to the deck */
    z-index: 100;
    will-change: transform;
}






/* Animation for Moving Cards */
@keyframes moveToPlayArea {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(50%, 50%); /* Adjust as necessary */
    }
}

@keyframes moveToWinner {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, -100px); /* Adjust as necessary */
    }
}





.dialog-popup-inner h3 {
    margin: 5px; /* Remove any default margin */
    
}

/* Add the keyframes for the animation */
@keyframes popupSlideIn {
    0% {
        transform: scale(0.5); /* Start smaller */
        opacity: 0;            /* Fully transparent */
    }
    100% {
        transform: scale(1);   /* Scale to normal size */
        opacity: 1;            /* Fully opaque */
    }
}

/* Apply the animation to the dialog-popup-inner */
.dialog-popup-inner {
    background-color: #fff;
    border-radius: 8px;
    padding: 0px 9px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    position: relative;
    
    /* Animation properties */
    animation: popupSlideIn 0.3s ease-out forwards; /* 0.3s duration, ease-out effect */
}

/* Arrow pointing up for player popup */
.dialog-popup-inner.player::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 25%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

/* Arrow pointing down for opponent popup */
.dialog-popup-inner.opponent::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 25%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
}


/* Positioning the player popup with percentage values */
.player-popup {
    position: absolute;
    margin-top:-170px;
    z-index: 1000;
}

/* Positioning the opponent popup similarly */
.opponent-popup {
    position: absolute;
    margin-top: 80px;
    z-index: 1000;
}

/* Positioning the opponent popup similarly */
.player-popup-inOtherScreen {
    position: absolute;
    margin-top: -180px;
    z-index: 1000;
}

.PlayerTrumpSelection-popUp {
    position: absolute;
    margin-top: -280px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.85); /* Darker transparent background */
    padding: 0px 15px 20px 15px;
    border-radius: 15px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* More prominent shadow */
    text-align: center;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.95;
    z-index: 5001;
}

/* Container for suit buttons */
.PlayerTrumpSelection-popUp .button-container {
    display: flex;
    justify-content: space-between; /* Space buttons evenly */
    width: 100%;

}

/* Common styles for all buttons */
.PlayerTrumpSelection-popUp button {
    background-color: #ffffff; /* Add white background */
    border: 2px solid #ffde59;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 20px;
}

/* Icon size and hover effects */
.PlayerTrumpSelection-popUp button img {
    width: 50%;
    height: 50%;
    transition: transform 0.2s ease-in-out;
}

.PlayerTrumpSelection-popUp button:hover {
    background-color: #e2e2e2;
}

/* Hover effect for all buttons */
.PlayerTrumpSelection-popUp button:hover img {
    transform: scale(1.1); /* Slight scale-up on hover */
  
}



/* Pass button styling */
.PlayerTrumpSelection-popUp button.btn {
    background-color: #ffde59; /* Yellow background for Pass */
    color: #000;
    border: none;
    width: auto;
    padding: 0px 20px; /* Adjust padding for horizontal space */
    margin-top: 15px;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1; /* This ensures the button height is just enough for the text */
    margin: 0; /* Remove default margin */
    transition: background-color 0.3s ease-in-out;
    height: 30px; /* Control height directly to reduce space */
    display: flex;
    align-items: center; /* Align the text vertically */
    justify-content: center; /* Center the text horizontally */
}

/* Hover effect for Pass button */
.PlayerTrumpSelection-popUp button.btn:hover {
    background-color: #ffb800; /* Slightly darker yellow */
    transform: scale(1.05);
}




.trump-final-decision-popup {
    position: absolute;
    margin-top: -280px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.85); /* Darker transparent background */
    padding: 0px 15px 20px 15px;
    border-radius: 15px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* More prominent shadow */
    text-align: center;
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.95;
}

/* Display for the selected trump suit icon */
.selected-suit-icon {
    background-color: #ffffff; /* Add white background */
    border: 2px solid #ffde59;
    padding: 5px;
    margin: 20px 0 -20px 0px; /* Use margin to move it down */
    border-radius: 8px;
    width: 35px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


.selected-suit-icon img {
    width: 30px;
    height: 30px;
}


/* Pass button styling */
/* Pass button styling */
.trump-final-decision-popup button.btn {
    background-color: #ffde59; /* Yellow background for Pass */
    color: #000;
    border: none;
    width: auto;
    padding: 0px 10px;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: bold;
    margin: 20px 10px 0 10px; /* Increase the top margin to move it down */
    transition: background-color 0.3s ease-in-out;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.trump-final-decision-popup button.btn:hover {
    background-color: #ffb800; /* Slightly darker yellow */
    transform: scale(1.05);
}


.PlayerTrumpDecision-popUp {
    position: absolute;
    z-index: 1000;
    text-align: center;
    display: flex;  
    justify-content: center;
    align-items: center;
}

/* Button Styles */
.PlayerTrumpDecision-popUp button {
    background-color: #ffde59; /* Yellow background */
    color: #000;
    border: none;
    width: auto;
    padding: 8px 20px;
    margin-top: 10px;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 180px;
}

/* Take Button Specific Styles */
.PlayerTrumpDecision-popUp .take-btn {
    background-color: #32CD32; /* Green background for Take button */
    color: white;
}

.PlayerTrumpDecision-popUp .take-btn:hover {
    background-color: #28a428; /* Darker green on hover */
    transform: scale(1.05);
}

/* Pass Button Specific Styles */
.PlayerTrumpDecision-popUp .pass-btn {
    background-color: #ffde59; /* Yellow background for Pass button */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.PlayerTrumpDecision-popUp .pass-btn:hover {
    background-color: #ffb800; /* Slightly darker yellow */
    transform: scale(1.05);
}

/* Disabled Pass Button Styles */
.PlayerTrumpDecision-popUp .pass-btn:disabled {
    background-color: #ccc; /* Grey out the button */
    color: #666; /* Darker text */
    cursor: not-allowed; /* Show 'not-allowed' cursor */
    transform: none; /* No scaling on hover */
    opacity: 0.7; /* Make it look inactive */
}




/* Announce and Cancel Popup */
.PlayerAnnounceCancel-popup {
    position: absolute; /* Position relative to player-hand-container */
    bottom: 140px; /* Position it above the player's hand */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Disable pointer events for the container */
}

/* Container for buttons */
.PlayerAnnounceCancel-popup .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: auto; /* Enable pointer events for buttons */
}

/* Common Button Styles */
.PlayerAnnounceCancel-popup button {
    background-color: white; /* Add background to buttons */
    padding: 8px 15px;
    border-radius: 12px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    font-size: 1rem;
    font-weight: bold;
    color: #000; /* Black text for contrast */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: 10px;
    pointer-events: auto; /* Enable pointer events on buttons */
}

/* Specific styles for Announce Button */
.PlayerAnnounceCancel-popup .announce-btn {
    background-color: #32CD32;
    color: white;
}

.PlayerAnnounceCancel-popup .announce-btn:hover {
    background-color: #28a428;
    transform: scale(1.05);
}

/* Specific styles for Cancel Button */
.PlayerAnnounceCancel-popup .cancel-btn {
    background-color: #ffde59;
    color: black;
}

.PlayerAnnounceCancel-popup .cancel-btn:hover {
    background-color: #ffb800;
    transform: scale(1.05);
}

/* Player Dialog Popup */
.player-popupComb {
    position: absolute; /* Position relative to player-hand-container */
    top: -30px; /* Adjust top value to control how high it appears above the player's hand */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Disable pointer events for the container */
}

.player-popupComb .dialog-popup-inner {
    pointer-events: auto; /* Enable pointer events for the dialog content */
}





/* CSS for remaining card dealing animation */
@keyframes ppbet-dealCardToPlayer {
    0% {
        transform: translate(0, 0); /* Start from the deck */
    }
    100% {
        transform: translate(-50vw, 100vh); /* Move to the player's area */
    }
}

@keyframes ppbet-dealCardToOpponent {
    0% {
        transform: translate(0, 0); /* Start from the deck */
    }
    100% {
        transform: translate(-50vw, -100vh); /* Move to the opponent's area */
    }
}

/* CSS for removing the deck cards */
@keyframes ppbet-removeDeckCard {
    0% {
        opacity: 1;
        transform: translate(0, 0); /* Start at current position */
    }
    100% {
        opacity: 0;
        transform: translate(50vw, 0); /* Move off-screen to the right */
    }
}

.ppbet-card {
    position: absolute;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}


/* CSS animation to rotate and move face-up card off-screen to the right */
@keyframes ppbet-rotateAndMoveOffScreen {
    0% {
        transform: rotate(90deg) translate(0, 0); /* Initial rotation and position */
        opacity: 1;
    }
    10% {
        transform: rotate(0deg); /* Rotate back to 0 degrees */
    }
    100% {
        transform: translateX(100vw); /* Move off-screen to the right */
        opacity: 0; /* Fade out */
    }
}

.ppbet-face-up-card-animation {
    position: absolute;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

















.belote-game-buttonsIcons {
    position: absolute;
    bottom: 100%;
    right: 12px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.belote-textIcon-buttonIcon,
.belote-settingsIcon-buttonIcon {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 8px; /* Space between buttons */
    padding: 1px;
    outline: none; /* Remove outline on focus */
    box-shadow: none; /* Remove box-shadow on focus */
    -webkit-tap-highlight-color: transparent; /* Remove iOS tap highlight */
}

.belote-textIcon-buttonIcon img,
.belote-settingsIcon-buttonIcon img {
  width: 25px;
  height: 25px;
}

.belote-textIcon-buttonIcon:focus,
.belote-textIcon-buttonIcon:hover,
.belote-textIcon-buttonIcon:active,
.belote-settingsIcon-buttonIcon:focus,
.belote-settingsIcon-buttonIcon:hover,
.belote-settingsIcon-buttonIcon:active,
.belote-soundToggle-buttonExt:focus,
.belote-soundToggle-buttonExt:hover,
.belote-soundToggle-buttonExt:active,
.belote-leaveGame-buttonExt:focus,
.belote-leaveGame-buttonExt:hover,
.belote-leaveGame-buttonExt:active  {
  outline: none;
  box-shadow: none;
  background: none; /* Remove any background styles */
}

.belote-settings-expanded {
    position: absolute;
    bottom: 0%;
    right: 40px; /* Adjust for desired spacing */
    z-index: 9;
    display: flex;
    opacity: 0;
    transform: translateX(10px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none; /* Prevent interaction when hidden */
}

.belote-leaveGame-buttonExt,
.belote-soundToggle-buttonExt{
background: none;
margin: 8px;
padding: 1px;
border: none;
}

/* When settings is active, show expanded buttons */
.belote-settingsIcon-buttonIcon.active + .belote-settings-expanded {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto; /* Enable interaction */
}



.belote-leaveGame-buttonExt img,
.belote-soundToggle-buttonExt img {
    width: 25px;
    height: 25px;
}



.belote-dropdown-menu {
    position: absolute;
    bottom: 105%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    width: 150px;
    padding: 8px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 6000;
}

.belote-dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.belote-dropdown-menu li {
    color: #fff;
    padding: 8px 16px;
    cursor: pointer;
    text-align: center;
}

.belote-dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}





/* Message Popups */
.belote-message-popup {
    position: absolute;
    z-index: 1000;
  }
  
  .belote-message-popup-inner {
    background-color: white;
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
    max-width: 150px;
   
  }
  
  /* Player Message Position */
  .belote-player-message {
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    animation: belote-massagePopupAnimation 0.4s ease-out;
    z-index: 5000;
  }
  
  .belote-player-message-inner::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  /* Opponent Message Position */
  .belote-opponent-message {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    animation: belote-massagePopupAnimation 0.4s ease-out;
  }
  
  .belote-opponent-message-inner::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  

/* Animation for smooth popup appearance */
@keyframes belote-massagePopupAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}


.belote-leave-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: carrom-popupAnimation 0.4s ease-out;
  }
  

  
  .belote-leave-game-header {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .belote-leave-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
  }
  
  .belote-leave-game-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .belote-leave-game-btn {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    width: 45%;
    text-align: center;
  }
  
  .belote-leave-game-btn:hover {
    background-color: #666;
  }
  





   /* Container for the end reason message */
.belote-end-reason-container {
    background-color: rgba(255, 255, 255, 0.1);  /* Lighter dark background to stand out */
    padding: 10px;
    margin-top: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.3);  /* Subtle border to distinguish it */
}

/* Icon for the end reason */
.belote-end-reason-icon {
    margin-right: 8px;
    font-size: 18px;
}

/* Reason text */
.belote-end-reason-text {
    display: flex;
    align-items: center;
}


