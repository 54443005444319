.transaction-history-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

body {
    background-color: #121212; /* Ensure the body also has a black background */
}

html {
    height: 100%; /* Ensure the html element stretches full height */
}

.history-page-title{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center; /* Center-aligns the title */

}

.filter-container {
    margin-bottom: 24px;
    margin-top: 24px;
}
.filter-container select {
    background-color: #121212; /* Black background */
    color: #fff; /* White text */
    border: 1px solid #555; /* Optional: add a border for better visibility */
    padding: 5px;
    border-radius: 4px;
}

.transaction-group{
    margin-bottom: 30px;
}

.transaction-fullList{
    margin-bottom: 80px;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #333;
    cursor: pointer;
}

.transaction-date{
    font-size: 14px;
    color: #ffffff;
}

.transaction-info {
    display: flex;
    flex-direction: column;
}

.transaction-amount {
    font-size: 18px;
}

.transaction-type {
    font-size: 14px;
    color: #888;
}

.transaction-status {
    font-size: 12px;
    color: #888; /* Keep the existing color for the status label "Status:" */
}

.status-name {
    margin-left: 8px; /* Add some space between the word "Status:" and the status name */
}

.status-name.completed {
    color: #13C513; /* Green for completed */
}

.status-name.pending {
    color: #FFD700; /* Yellow for pending */
}

.status-name.failed {
    color: #e74c3c; /* Red for failed */
}




.transaction-arrow {
    width: 20px;
    height: 20px;
    filter: invert(100%); /* Make the arrow white */
}
/* Overlay styling to cover the whole screen */
/* Existing CSS remains unchanged */

.transaction-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

/* Main popup styling */
.transaction-popup {
    background-color: #282828; /* Match the app's dark theme */
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    text-align: center;
    position: relative;
    max-height: 90vh; /* Ensure popup doesn't overflow */
    overflow-y: auto; /* Allow scrolling if content exceeds the height */
    word-wrap: break-word; /* Force long words to wrap */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Separator line between title and content */
.popup-separator {
    border: 0;
    height: 1px;
    background-color: #555; /* Grey line */
    margin: 10px 0;
}

/* Unique Popup header */
.transaction-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items vertically in the center */
    margin-bottom: 10px;
}

/* Unique Popup title */
.transaction-popup-title {
    color: white; /* Make the title white */
    margin: 0;
    font-size: 18px;
    text-align: left; /* Keep the title aligned to the left */
}

/* Row labels (field names) */
.row-label {
    color: #9E9E9E; /* Grey color for labels */
    font-weight: bold;
}

/* Row data (actual transaction values) */
.row-data {
    color: white; /* White color for data */
}

/* Status-specific colors */
.status-completed {
    color: #13C513; /* Green for completed */
}

.status-pending {
    color: #FFD700; /* Yellow for pending */
}

.status-failed {
    color: #e74c3c; /* Red for failed */
}

.empty-message{
    color: #9E9E9E;
}

/* Unique close button for this transaction popup */
.transaction-close-btn {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: 0px; /* Moves it to the right */
    top:10px;
}

.transaction-close-btn:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
}

/* Ensure long content wraps */
.transaction-popup p {
    word-wrap: break-word; /* Ensure long words wrap */
    white-space: normal; /* Breaks long text into new lines */
}


/* Ensure the loading message has the same black background and white text */
.loading-message {
    background-color: #121212; /* Black background */
    color: #fff; /* White text */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center; /* Center horizontally */
    padding-top: 80px; /* Add space from the top */
    font-size: 18px;
}



