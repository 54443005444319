/* Admin Dashboard Styles */
.admin-dashboard-container {
    padding: 20px;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

.dashboard-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #FFD700; /* Gold highlight */
}

.date-range-picker {
    text-align: center;
    margin-bottom: 40px;
}

.date-picker-group {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.date-picker {
    background-color: #1E1E1E;
    color: white;
    border: 1px solid #FFD700;
    padding: 8px;
    border-radius: 5px;
    font-size: 16px;
    max-width: 100%;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 85px;
}

.view-all-button {
    position: absolute; /* Allow precise positioning */
    bottom: 10px; /* Adjust bottom positioning */
    right: 10px; /* Place at the bottom-right corner */
    padding: 8px 16px;
    background-color: #FFD700; /* Gold color for the button */
    color: #121212; /* Dark text for contrast */
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.view-all-button:hover {
    background-color: #e5c100; /* Slightly darker gold on hover */
    transform: scale(1.05); /* Slight enlargement on hover */
}

.view-all-button:active {
    transform: scale(0.95); /* Slight shrink on click */
}

.dashboard-card {
    position: relative; /* Ensures the button can be positioned within the card */
    padding: 20px;
    background-color: #1E1E1E;
    border: 1px solid #555555;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.dashboard-card h2 {
    color: #FFD700; /* Gold for card titles */
    margin-bottom: 15px;
}

.dashboard-card p,
.dashboard-card ul {
    color: #FFFFFF;
    font-size: 16px;
    margin: 0;
}

.dashboard-card ul {
    list-style-type: none;
    padding: 0;
}

.dashboard-card ul li {
    background-color: #252525;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
}

.highlightQ {
    font-weight: bold;
    color: red;
}



