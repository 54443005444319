.played-games-history-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.played-games-history-page-titleO {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 45px;
    color: #FFFFFF;
    text-align: center;
}

.played-games-history-group {
    margin-bottom: 30px;
}

.played-games-history-fullList {
    margin-bottom: 80px;
}

.played-games-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #333;
    cursor: pointer;
}

.played-games-history-date {
    font-size: 14px;
    color: #ffffff;
}

.played-games-history-info {
    display: flex;
    flex-direction: column;
}

.played-games-history-nameO {
    font-size: 16px;
    font-weight: normal;
}

.played-games-history-bet-result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.played-games-history-bet {
    font-size: 14px;
    color: #888;
}

.played-games-history-result {
    font-size: 14px;
    margin-left: 10px;
}

.played-games-history-result.win {
    color: #13C513; /* Green for win */
}

.played-games-history-result.lose {
    color: #E74C3C; /* Red for lose */
}

.played-games-history-arrow {
    width: 20px;
    height: 20px;
    filter: invert(100%); /* Make the arrow white */
}

.played-games-history-empty-message {
    text-align: center;
    color: grey;
}

.played-games-history-container-loading-message {
    background-color: #121212;
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    font-size: 18px;
}

.played-games-history-container-error-message {
    text-align: center;
    color: #E74C3C;
}


/* Container styling */
.played-games-history-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

/* Page title styling */
.played-games-history-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center;
}

/* Grouping items by date */
.played-games-history-group {
    margin-bottom: 30px;
}

/* Game item styling */
.played-games-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #333;
    cursor: pointer;
}

/* Game date styling */
.played-games-history-date {
    font-size: 14px;
    color: #ffffff;
}

/* Game information styling */
.played-games-history-info {
    display: flex;
    flex-direction: column;
}

/* Game name */
.played-games-history-name {
    font-size: 18px;
}

/* Bet and result information */
.played-games-history-bet-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.played-games-history-bet {
    font-size: 14px;
    color: #888;
}

/* Result styling (win/lose) */
.played-games-history-result.win {
    color: #13C513; /* Green for win */
}

.played-games-history-result.lose {
    color: #E74C3C; /* Red for lose */
}

/* Arrow icon */
.played-games-history-arrow {
    width: 20px;
    height: 20px;
    filter: invert(100%);
}

/* Popup overlay */
.played-games-history-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
}

/* Popup styling */
.played-games-history-popup {
    background-color: #282828;
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    text-align: center;
    position: relative;
    max-height: 90vh;
    overflow-y: auto; /* Allow scrolling if content exceeds the height */
    
}

/* Popup title */
.played-games-history-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.played-games-history-popup-title {
    color: white;
    margin: 0;
    font-size: 18px;
    text-align: left;
}

/* Close button */
.played-games-history-close-btn {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: 0px;
    top: 10px;
}

.played-games-history-close-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Popup separator */
.popup-separator {
    border: 0;
    height: 1px;
    background-color: #555;
    margin: 10px 0;
}

.opponent-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long names with dots */
    max-width: 100px; /* Set a limit on the name's width */

   
}

.game-pointsResult {
    font-size: 16px;
}

/* Row label and data */
.row-label {
    color: #9E9E9E;
    font-weight: bold;
}

.row-data {
    color: white;
}

/* Styling for win/lose status inside the popup */
.status-win {
    color: #13C513; /* Green for win */
}

.status-lose {
    color: #E74C3C; /* Red for lose */
}

/* Loading message styling */
.played-games-history-container-loading-message {
    background-color: #121212;
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    font-size: 18px;
}


