.friends-page-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    position: relative ;  /* For fixed buttons */

}

.claimFriends-reward-popup-overlay {
    position: absolute;
    top: 0; /* Center vertically */
    left: 0; /* Center horizontally */
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
}


.claimFriends-reward-popup {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
    max-width: 90%; 
    max-height: 90%; 
    overflow: auto; 
}

.claimFriendspopup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.claimFriendspopup-message {
    font-size: 18px;
    margin-bottom: 20px;
}

/* Unique class for the close button */
.claimFriends-popup-close-button {
    background-color: #FFD700;
    color: black;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.claimFriends-popup-close-button:hover {
    background-color: #dec000;
}



.friends-page-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Claim Rewards Section */
.claim-rewards-section {
    text-align: center;
    margin-bottom: 20px;
}

.claim-amount {
    font-size: 36px;
    font-weight: bold;
    color: #FFFFFF;
}
.claim-amount-decimal {
    font-size: 28px; /* Smaller font size for the decimal part */
    font-weight: normal;
    color: #FFFFFF;
    
}

.currency-symbol {
    color: #A9A9A9;
    margin-right: 5px;
    font-size: 34px;
}

.currency-symbol-on-row {
    color: #A9A9A9;
    margin-right: 5px;
 
}


.claim-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 40px;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
    margin-bottom: 0px;
}

.claim-button:hover {
    background-color: #dec000;
}

.claim-button.disabled {
    background-color: #999;
    opacity: 0.7;
}



/* Explanation Text */
.explanation-text {
    text-align: center;
    font-size: 13px;
    color: #ccc;
    margin-bottom: 40px;
}

/* Friends Section */
.friends-count-title {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
}

.friends-list {
    margin-bottom: 150px; /* Space before fixed buttons */
}

.friend-item {
    display: flex;
    align-items: center; /* Vertically align avatar and name */
    justify-content: space-between; /* Ensure the name and amount are spaced correctly */
    padding: 6px 15px;
    margin-bottom: 10px;
    background-color: #282828;
    border-radius: 12px;
    
    
}

.friend-info {
    display: flex; /* Ensure avatar and name are side-by-side */
    align-items: center; /* Vertically align avatar and name */
}

.friend-icon img, .friend-initial {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.friends-page-friend-name {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 15px; /* Space between avatar and name */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate long names with dots */
    max-width: 120px; /* Set a limit on the name's width */
}


.friend-initial {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #555;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.friend-earnings {
    font-size: 18px;
    font-weight: bold;
    color: #FFD700;
}

/* No friends message */
.no-friends-message {
    text-align: center;
    color: grey;
}


.copy-popup {
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 90%;
    width: 400px;
}

.manual-copy-link {
    color: #FFD700;
    margin-top: 10px;
    font-size: 14px;
    word-wrap: break-word; /* In case the link is long, it breaks into the next line */
}

.copy-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copy-popup-title {
    color: #A9A9A9; /* Grey color for the title */
    font-size: 16px;
    margin: 0;
    margin-top: -15px;
}

.copy-popup-close-btn {
    background-color: transparent;
    border: none;
    color: #A9A9A9; /* Grey color for the close button */
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    line-height: 1;
    margin-top: -15px;

}

.copy-popup-close-btn:hover {
     background-color: rgba(184, 184, 184, 0.2); /* Subtle hover effect */
}




.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
}

.confetti-piece {
    width: 10px;
    height: 20px;
    position: absolute;
    background-color: hsl(0, 100%, 50%);
    animation: explode 1.1s ease-in-out forwards; /* Reduced animation duration for faster effect */
    z-index: 9999;
}

@keyframes explode {
    to {
        transform: translate(var(--randomX), var(--randomY)) rotate(1080deg); /* Full-screen movement */
        opacity: 0; /* Fade out */
    }
}

