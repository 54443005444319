/* Admin Login Page Styles */
.admin-login-container {
    padding: 20px;
    margin: 0 auto;
    background-color: #121212; /* Dark background */
    color: #FFFFFF; /* Light text for contrast */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-login-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #FFD700; /* Gold for admin-specific highlight */
}

.admin-login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.admin-form-group {
    margin-bottom: 20px;
}

.admin-label {
    font-size: 16px;
    margin-bottom: 8px;
    color: #FFFFFF; /* Ensure text is visible */
}

.admin-input-field {
    width: 100%;
    font-size: 16px;
    padding: 12px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-bottom: 10px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.admin-input-field:focus {
    border-color: #FFD700; /* Gold highlight on focus */
    box-shadow: 0 0 5px #FFD700;
    outline: none;
}

/* Button styles */
.admin-login-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 12px 60px;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.admin-login-button:hover {
    background-color: #E6C300;
}

.admin-login-disabled {
    background-color: #555555;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Error message styling */
.admin-error-message {
    color: #FF4D4D;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

/* Centering the login container */
.admin-login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Adjust loading spinner */
.admin-spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #FFD700; /* Gold spinner */
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Responsive design */
@media (max-width: 768px) {
    .admin-login-container {
        padding: 10px;
    }

    .admin-login-title {
        font-size: 24px;
    }

    .admin-input-field {
        font-size: 14px;
    }

    .admin-login-button {
        font-size: 14px;
        padding: 10px 40px;
    }
}
