.backgammon-container {
    background: linear-gradient(180deg, #1a1a1a, #283c86);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow: hidden;
    touch-action: none;
}

/* Opponent Wrapper */
.backgammon-opponent-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Opponent Area */
.backgammon-opponent-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 14px;
    width: 100%;
    max-width: 500px;
    background: linear-gradient(180deg, #3b4c6b 0%, #1d2b4f 77.5%);
    border: 2px solid #8c5e01;
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box;
}

/* Opponent Avatar */
.backgammon-opponent-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px;
    font-weight: bold;
    color: #464646;
    text-transform: uppercase;
    overflow: hidden;
}

/* Opponent Info */
.backgammon-opponent-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
}

/* Opponent Name and Flag */
.backgammon-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.backgammon-opponent-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.backgammon-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Opponent Score */
.backgammon-opponent-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Opponent Checkers Borne Off */
.backgammon-opponent-checkers {
    font-size: 14px;
    color: #4caf50;
}


























.backgammon-board-wrapper {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.backgammon-board-container {
    position: relative; /* This allows us to position points inside the container */
    width: 340px; /* Board container width */
    height: 400px; /* Board container height */
    background: url('/backgammonAssets/board.png') no-repeat center center;
    background-size: cover;
    box-shadow: 0 0 14px 10px rgba(0,0,0,0.4);
    border-radius: 5px;
    z-index: 1;
}

.backgammon-point {
    position: absolute; /* Points are positioned inside the board container */
    width: 26px;  /* Width for each point */
    height: 122.5px;  /* Height for each point */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
}



/* Updated Checker Styling with PNG images */
.backgammon-checker {
    width: 24.5px;  /* Resize to fit inside the point */
    height: 24.5px;
    position: absolute;
    bottom: 0;  /* Start at the bottom of the point */
    z-index: 1;  /* Stack the checkers */
    transition: top 0.3s ease, bottom 0.3s ease;
}

/* White Checker */
.backgammon-checker.white {
    background: url('/backgammonAssets/checker-white.png') no-repeat center center;
    background-size: contain;
}

/* Black Checker */
.backgammon-checker.black {
    background: url('/backgammonAssets/checker-black.png') no-repeat center center;
    background-size: contain;
}

/* Adjust for stacked checkers: */
.backgammon-checker.black.stacked {
    z-index: 1005; 
}

/* Adjust for stacked checkers: */
.backgammon-checker.white.stacked {
    z-index: 1005; 
}


.backgammon-dice-container {
    position: absolute;
    bottom: 184px;
    display: flex;
    gap: 10px;
}

.player-dice {
    left: 64.2%; /* Right half of the board for the current player */
}

.opponent-dice {
    left: 13.7%; /* Left half of the board for the opponent */
}

.backgammon-dice1,
.backgammon-dice2 {
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    z-index: 1;
}

.backgammon-OpponentDice1,
.backgammon-OpponentDice2 {
    width: 32px;
    height: 32px;
    left: 20%;
    transform: translateX(-20%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.backgammon-roll-button {
    position: absolute;
    bottom: 178px;
    cursor: pointer;
}

.backgammon-bear-off {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.game-end-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 18px;
}



















/* Player Wrapper */
.backgammon-player-wrapper {
    position: absolute;
    bottom: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Player Area */
.backgammon-player-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 14px;
    width: 100%;
    max-width: 500px;
    background: linear-gradient(180deg, #3b4c6b 0%, #1d2b4f 77.5%);
    border: 2px solid #8c5e01;
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box;
}

/* Player Avatar */
.backgammon-player-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px;
    font-weight: bold;
    color: #464646;
    text-transform: uppercase;
    overflow: hidden;
}

.backgammon-avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

/* Player Info */
.backgammon-player-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Player Name and Flag */
.backgammon-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.backgammon-player-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.backgammon-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.backgammon-player-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Player Checkers Off */
.backgammon-player-checkers {
    font-size: 14px;
    color: #4caf50;
}
















/* Apply transform animation for the dice movement */
.shift-die {
    animation: moveDice 0.4s ease forwards;
}

/* Define the movement animation */
@keyframes moveDice {
    to {
        transform: translate(var(--shift-x), var(--shift-y)); /* Use the custom properties set in JS */
    }
}












/* Position and size for the SVG container */
.backgammon-player-avatar svg,
.backgammon-opponent-avatar svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg) scale(1.07); /* Ensure the timer starts at the top */
    z-index: 1;
}

/* Styling for the base circle */
.backgammon-player-avatar circle.backgammon-base,
.backgammon-opponent-avatar circle.backgammon-base {
    fill: none;
    stroke: #e9e9e9; /* Gray color for the base circle */
    stroke-width: 3; /* Adjust thickness if needed */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 0; /* Always fully visible */
}

/* Styling for the timer circle */
.backgammon-player-avatar circle.backgammon-timerP,
.backgammon-opponent-avatar circle.backgammon-timerO {
    fill: none;
    stroke-width: 4; /* Thickness of the animated stroke */
    stroke-dasharray: 188; /* Total length of the circle */
    stroke-dashoffset: 188; /* Initially hidden (full offset) */
    stroke: url(#gradientStroke); /* Apply the gradient */
}

/* Transform and scaling when highlighted */
.backgammon-player-area.highlighted-turn .backgammon-player-avatar,
.backgammon-opponent-wrapper.highlighted-turn .backgammon-opponent-avatar {
    transform: scale(1.22); /* Scale up when active */
    transition: transform 0.35s ease-in-out; /* Smooth scaling */
}

.backgammon-player-area .backgammon-player-avatar,
.backgammon-opponent-wrapper .backgammon-opponent-avatar {
    transform: scale(1); /* Default size */
    transition: transform 0.25s ease-in-out; /* Smooth scaling */
}










/* Control Buttons */
.backgammon-game-buttonsIcons {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 10;
    flex-direction: column;
    align-items: flex-end;
    display: contents;
}

.backgammon-textIcon-buttonIcon,
.backgammon-settingsIcon-buttonIcon {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 8px;
    padding: 1px;
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
}

.backgammon-textIcon-buttonIcon img,
.backgammon-settingsIcon-buttonIcon img {
    width: 25px;
    height: 25px;
}

/* Icons inside the expanded buttons */
.backgammon-soundToggle-buttonExt img,
.backgammon-leaveGame-buttonExt img {
    width: 25px;
    height: 25px;
}

/* Expanded Settings Buttons */
.backgammon-settings-expanded {
    position: absolute;
    display: grid;
    bottom: 70%;
    right: 17px;
    z-index: 9;
    opacity: 0;
    transform: translateX(10px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    pointer-events: none;
}

.backgammon-settingsIcon-buttonIcon.active + .backgammon-settings-expanded {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
}

.backgammon-leaveGame-buttonExt,
.backgammon-soundToggle-buttonExt{
background: none;
margin: 3px;
padding: 1px;
border: none;
}




/* Dropdown Menu */
.backgammon-dropdown-menu {
    position: absolute;
    margin-bottom: 385px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    width: 160px;
    padding: 10px;
    z-index: 20;
}

.backgammon-dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.backgammon-dropdown-menu li {
    padding: 10px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.2s ease-in-out;
}

.backgammon-dropdown-menu li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Message Popups */
.backgammon-message-popup {
    position: absolute;
    z-index: 1000;
}

.backgammon-message-popup-inner {
    background-color: white;
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    color: #333;
    text-align: center;
    max-width: 100px;
}

  /* Player Message Position */
  .backgammon-player-message {
    bottom: 115%;
    left: 12%;
    transform: translateX(-50%);
    animation: backgammon-massagePopupAnimation 0.4s ease-out;
  }

  .backgammon-player-message-inner::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  /* Opponent Message Position */
  .backgammon-opponent-message {
    top: 100%;
    left: 12%;
    transform: translateX(-50%);
    animation: backgammon-massagePopupAnimation 0.4s ease-out;
  }
  
  .backgammon-opponent-message-inner::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  

/* Animation for smooth popup appearance */
@keyframes backgammon-massagePopupAnimation {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translateX(-50%) scale(1);
    }
}


/* Leave Game Popup */
.backgammon-leave-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
}

.backgammon-leave-game-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.backgammon-leave-game-btn {
    background-color: #444;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    width: 45%;
}
















/* Unique class for the RPS end game popup */
.backgammon-end-game-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 3001;
    width: 280px;
    max-height: 80%;
    animation: backgammon-popupAnimation 0.4s ease-out;
}

/* Animation for smooth popup appearance */
@keyframes backgammon-popupAnimation {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Header styles for the end game popup */
.backgammon-end-game-header {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.backgammon-end-game-header h3 {
    margin: 0;
    padding: 5px 0;
    font-size: 1.2rem;
}

/* Win/Lose styling */
.backgammon-end-game-header h3.backgammon-win {
    color: #32cd32; /* Green for win */
}

.backgammon-end-game-header h3.backgammon-lose {
    color: #e74c3c; /* Red for loss */
}

.backgammon-end-game-results-table {
    margin-top: 15px;
    text-align: center;
}

.backgammon-end-game-results-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
}

.backgammon-end-game-score {
    font-size: 1.5rem;
    font-weight: bold;
}

.backgammon-end-game-colon {
    padding: 0 10px;
    color: #fff;
}

.backgammon-end-game-winner-score {
    color: #ffffff; 
}

.backgammon-end-game-loser-score {
    color: #a9a9a9; 
}

/* General row styling */
.backgammon-results-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}

/* Left Column */
.backgammon-results-left {
    display: flex;
    align-items: center;
    width: 150px;
}

/* Icon styling */
.backgammon-score-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.backgammon-score-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.backgammon-current-score {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
}

.backgammon-score-result {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

.backgammon-score-positive {
    color: #ffffff;
}

.backgammon-score-negative {
    color: #ffffff;
}

/* Bet Amount */
.backgammon-bet-amount {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5px;
}

/* Winning Amount */
.backgammon-winning-row .backgammon-winning-amount {
    font-size: 1.4rem;
    font-weight: bold;
    color: #32cd32; /* Green */
}













.backgammon-textIcon-buttonIcon:focus,
.backgammon-textIcon-buttonIcon:hover,
.backgammon-textIcon-buttonIcon:active,
.backgammon-settingsIcon-buttonIcon:focus,
.backgammon-settingsIcon-buttonIcon:hover,
.backgammon-settingsIcon-buttonIcon:active,
.backgammon-soundToggle-buttonExt:focus,
.backgammon-soundToggle-buttonExt:hover,
.backgammon-soundToggle-buttonExt:active,
.backgammon-leaveGame-buttonExt:focus,
.backgammon-leaveGame-buttonExt:hover,
.backgammon-leaveGame-buttonExt:active {
  outline: none;
  box-shadow: none;
  background: none; /* Remove any background styles */
}




