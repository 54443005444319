.affiliate-program-container-unique {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.affiliate-program-title-unique {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.affiliate-program-description-unique {
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    color: #A9A9A9;
}

.affiliate-program-form-unique {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiliate-form-group-unique {
    margin-bottom: 20px;
    width: 100%;
}

.affiliate-input-field-unique {
    width: calc(100% - 25px); 
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    margin-bottom: 10px;
    font-size: 16px;
}

.affiliate-textarea-field-unique {
    height: 80px;
    resize: none;
}

.affiliate-input-field-unique:focus {
    border-color: #eaeaea;
    box-shadow: 0 0 2px rgb(255, 255, 255);
    outline: none;
}

.affiliate-submit-button-unique {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 320px;
}




.affiliate-submit-button-unique:hover {
    background-color: #dec000;
}

.affiliate-button-disabled-unique {
    background-color: #dec000;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Loading Spinner */
.affiliate-spinner-unique {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.affiliate-success-message-unique {
    color: #13C513;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}



input, textarea {
    outline: none;
}
input:focus, textarea:focus {
    scroll-margin-top: 300px; 
}

#miniAppContainer, #scrollableContent {
    touch-action: manipulation; /* Prevent unintended touch scrolling behavior */
}

textarea {
    resize: none; /* Prevent resizing, which can mess with the layout */
}

#scrollableContent {
    padding-bottom: 300px; /* Extra space to account for the keyboard */
}