.completed-games-container {
    padding: 20px;
    background-color: #121212;
    color: #FFFFFF;
}

.completed-games-container h1 {
    text-align: center;
    color: #FFD700;
    margin-bottom: 20px;
}

/* Responsive Table Container */
.table-container {
    overflow-x: auto;
    margin-bottom: 20px;
}

.games-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    min-width: 600px; /* Ensure table doesn't shrink too small */
}

.games-table th,
.games-table td {
    border: 1px solid #555555;
    padding: 10px;
    text-align: center;
    white-space: nowrap; /* Prevent text wrapping for better readability */
}

.games-table th {
    background-color: #1E1E1E;
    color: #FFD700;
    font-size: 14px;
}

.games-table tr:nth-child(even) {
    background-color: #252525;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.pagination button {
    padding: 10px 20px;
    background-color: #FFD700;
    border: none;
    color: #121212;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.pagination button:disabled {
    background-color: #555555;
    cursor: not-allowed;
}

/* Date Range Picker Styling */
.date-range-picker {
    display: flex;
    flex-wrap: wrap; /* Wrap items on small screens */
    justify-content: center;
    gap: 20px; /* Space between items */
    margin-bottom: 20px;
}

.date-picker-group {
    display: flex;
    flex-direction: column; /* Stack items vertically for smaller screens */
    align-items: center;
    gap: 10px;
}

.date-picker {
    background-color: #1E1E1E;
    color: white;
    border: 1px solid #FFD700;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%; /* Take full width in column layout */
    max-width: 200px;
}

.fetch-games-button {
    padding: 8px 20px;
    background-color: #FFD700;
    border: none;
    color: #121212;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.fetch-games-button:hover {
    background-color: #FFC107;
}

/* Search Bar Styling */
.search-bar {
    text-align: center;
    margin-bottom: 20px;
    
}

.search-input {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    border: 1px solid #FFD700;
    background-color: #1E1E1E;
    color: white;
    font-size: 14px;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .date-range-picker {
        flex-direction: column; /* Stack filters vertically */
        align-items: center;
    }

    .table-container {
        overflow-x: auto;
    }

    .pagination button {
        font-size: 12px;
        padding: 8px 16px;
        margin-bottom: 75px;
    }

    .search-bar input {
        max-width: 100%; /* Take up full width */
        font-size: 16px; /* Slightly smaller font for compact view */
    }

    .search-bar {
        flex-direction: column; /* Stack elements vertically */
        gap: 10px; /* Add space between elements */
        max-width: 80vw;
    }


}

.downloadLog-button {
    padding: 5px 10px;
    background-color: #ccd7ff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #121212;
    font-weight: bold;
}

.downloadLog-button:hover {
    background-color: #8fa6fa;
}