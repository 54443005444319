.users-page-container {
    padding: 20px;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    overflow-x: auto;
}

.users-page-container h1 {
    text-align: center;
    color: #FFD700;
}


.users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.users-table th,
.users-table td {
    border: 1px solid #555555;
    padding: 10px;
    text-align: center;
}

.users-table th {
    background-color: #1E1E1E;
    color: #FFD700;
}

.users-table tr:nth-child(even) {
    background-color: #252525;
}

.transactions-button {
    padding: 5px 10px;
    background-color: #ccd7ff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #121212;
    font-weight: bold;
}

.transactions-button:hover {
    background-color: #8fa6fa;
}



/* Min Balance Filter Styling */
.min-balance-filter {
    text-align: center;
    margin-bottom: 20px;
}

.min-balance-input {
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border-radius: 5px;
    border: 1px solid #FFD700;
    background-color: #1E1E1E;
    color: white;
    font-size: 14px;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .min-balance-filter input {
        max-width: 100%; /* Take up full width */
        font-size: 16px; /* Slightly smaller font for compact view */
    }

    .min-balance-filter {
        flex-direction: column;
        gap: 10px; /* Space between filter and search */
        max-width: 80vw;
    }
}

.export-buttonUsers {
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #FFD700;
    color: #1E1E1E;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.export-buttonUsers:hover {
    background-color: #E6BE00;
}
