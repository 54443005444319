.withdraw-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}

.withdraw-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #FFFFFF;
    text-align: center; /* Center-aligns the title */
}

.step-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 100;
    margin-top: 40px;
    margin-bottom: 15px;
    text-align: left;
}



.address-input-container {
 
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;   
    
}

.address-input {
    width: calc(100% - 25px); 
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #555555; /* Adds a dark border to the text box */
    background-color: #1E1E1E; /* Dark background for the text box */
    color: white; /* White text to ensure readability */
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 16px; /* Prevents zooming on mobile */
}

.address-input:focus {
    border-color: #dfdfdf; /* Same as the regular border color */
    outline: none; /* Remove the blue outline */
    box-shadow: 0 0 2px rgb(255, 255, 255);
}


.invalid {
    border-color: #ff4d4d;
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.paste-button, .qr-scan-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2px;
    margin-bottom: 10px;
}

.paste-button {
    font-size: 18px;
    color: #FFD700;
}

.paste-button:hover {
    background-color: #121212;
}

.qr-scan-button {
    margin-left: 10px;
}


.qr-scan-button:hover {
    background-color: #121212;
}

.qr-scan-icon {
    width: 18px;
    height: 18px;
}

.qr-scanner-title {
    font-size: 20px;
    color: white;
    text-align: center;
    margin-bottom: 8px;
}

.qr-scanner-subtitle {
    font-size: 14px;
    color: lightgray;
    text-align: center;
    margin-bottom: 16px;
}


.continue-button, .withdraw-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto; /* This centers the button */
    margin-bottom: 80px;
}

.continue-button:hover{
    background-color: #dec000;
}

.withdraw-button:hover{
    background-color: #dec000;
}

.error-message {
    color: #ff4d4d;
    font-size: 14px;
    margin-top: 10px; /* This adds space between the button and the message */
    margin-bottom: 15px;
    text-align: center; /* Align the message in the center */
}

.success-message {
    color: #28a745; /* Green color for success */
    font-size: 14px;
    margin-top: 10px; /* This adds space between the button and the message */
    margin-bottom: 15px;
    text-align: center; /* Align the message in the center */
}
    

.info-section {
    margin-top: 20px;
    font-size: 12px;
    color: grey;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 10px;
}

.warning-text {
    color: grey;
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: -8px;
}


.qr-scanner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Dim background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.qr-scanner-popup-unique {
    background-color: #121212;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    width: 90%;
    position: relative;
}

.qr-scanner-frame {
    border: 2px solid #FFFFFF;
    padding: 5px; /* Reduce padding slightly */
    border-radius: 10px;
    width: 80%; /* Reduce width for smaller frame */
    max-width: 250px; /* Decrease max width to make it smaller */
    margin: 0 auto 15px; /* Center the frame and add bottom margin */
}


.unique-close-button {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle grey background */
    color: #9E9E9E;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 24px;
    cursor: pointer;
    margin-top: 15px;   /* Adds space below the video */
    align-self: center;
}

.unique-close-button:hover{
    background-color: rgba(184, 184, 184, 0.2); /* Subtle hover effect */
}

.address-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.address-popup {
    background-color: #282828;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    width: 90%;
    max-width: 400px;
    
}

.address-popup h4 {
    font-size: 16px; /* You can set this to any size you prefer */
    word-break: break-all; /* Ensures long addresses break to the next line */
    margin-top: 40px;
}

.disconnect-button {
    background-color: #FFD700;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.disconnect-button:hover{
    background-color: #dec000;
}

.disconnect-close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #9E9E9E;
}

.disconnect-close-popup:hover{
    background: none;
}

.attached-address-container {
    margin-bottom: 10px;
   }

.arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
}

.attached-address-titul{
    font-size: 16px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 100;
    margin-top: 40px;
    margin-bottom: 15px;
    text-align: left;
}

.attached-address {
    display: flex;
}

.attached-address-text {
    font-size: 12px;
    color: grey;
    flex-grow: 1;  /* Ensures the text takes all available space */
    word-wrap: break-word;  /* Prevents long addresses from overflowing */
    margin-top: -10px;
}

.arrow-icon {
    font-size: 18px;
    color: #FFD700;  /* Yellow color for better visibility */
    margin-top: -20px;
}

.amount-title{
    font-size: 16px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 100;
    margin-top: 15px;
    margin-bottom: 8px;
    text-align: left;
}


.amount-input-container {
    position: relative;
    width: 100%;
    margin-bottom: 40px;
}

.amount-input {
    width: 100%;
    padding: 10px;
    padding-right: 20px; /* Add padding to the right for the button space */
    border-radius: 5px;
    border: 2px solid #555555;
    background-color: #1E1E1E;
    color: white;
    box-sizing: border-box;
    font-size: 16px;
}

.amount-input:focus {
    border-color: #dfdfdf; /* Same as the regular border color */
    outline: none; /* Remove the blue outline */
    box-shadow: 0 0 2px rgb(255, 255, 255);
}

/* For Chrome, Safari, Edge, and Opera */
.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.max-button {
    position: absolute;
    right: 0px; /* Align button inside input on the right */
    top: 50%;
    transform: translateY(-50%);
    background-color: #1E1E1E;
    color: rgb(158, 158, 158);
    border: none;
    padding: 2px 10px;
    border-radius: 5px;
    margin-top: 0px;
    cursor: pointer;
}

.max-button:hover{
    background-color: #555555;
}


/* Styling for the fee and withdrawal information */
.fee-info {
    margin-top: -40px;
    margin-bottom: 20px;
    padding: 4px 0px;
    background-color: #1e1e1e; /* Dark background to match theme */
    border-radius: 5px;
    color: rgb(255, 255, 255); /* Ensure readability on the dark background */
}

.fee-details {
    font-size: 12px;
    margin: 5px 0; /* Adjusts spacing between the fee and withdrawal amount */
}

.withdrawal-warning {
    margin-bottom: 15px;
    color: #ff4d4d;
    font-size: 12px;

}

.withdraw-disabled {
    opacity: 0.5; /* Dim the button when disabled */
    cursor: not-allowed;
}


.spinner {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #ff4d4d;
    width: 12px;
    height: 12px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-right: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



