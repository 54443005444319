.tasks-page-container {
    padding: 0 20px 20px;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
    text-align: center;
}

.tasks-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
.tasks-available-title{
    text-align: left;
    font-size: 18px;
    margin-top: 40px;
}

.tasks-page-description {
    font-size: 14px;
    color: #A9A9A9;
    margin-bottom: 20px;
}

.tasks-list {
    margin-bottom: 100px;
}

.task-item {
    display: flex;
    align-items: center; /* Vertically align avatar and name */
    justify-content: space-between; /* Ensure the name and amount are spaced correctly */
    padding: 6px 15px;
    margin-bottom: 10px;
    background-color: #282828;
    border-radius: 12px;
}

.task-info {
    text-align: left;
}

.task-name {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.task-status {
    font-size: 12px;
    color: #FFD700;
}

.task-claim-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 8px 20px;
    border-radius: 12px;
    cursor: pointer;
}

.task-claim-button:hover {
    background-color: #dec000;
    
}

.task-claim-button.disabled {
    background-color: #A9A9A9;
    border: none; /* Remove border if needed */
    opacity: 0.7;
}

.task-claim-button.completed {
    background-color: transparent; /* Remove button background for completed tasks */
    border: none; /* Remove border for completed tasks */
    cursor: default; /* Default cursor for completed tasks */
    
}

.task-claim-button img.checkmark-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    filter: invert(50%); /* Invert the color to white */

}


.no-tasks-message {
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    margin-top: 80px;
}

.claim-reward-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.claim-reward-popup {
    background-color: #282828;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
}

.popup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.popup-message {
    font-size: 18px;
    margin-bottom: 20px;
}

.claim-popup-close-button {
    background-color: #FFD700;
    color: black;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}

.claim-popup-close-button:hover {
    background-color: #dec000;
}

.confetti-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
}

.confetti-piece {
    width: 10px;
    height: 20px;
    position: absolute;
    background-color: hsl(0, 100%, 50%);
    animation: explode 1.1s ease-in-out forwards;
    z-index: 9999;
}

@keyframes explode {
    to {
        transform: translate(var(--randomX), var(--randomY)) rotate(1080deg);
        opacity: 0;
    }
}
