.more-page-container {
    padding: 0 20px 20px;
    background-color: #121212;
    color: #ffffff;
    min-height: 100vh;
    text-align: center;
}

.more-page-title {
    font-size: 24px;
    margin-bottom: 45px;
}

.more-options-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 80px;
}

.more-option-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    margin: 10px 0;
    background-color: #282828;
    border-radius: 12px;
    cursor: pointer;
}

.more-option-text {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
}

.more-option-arrow {
    width: 20px;
    height: 20px;
}

.more-option-item:hover {
    background-color: #343434;
}


/* Unique CSS for User Agreement Popup */
.user-agreement-popup-overlay-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: flex-start; /* Open from the left */
    align-items: flex-end; 
    z-index: 1000;
}

.user-agreement-popup-custom {
    background-color: #282828;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    text-align: left; /* Text aligned to the left */
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: 80vh; /* Set height to 80% of the page */
    overflow-y: auto; /* Allow scrolling inside the popup */
    animation: slideUp 0.3s ease;
}

@keyframes slideInFromBottomCustom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.user-agreement-header-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.user-agreement-title-custom {
    color: #FFFFFF;
    margin: 0;
    font-size: 16px;
}

.user-agreement-close-btn-custom {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-agreement-close-btn-custom:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.user-agreement-separator-custom {
    border: 0;
    height: 1px;
    background-color: #555;
    margin: 10px 0;
}

.user-agreement-body-custom {
    color: white;
    font-size: 14px;
    text-align: left; /* Ensure text aligns left */
}

.user-agreement-body-custom p {
    margin-bottom: 15px;
    text-align: left; /* Ensure text aligns left */
}
