.deposit-container {
    padding: 0 20px 20px;
    margin: 0 auto;
    background-color: #121212;
    color: #FFFFFF;
    min-height: 100vh;
}



/* Page Title */
.deposit-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
    color: #FFFFFF;
    text-align: center; /* Center-aligns the title */
}

/* Instructions Section */
.instructions-section {
    text-align: left;
    margin-bottom: 30px;
}



.instructions-title {
    font-size: 14px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: left; /* Left align title */
    font-weight: 200;
    margin-bottom: 15px;
}

.instructions-border {
    border: 1px solid #383434;
    padding: 15px;
    border-radius: 10px;
    background-color: #282828;
    margin-bottom: 50px;
}

.instruction-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.instruction-number {
    background-color: #444;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0; /* Prevents the circle from shrinking */
}


.instruction-item span {
    color: #E0E0E0;
    font-size: 14px;
    font-weight: normal;
}


/* Currency Section */
.currency-section {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.currency-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.currency-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
}

.coming-soon {
    opacity: 0.5;
    cursor: not-allowed;
}

.deposit-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align popup to the bottom */
    z-index: 2000;
}

.deposit-popup-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 2001;
    animation: slideUp 0.3s ease;
}

.deposit-popup-container.closing {
    animation: slideDown 0.2s ease;
}

.deposit-popup {
    background-color: #282828;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    padding-bottom: 110px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    max-height: 70vh; /* Limit popup height */
    overflow-y: auto; /* Allow scrolling inside the popup */
}

  
  .deposit-popup-header {
    display: flex;
    justify-content: center; /* Center icon and text horizontally */
    align-items: center;     /* Center icon and text vertically */
    padding-bottom: 0px;
    margin-top: -18px; /* Move the title and button slightly up */
    position: relative;      /* Position close button on the right */
  }
  
  .deposit-popup-currency-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .deposit-popup-currency-label {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
  }
  
  .deposit-popup-close {
    background: none;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: -15px; /* Moves it to the right */
  }
  
  .deposit-popup-close:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
  }
  
  .deposit-popup-warning {
    font-size: 14px;
    color: grey;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  
  .qr-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;
}



.wallet-address-text {
    font-size: 12px;
    color: #FFFFFF;
    word-break: break-all;
    margin-top: 10px;
}

.copy-button {
    background-color: #FFD700;
    color: black;
    border: none;
    padding: 10px 60px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
    margin: 0 auto; /* This centers the button */
}

.copy-button:hover {
    background-color: #dec000;
}

.copy-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.copied-message {
    color: green;
    font-size: 12px;
}

.normal-text {
    font-size: 14px;
    font-weight: 100; /* Light font */
    color: white;
}

.bold-text {
    font-size: 14px;
    font-weight: bold; /* Bold font for the amount */
    color: white;
}

.rate-info {
    font-size: 12px;
    color: grey;
    margin-top: 5px;
  }
  